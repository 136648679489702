import { Message, NotificationType, PlotRequestMessage, ResultFileMessage, OrderExecutionMessage, OrderExecutionActions } from 'api/Notifications/types';
import { plotsUrl } from 'app/consts/urls';
import { SearchParams } from 'app/types/PaginationParams';

import React from 'react';
import type { ArgsProps } from 'antd/es/notification';
import { Typography } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import { capitalize } from 'lodash';

export const getNotificationProps = (message: Message, type: NotificationType, navigate: NavigateFunction): ArgsProps | null => {
  const notification = { duration: 0 };

  if (type === 'plot_completed') {
    const { modelApi, plotRequestId } = message as PlotRequestMessage;
    return {
      ...notification,
      key: plotRequestId,
      message: 'Plots are completed',
      description: (
        <Typography.Link onClick={() => navigate(`${plotsUrl}/${modelApi}/?${SearchParams.PLOT_REQUEST_ID}=${plotRequestId}`)}>
          Click here to download {modelApi} plots
        </Typography.Link>
      ),
      type: 'success',
    };
  } else if (type === 'plot_failed') {
    const { error, modelApi, plotRequestId } = message as PlotRequestMessage;
    return {
      ...notification,
      key: plotRequestId,
      message: `${capitalize(modelApi)} plots are failed`,
      description: error,
      type: 'error',
    };
  } else if (type === 'upload_result_file_completed') {
    const { modelApi, resultFileId } = message as ResultFileMessage;
    return {
      ...notification,
      key: resultFileId,
      message: `${capitalize(modelApi)} results are uploaded`,
      description: (
        <Typography.Link onClick={() => navigate(`${plotsUrl}/${modelApi}`)}>
          Navigate to {modelApi} plots page
        </Typography.Link>
      ),
      type: 'success',
    };
  } else if (type === 'upload_result_file_failed') {
    const { error, modelApi, resultFileId } = message as ResultFileMessage;
    return {
      ...notification,
      key: resultFileId,
      message: `${capitalize(modelApi)} results import is failed`,
      description: error,
      type: 'error',
    };
  } else if (type === 'order_execution_failed') {
    const orderExecutionMessage = message as OrderExecutionMessage;
    const action = orderExecutionMessage.find(m => m.type === 'actions') as OrderExecutionActions;
    const { text, url } = action.elements[0];
    const link = url.split('dashboard/')[1];
    const orderId = link.split('search=')[1];
    return {
      ...notification,
      key: orderId,
      message: 'Order execution is failed',
      description: (
        <Typography.Link onClick={() => navigate(link)}>
          {text.text}
        </Typography.Link>
      ),
      type: 'error',
    };
  }

  return null;
};
