import { updateOrderRequest } from 'api/Exec/api';
import { Order } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { message } from 'antd';

const useUpdateOrder = (onOrderUpdated: (order: Order) => void, finalCallback: () => void) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const updateOrder = (orderId: string, tagIds: string[]) => {
    setIsLoading(true);

    updateOrderRequest(orderId, tagIds)
      .then(({ data }) => {
        onOrderUpdated(data);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsLoading(false);
        finalCallback();
      });
  };

  return { isLoading, updateOrder };
};

export default useUpdateOrder;
