import { getCreditLedgerRequest } from 'api/Credit/api';
import { PaginatedCreditLedger, CreditLedgerFilterParams } from 'api/Credit/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useCreditLedger = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ creditLedger, setCreditLedger ] = useState<PaginatedCreditLedger>({ results: [], count: 0 });

  const fetchCreditLedger = useCallback(async (page: number, pageSize: number, filter: CreditLedgerFilterParams, abortController?: AbortController) => {
    setIsLoading(true);
    getCreditLedgerRequest(page, pageSize, filter, abortController)
      .then(({ data }) => {
        setCreditLedger(data);
      })
      .catch((reason) => {
        if (!abortController?.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { creditLedger, isLoading, fetchCreditLedger, setCreditLedger };
};

export default useCreditLedger;
