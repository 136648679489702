import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import usePaginationParams from 'app/hooks/usePaginationParams';
import { DashboardPageProps } from 'components/Dashboard/types';
import useStatistics from 'components/Dashboard/Statistics/useStatistics';
import StatisticsFilter from 'components/Dashboard/Statistics/StatisticsFilter/StatisticsFilter';
import Orders from 'components/Dashboard/Statistics/Orders/Orders';
import Credits from 'components/Dashboard/Statistics/Credits/Credits';
import Files from 'components/Dashboard/Statistics/Files/Files';
import Resources from 'components/Dashboard/Statistics/Resources/Resources';
import OrdersUsage from 'components/Dashboard/Statistics/OrdersUsage/OrdersUsage';

import React, { useEffect } from 'react';
import { Spin, Tabs, TabsProps } from 'antd';

const Statistics: React.FC<DashboardPageProps> = ({ setTitle }) => {
  const { orgParam, minDateParam, maxDateParam } = usePaginationParams();
  const { response, isLoading, fetchStatistics } = useStatistics();

  useEffect(() => {
    setTitle('Statistics');
  }, [ setTitle ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchStatistics(orgParam, minDateParam, maxDateParam, abortController);

    return () => {
      abortController.abort();
    };
  }, [ orgParam, minDateParam, maxDateParam, fetchStatistics ]);

  if (isLoading || !response) {
    return (
      <div className="pageLoader">
        <Spin size="large" />
      </div>
    );
  }

  const tabs: TabsProps['items'] = [
    {
      key: 'orders',
      label: 'Orders',
      children: <Orders statistics={response.ordersStatistics} />,
    },
    {
      key: 'usage',
      label: 'Orders usage',
      children: <OrdersUsage />,
    },
    {
      key: 'files',
      label: 'Files',
      children: <Files statistics={response} />,
    },
    {
      key: 'credits',
      label: 'Credits',
      children: <Credits statistics={response.creditStatistics} />,
    },
    {
      key: 'resources',
      label: 'Resources',
      children: <Resources statistics={response.resourceStatistics} />,
    },
  ];

  return (
    <>
      <TableTopPanel Filter={StatisticsFilter} />

      <Tabs defaultActiveKey="orders" items={tabs} />
    </>
  );
};

export default Statistics;
