import { Model, NamedModel } from 'api/Exec/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import DockerImageForm from 'components/Dashboard/Models/DockerImageForm/DockerImageForm';
import { DashboardPageProps } from 'components/Dashboard/types';
import useColumns from 'components/Dashboard/Models/DockerImages/hooks';

import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Typography } from 'antd';

interface Props extends DashboardPageProps {
  isLoading: boolean;
  initModels: Model[];
  namedModels: NamedModel[],
  onDockerImageAdded: (model: Model) => void;
}

const DockerImages: React.FC<Props> = ({ setTitle, initModels, namedModels, isLoading, onDockerImageAdded }) => {
  const { columns } = useColumns();
  const { searchParam } = usePaginationParams();
  const { tablePagination } = useTablePagination();
  const [ models, setModels ] = useState<Model[]>([]);
  const pagination = tablePagination(models.length);

  const [ isDockerImageModalOpen, setIsDockerImageModalOpen ] = useState<boolean>(false);
  const openDockerImageModal = () => setIsDockerImageModalOpen(true);
  const closeDockerImageModal = () => setIsDockerImageModalOpen(false);

  const addDockerImageAndCloseModal = (model: Model) => {
    onDockerImageAdded(model);
    closeDockerImageModal();
  };

  useEffect(() => {
    setTitle('Docker images');
  }, [ setTitle ]);

  useEffect(() => {
    if (searchParam) {
      setModels(initModels.filter((model: Model) => model.assignedName.toLowerCase().includes(searchParam.toLowerCase())));
    } else {
      setModels(initModels);
    }
  }, [ searchParam, initModels ]);

  return (
    <>
      <TableTopPanel
        RightColumn={<Button type="primary" onClick={openDockerImageModal} icon={<PlusOutlined />}>Add docker image</Button>}
        placeholder="Search docker images"
      />

      <Table
        rowKey={record => String(record.id)}
        columns={columns}
        dataSource={models}
        pagination={pagination}
        scroll={{ x: true }}
        loading={isLoading}
      />

      <Modal
        title={<Typography.Title level={4}>Add docker image</Typography.Title>}
        open={isDockerImageModalOpen}
        footer={null}
        width={420}
        onCancel={closeDockerImageModal}
      >
        <DockerImageForm namedModels={namedModels} onCancel={closeDockerImageModal} onDockerImageAdded={addDockerImageAndCloseModal} />
      </Modal>
    </>
  );
};

export default DockerImages;
