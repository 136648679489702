import { ColumnItem, OrderStatistics } from 'api/Statistics/types';

type OrderStatisticsSeries = 'Total' | 'Completed' | 'Failed' | 'Reports failed' | 'In progress' | 'GNT' | 'WGS';

export const getOrdersPlotAdmixData = (statistics: OrderStatistics[]): ColumnItem[] => {
  const groupedData: ColumnItem[][] = statistics.map((item) => {
    return Object
      .keys(item)
      .filter(value => value !== 'modelApiName')
      .map(value => ({
        x: item.modelApiName || 'Total',
        y: item[value as keyof Omit<OrderStatistics, 'modelApiName'>],
        series: getSeries(value as keyof Omit<OrderStatistics, 'modelApiName'>),
      }));
  });
  return groupedData.reduce((acc, val) => [ ...acc, ...val ], []);
};

export const getSeries = (value: keyof Omit<OrderStatistics, 'modelApiName'>): OrderStatisticsSeries => {
  switch (value) {
    case 'amount':
      return 'Total';
    case 'completed':
      return 'Completed';
    case 'failed':
      return 'Failed';
    case 'reportsFailed':
      return 'Reports failed';
    case 'inProgress':
      return 'In progress';
    case 'gnt':
      return 'GNT';
    case 'wgs':
      return 'WGS';
  }
};

export const getColor = (value: OrderStatisticsSeries) => {
  switch (value) {
    case 'Total':
      return '#6395f9';
    case 'Completed':
      return '#62daab';
    case 'Failed':
      return '#fa1b1b';
    case 'Reports failed':
      return '#f87057';
    case 'In progress':
      return '#f7c122';
    case 'GNT':
      return '#7666f9';
    case 'WGS':
      return '#9a67bd';
  }
};

export const getLabel = (value: ColumnItem) => {
  const { y } = value;
  return y === 0 ? '' : String(y);
};
