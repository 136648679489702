import { ModelApiName } from 'api/Exec/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import useTablePagination from 'app/hooks/useTablePagination';
import { ChartFilter } from 'components/Dashboard/Plots/types';
import usePlotRequests from 'components/Dashboard/Plots/PlotRequests/hooks/usePlotRequests';
import usePlotRequestsColumns from 'components/Dashboard/Plots/PlotRequests/hooks/usePlotRequestsColumns';

import { Table } from 'antd';
import React, { useEffect, useMemo } from 'react';

interface Props {
  modelApiName: ModelApiName;
  onBuildPlotClick: () => void;
  setFilter: (value: React.SetStateAction<ChartFilter>) => void;
}

const PlotRequests: React.FC<Props> = ({ modelApiName, onBuildPlotClick, setFilter }) => {
  const { fetchPlotRequests, response, isLoading } = usePlotRequests();
  const { columns } = usePlotRequestsColumns(onBuildPlotClick, setFilter);
  const { tablePagination } = useTablePagination(true);
  const pagination = useMemo(() => tablePagination(response.count), [ response.count, tablePagination ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (pagination.current && pagination.pageSize) {
      fetchPlotRequests(pagination.current, pagination.pageSize, modelApiName, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ pagination.current, pagination.pageSize, fetchPlotRequests, modelApiName ]);

  const reload = () => {
    if (pagination.current && pagination.pageSize) {
      fetchPlotRequests(pagination.current, pagination.pageSize, modelApiName);
    }
  };

  return (
    <>
      <TableTopPanel onReload={reload} />

      <Table
        size="small"
        rowKey={PlotRequest => PlotRequest.id}
        columns={columns}
        dataSource={response.results}
        pagination={pagination}
        loading={isLoading}
        scroll={{ x: true }}
      />
    </>
  );
};

export default PlotRequests;
