import { CreditService } from 'api/Credit/types';
import { formatDateTime } from 'app/helpers/dateTime';
import { useDeleteCreditService } from 'components/Dashboard/Billing/CreditServices/hooks';

import { Popconfirm, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { upperFirst } from 'lodash';

const useColumns = (onCreditServiceDeleted: (creditServiceId: string) => void) => {
  const { deleteCreditService } = useDeleteCreditService(onCreditServiceDeleted);

  const columns: ColumnsType<CreditService> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      render: (_, { type }) => upperFirst(type.toLowerCase().replaceAll('_', ' ')),
    },
    {
      title: 'Model',
      render: (_, { namedModel }) => namedModel?.modelName,
    },
    {
      title: 'Created',
      render: (_, { createdAt }) => <Typography.Text style={{ whiteSpace: 'nowrap' }}>{formatDateTime(createdAt)}</Typography.Text>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, { id }) => (
        <Popconfirm
          title="Delete the credit service"
          description="Are you sure you want to delete this credit service"
          onConfirm={() => deleteCreditService(id)}
        >
          <Typography.Link disabled ellipsis>Remove</Typography.Link>
        </Popconfirm>
      ),
    },
  ];

  return { columns };
};

export default useColumns;
