import { Model } from 'api/Exec/types';
import useModels from 'app/hooks/useModels';
import DockerImages from 'components/Dashboard/Models/DockerImages/DockerImages';
import NamedModels from 'components/Dashboard/Models/NamedModels/NamedModels';
import { useNamedModels } from 'components/Dashboard/Orgs/Wizard/OrgModels/hooks';
import { DashboardPageProps } from 'components/Dashboard/types';

import React, { useEffect } from 'react';
import { Tabs, TabsProps } from 'antd';

const Models: React.FC<DashboardPageProps> = ({ setTitle }) => {
  const { isLoading: areModelsLoading, models, fetchModels, setModels } = useModels();
  const { isLoading: areNamedModelsLoading, namedModels, fetchNamedModels } = useNamedModels();
  const isLoading = areModelsLoading || areNamedModelsLoading;

  useEffect(() => {
    const abortController = new AbortController();
    fetchModels(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchModels ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchNamedModels(false, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchNamedModels ]);

  const onDockerImageAdded = (model: Model) => {
    setModels((prevState: Model[]) => [ model, ...prevState ]);
  };

  const onTabsChange = (key: string) => {
    if (key === 'docker_images') {
      const abortControllerModels = new AbortController();
      const abortControllerNamedModels = new AbortController();
      fetchModels(abortControllerModels);
      fetchNamedModels(false, abortControllerNamedModels);
    }
  };

  const tabs: TabsProps['items'] = [
    {
      key: 'docker_images',
      label: 'Docker images',
      children: <DockerImages
        setTitle={setTitle}
        isLoading={isLoading}
        initModels={models}
        namedModels={namedModels}
        onDockerImageAdded={onDockerImageAdded}
      />,
    },
    {
      key: 'named_models',
      label: 'Named models',
      children: <NamedModels setTitle={setTitle} models={models} initNamedModels={namedModels} />,
    },
  ];

  return <Tabs onChange={onTabsChange} defaultActiveKey="1" items={tabs} />;
};

export default Models;
