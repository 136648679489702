import { ResultFilesStatistics } from 'api/Statistics/types';
import { goldenPurple6 } from 'app/consts/colors';
import formatBytes from 'app/helpers/formatBytes';

import React from 'react';
import { Card, Statistic, Row, Col } from 'antd';

interface Props {
  statistics: ResultFilesStatistics;
}

const ResultFiles: React.FC<Props> = ({ statistics }) => {
  return (
    <Row gutter={[ 16, 16 ]}>
      <Col lg={4} xl={3} xxl={2}>
        <Card bordered style={{ textAlign: 'center' }}>
          <Statistic
            title="Total"
            value={statistics.amount}
            valueStyle={{ color: goldenPurple6 }}
          />
        </Card>
      </Col>

      <Col xl={5} xxl={4}>
        <Card bordered style={{ textAlign: 'center' }}>
          <Statistic
            title="Result files size"
            precision={2}
            value={formatBytes(statistics.fileSizeBytes)}
            valueStyle={{ color: goldenPurple6 }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ResultFiles;
