import { cancelOrderRequest } from 'api/Exec/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { message } from 'antd';

const useCancelOrder = (onCanceled: (orderId: string) => void, finalCallback: () => void) => {
  const [ isCanceling, setIsCanceling ] = useState<boolean>(false);

  const cancelOrder = (orderId: string) => {
    setIsCanceling(true);
    cancelOrderRequest(orderId)
      .then(() => {
        onCanceled(orderId);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsCanceling(false);
        finalCallback();
      });
  };

  return { isCanceling, cancelOrder };
};

export default useCancelOrder;
