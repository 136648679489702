import { getStatisticsRequest } from 'api/Statistics/api';
import { Statistics } from 'api/Statistics/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useStatistics = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ response, setResponse ] = useState<Statistics>();

  const fetchStatistics = useCallback((orgs: string, minDate: string, maxDate: string, abortController: AbortController) => {
    setIsLoading(true);
    getStatisticsRequest(orgs, minDate, maxDate, abortController)
      .then((response) => {
        setResponse(response.data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { response, isLoading, fetchStatistics };
};

export default useStatistics;
