import { getFilesRequest } from 'api/Data/api';
import { FilesFilterParams, PaginatedFiles } from 'api/Data/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useFiles = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ response, setResponse ] = useState<PaginatedFiles>({ count: 0, results: [] });

  const fetchFiles = useCallback((pageNumber: number, pageSize: number, filter: FilesFilterParams, abortController?: AbortController) => {
    setIsLoading(true);
    getFilesRequest(pageNumber, pageSize, filter, abortController)
      .then((response) => {
        setResponse(response.data);
      })
      .catch((reason) => {
        if (!abortController?.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { response, isLoading, fetchFiles, setResponse };
};

export default useFiles;
