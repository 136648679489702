import { message } from 'antd';

export const copy = async (text: string, successMessage: string) => {
  try {
    await navigator.clipboard.writeText(text);
    message.success(successMessage);
  } catch (err) {
    message.error('Failed to copy');
  }
};
