import { CreditStatistics } from 'api/Statistics/types';
import { goldenPurple6 } from 'app/consts/colors';

import React from 'react';
import { Card, Statistic, Row, Col } from 'antd';

interface Props {
  statistics: CreditStatistics;
}

const Credits: React.FC<Props> = ({ statistics }) => {
  return (
    <Row gutter={[ 16, 16 ]}>
      <Col xl={4} xxl={3}>
        <Card bordered style={{ textAlign: 'center' }}>
          <Statistic
            title="Paid orders"
            value={statistics.ordersAmount}
            valueStyle={{ color: goldenPurple6 }}
          />
        </Card>
      </Col>

      <Col xl={4} xxl={3}>
        <Card bordered style={{ textAlign: 'center' }}>
          <Statistic
            title="Paid PDF reports"
            value={statistics.pdfReportsAmount}
            valueStyle={{ color: goldenPurple6 }}
          />
        </Card>
      </Col>

      <Col xl={4} xxl={3}>
        <Card bordered style={{ textAlign: 'center' }}>
          <Statistic
            title="Total replenishments"
            value={statistics.replenishmentAmount}
            valueStyle={{ color: goldenPurple6 }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Credits;
