import { backgroundColorLight, white } from 'app/consts/colors';
import { completeRegistrationUrl, resetPasswordUrl } from 'app/consts/urls';
import logo from 'app/sources/logo.png';
import CompleteRegistration from 'components/Auth/CompleteRegistration/CompleteRegistration';
import SignIn from 'components/Auth/SignIn/SignIn';
import ResetPassword from 'components/Auth/ResetPassword/ResetPassword';

import { Space, Layout, Typography } from 'antd';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import styles from './AuthContainer.module.css';

const { Content } = Layout;

const AuthContainer: React.FC = () => {
  return (
    <Layout hasSider={false} style={{ minHeight: '100vh' }} className={styles.layout}>

      <Content className={styles.sider} style={{ backgroundColor: white }}>
        <Space align="center" style={{ height: '100%', width: '100%', justifyContent: 'center' }}>
          <Space direction="vertical" style={{ width: 360 }}>
            <Routes>
              <Route path={completeRegistrationUrl} element={<CompleteRegistration />} />
              <Route path={resetPasswordUrl} element={<ResetPassword />} />
              <Route path="*" element={<SignIn />} />
            </Routes>
          </Space>
        </Space>
      </Content>

      <Content className={styles.sider} style={{ backgroundColor: backgroundColorLight }}>
        <Space align="center" style={{ height: '100%', width: '100%', justifyContent: 'center' }} direction="vertical">
          <img src={logo} className={styles.logo} alt="octopod" />
          <Typography.Title level={3} style={{ marginBottom: 0 }}>Galatea Bio Ancestry API</Typography.Title>
        </Space>
      </Content>
    </Layout>
  );
};

export default AuthContainer;
