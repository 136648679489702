import usePaginationParams from 'app/hooks/usePaginationParams';
import { DashboardPageProps } from 'components/Dashboard/types';
import ApiUsers from 'components/Dashboard/Users/ApiUsers/ApiUsers';
import WebUsers from 'components/Dashboard/Users/WebUsers/WebUsers';

import React, { useEffect } from 'react';
import { Tabs, TabsProps } from 'antd';

const Users: React.FC<DashboardPageProps> = ({ setTitle }) => {
  const { clearSearchParams } = usePaginationParams();

  useEffect(() => {
    setTitle('Users');
  }, [ setTitle ]);

  const tabs: TabsProps['items'] = [
    {
      key: 'users',
      label: 'Web users',
      children: <WebUsers />,
    },
    {
      key: 'api',
      label: 'API keys',
      children: <ApiUsers />,
    },
  ];

  return <Tabs defaultActiveKey="users" items={tabs} onChange={clearSearchParams} destroyInactiveTabPane />;
};

export default Users;
