import { getPdfReportsRequest } from 'api/Data/api';
import { PaginatedPdfReports, PdfReportsFilterParams } from 'api/Data/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';
import { message } from 'antd';

const usePdfReports = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ response, setResponse ] = useState<PaginatedPdfReports>({ count: 0, results: [] });

  const fetchPdfReports = useCallback((
    orderId: string,
    page: number,
    pageSize: number,
    filter: PdfReportsFilterParams,
    abortController?: AbortController,
  ) => {
    setIsLoading(true);
    getPdfReportsRequest(orderId, page, pageSize, filter, abortController)
      .then(({ data }) => {
        setResponse(data);
      })
      .catch((reason) => {
        if (reason?.message !== 'canceled') {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { isLoading, fetchPdfReports, response };
};

export default usePdfReports;
