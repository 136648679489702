import { Notification } from 'api/Notifications/types';
import { formatDateTime } from 'app/helpers/dateTime';
import { getNotificationProps } from 'app/helpers/notifications';
import { useAppDispatch } from 'store/hooks';
import { markAsReadAsync } from 'store/notifications/notificationsSlice';

import React, { ReactNode, useCallback, useMemo } from 'react';
import { ArgsProps } from 'antd/es/notification';
import { List, Typography } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

interface Props {
  notifications: Notification[];
}

const Notifications: React.FC<Props> = ({ notifications }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const iconStyles = (color: string) => ({ color, fontSize: 20, marginTop: 2 });
  const unreadNotifications = useMemo(() => notifications.filter(n => !n.watched), [ notifications ]);

  const onReadClick = (id: string) => dispatch(markAsReadAsync({ notificationId: id, watchAll: false }));
  const onReadAllClick = () => dispatch(markAsReadAsync({ watchAll: true }));

  const renderTitle = useCallback((notification: Notification, notificationProps: ArgsProps): ReactNode => {
    const date = formatDateTime(notification.createdAt);

    return (
      <>
        <Typography.Text strong={!notification.watched}>{notificationProps.message}</Typography.Text>
        <br />
        <Typography.Text>{date}</Typography.Text>
      </>
    );
  }, []);

  return (
    <List
      header={!!unreadNotifications.length && (
        <Typography.Link
          onClick={onReadAllClick}
          style={{ textAlign: 'right', display: 'block', paddingRight: 16 }}
        >
          Mark all notifications as read
        </Typography.Link>
      )}
      style={{ width: 450 }}
      itemLayout="horizontal"
      dataSource={notifications}
      size="small"
      renderItem={(item: Notification) => {
        const notificationProps = getNotificationProps(item.message.message, item.messageType, navigate);

        return notificationProps ? (
          <List.Item actions={[ item.watched ? undefined : <Typography.Link onClick={() => onReadClick(item.id)}>Mark as read</Typography.Link> ]}>
            <List.Item.Meta
              avatar={notificationProps.type === 'success' ?
                <CheckCircleFilled style={iconStyles('green')} /> :
                <CloseCircleFilled style={iconStyles('red')} />}
              title={renderTitle(item, notificationProps)}
              description={notificationProps.description}
            />
          </List.Item>
        ) : null;
      }}
    />
  );
};

export default Notifications;
