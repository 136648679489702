import { getOrgsRequest, getOrgModelsRequest } from 'api/Orgs/api';
import { Org, OrgModel } from 'api/Orgs/types';
import { OrgsState, OrgModelsPayload } from 'store/orgs/types';
import { RootState } from 'store/types';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: OrgsState = {
  orgModels: [],
  orgs: [],
  orgsStatus: 'idle',
  orgModelsStatus: 'loading',
};

export const getOrgModelsAsync = createAsyncThunk(
  'orgs/getOrgModels',
  async ({ orgId, abortController }: OrgModelsPayload) => {
    const response = await getOrgModelsRequest(orgId, false, abortController);
    return response.data;
  },
);

export const getOrgsAsync = createAsyncThunk(
  'orgs/getOrgs',
  async (abortController: AbortController) => {
    const response = await getOrgsRequest(abortController);
    return response.data;
  },
);

export const orgsSlice = createSlice({
  name: 'exec',
  initialState,
  reducers: {
    addOrg: (state, action: PayloadAction<Org>) => {
      state.orgs = [ action.payload, ...state.orgs ];
    },
    changeOrg: (state, action: PayloadAction<Org>) => {
      const orgIndex = state.orgs.findIndex(o => o.id === action.payload.id);
      state.orgs = [
        ...state.orgs.slice(0, orgIndex),
        action.payload,
        ...state.orgs.slice(orgIndex + 1, state.orgs.length),
      ];
    },
    changeOrgSftpUsersCount: (state, action: PayloadAction<{ orgId: string, value: number }>) => {
      const orgIndex = state.orgs.findIndex(o => o.id === action.payload.orgId);
      state.orgs[orgIndex].sftpUsersCount += action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrgsAsync.pending, (state) => {
        state.orgsStatus = 'loading';
      })
      .addCase(getOrgsAsync.fulfilled, (state, action: PayloadAction<Org[]>) => {
        state.orgsStatus = 'idle';
        state.orgs = action.payload;
      })
      .addCase(getOrgsAsync.rejected, (state) => {
        state.orgsStatus = 'failed';
      })
      .addCase(getOrgModelsAsync.pending, (state) => {
        state.orgModelsStatus = 'loading';
      })
      .addCase(getOrgModelsAsync.fulfilled, (state, action: PayloadAction<OrgModel[]>) => {
        state.orgModelsStatus = 'idle';
        state.orgModels = action.payload;
      })
      .addCase(getOrgModelsAsync.rejected, (state) => {
        state.orgModelsStatus = 'failed';
      });
  },
});

export const { addOrg, changeOrg, changeOrgSftpUsersCount } = orgsSlice.actions;

export const selectOrgModelsStatus = (state: RootState) => state.orgs.orgModelsStatus;
export const selectOrgModels = (state: RootState) => state.orgs.orgModels;
export const selectOrgs = (state: RootState) => state.orgs.orgs;

export default orgsSlice.reducer;
