import { OrgQueue, ResourceStatistics } from 'api/Statistics/types';
import { goldenPurple6 } from 'app/consts/colors';
import { useCurrentQueueColumns, useOrgQueueColumns } from 'components/Dashboard/Statistics/Resources/hooks';

import React from 'react';
import { Card, Col, Row, Statistic, Table, Typography } from 'antd';

interface Props {
  statistics: ResourceStatistics;
}

const Resources: React.FC<Props> = ({ statistics }) => {
  const { currentQueueColumns } = useCurrentQueueColumns();
  const { orgQueueColumns, queueColumns } = useOrgQueueColumns();

  const expandedRowRender = (record: OrgQueue) => (
    <Table
      rowKey={record => record.queueName}
      columns={queueColumns}
      dataSource={record.queues}
      pagination={false}
      scroll={{ x: true }}
      size="small"
    />
  );

  return (
    <>
      <Row gutter={[ 16, 16 ]} style={{ marginBottom: 16 }}>
        <Col xl={5} xxl={4}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="Amount of queues in AWS"
              value={statistics.currentAmountCeAws}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>

        <Col xl={5} xxl={4}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="Amount of queues in APP"
              value={statistics.currentAmountCeApp}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>
      </Row>

      <Typography.Title level={4}>Current queues</Typography.Title>

      <Table
        rowKey={record => record.queueName}
        columns={currentQueueColumns}
        dataSource={statistics.currentQueues}
        pagination={false}
        scroll={{ x: true }}
        style={{ marginBottom: 24 }}
      />

      <Typography.Title level={4}>Organizations’ queues</Typography.Title>

      <Table
        rowKey={record => record.orgName}
        columns={orgQueueColumns}
        dataSource={statistics.orgQueues}
        pagination={false}
        scroll={{ x: true }}
        style={{ marginBottom: 24 }}
        expandable={{ expandedRowRender }}
      />
    </>
  );
};

export default Resources;
