import { CreditLedger } from 'api/Credit/types';
import { Org } from 'api/Orgs/types';
import { dashboardUrl, ordersUrl } from 'app/consts/urls';
import { formatDateTime } from 'app/helpers/dateTime';
import { pluralize } from 'app/helpers/pluralize';
import useOrgsOptions from 'app/hooks/useOrgsOptions';
import usePaginationParams from 'app/hooks/usePaginationParams';
import { getStatusColor } from 'components/Dashboard/Billing/helpers';

import { Space, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const useColumns = (org?: Org) => {
  const navigate = useNavigate();
  const goToOrders = (orderId: string) => navigate(`/${dashboardUrl}/${ordersUrl}?search=${orderId}`, { replace: true });
  const { getOrgNameByOrgId } = useOrgsOptions();
  const { fullLedgerParam } = usePaginationParams();

  const columns: ColumnsType<CreditLedger> = [
    {
      title: 'Service',
      render: (_, { creditTariff, orderId, comment }) => (
        <Space direction="vertical" size={1}>
          <Typography.Text>{creditTariff.creditService.name}</Typography.Text>
          {orderId && <Typography.Link onClick={() => goToOrders(orderId)} style={{ fontSize: 12 }}>{orderId}</Typography.Link>}
          {comment && <Typography.Text type="secondary" style={{ fontSize: 12 }}>{comment}</Typography.Text>}
        </Space>
      ),
    },
    ...(!org ? [ {
      title: 'Organization',
      render: (_, { orgId }) => getOrgNameByOrgId(orgId),
    } ] as ColumnsType<CreditLedger> : []),
    {
      title: 'Status',
      render: (_, { status }) => {
        const color = getStatusColor(status);

        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: 'Sum',
      render: (_, { amount }) => pluralize(amount, 'credit'),
    },
    ...(fullLedgerParam ? [ {
      title: 'From',
      render: (_, { balanceFrom }) => pluralize(balanceFrom, 'credit'),
    }, {
      title: 'To',
      render: (_, { balanceTo }) => pluralize(balanceTo, 'credit'),
    } ] as ColumnsType<CreditLedger> : []),
    {
      title: 'Created',
      render: (_, { createdAt }) => <Typography.Text style={{ whiteSpace: 'nowrap' }}>{formatDateTime(createdAt, true)}</Typography.Text>,
    },
  ];

  return { columns };
};

export default useColumns;
