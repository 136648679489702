import { Button, Form, Input, Space, Checkbox } from 'antd';
import React, { useEffect } from 'react';

interface FormValues {
  sshKey: string;
  generate: boolean;
}

interface Props {
  onSubmit: (sshKey?: string) => void;
  isLoading: boolean;
  onCancel: () => void;
}

const AddSshKeyForm: React.FC<Props> = ({ onSubmit, isLoading, onCancel }) => {
  const [ form ] = Form.useForm<FormValues>();
  const generate = Form.useWatch('generate', form);
  const getSSHKey = (): string | undefined => form.getFieldValue('sshKey');

  const onFinish = () => {
    onSubmit(getSSHKey());
  };

  useEffect(() => {
    if (generate === true) {
      form.setFieldValue('sshKey', undefined);
    }
  }, [ generate, form ]);

  return (
    <Form
      form={form}
      name="newSftpUserForm"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ marginTop: 24 }}
      initialValues={{ generate: false }}
    >
      <Form.Item
        label="Please, enter SSH public key"
        name="sshKey"
        rules={[ { required: !generate, message: 'SSH public key is required' } ]}
        dependencies={[ 'generate' ]}
      >
        <Input.TextArea placeholder="SSH public key" disabled={generate || isLoading} autoSize={{ minRows: 3, maxRows: 8 }} />
      </Form.Item>

      <Form.Item name="generate" valuePropName="checked" label="or">
        <Checkbox>Automatically generate SSH key pair</Checkbox>
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Create
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AddSshKeyForm;
