import { SftpUser, SSHKey } from 'api/Orgs/types';
import ConnectionHint from 'components/Dashboard/Orgs/Wizard/SftpUsers/ConnectionHint/ConnectionHint';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popover, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';

const useColumns = (
  onDeleteClick: (sftpUserId: string) => void,
  onManageClick: (sftpUserId: string, sshKeys: SSHKey[]) => void,
) => {
  const columns: ColumnsType<SftpUser> = [
    {
      title: 'Name',
      dataIndex: 'userName',
    },
    {
      title: 'Home folder',
      dataIndex: 'userHomeFolder',
    },
    {
      title: '# of SSH keys',
      dataIndex: 'sshKeys',
      render: sshKeys => sshKeys.length,
    },
    {
      title: 'Connection hint',
      align: 'center',
      render: (_, { connectionHint }) => {
        return (
          <Popover content={<ConnectionHint hint={connectionHint} />}>
            <QuestionCircleOutlined />
          </Popover>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, { id, sshKeys }) => {
        return (
          <Space size="middle">
            <Typography.Link ellipsis onClick={() => onManageClick(id, sshKeys)}>Manage SSH keys</Typography.Link>
            <Typography.Link ellipsis onClick={() => onDeleteClick(id)}>Remove</Typography.Link>
          </Space>
        );
      },
    },
  ];

  return { columns };
};

export default useColumns;
