import { CreditService, CreditServiceType } from 'api/Credit/types';
import { NamedModel } from 'api/Exec/types';
import { SelectOption } from 'app/types/Forms';
import { useNamedModels } from 'components/Dashboard/Orgs/Wizard/OrgModels/hooks';
import useAddCreditService from 'components/Dashboard/Billing/CreditServices/CreditServiceForm/useAddCreditService';

import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Space } from 'antd';

interface FormValues {
  name: string;
  type: CreditServiceType;
  namedModelId?: string;
}

interface Props {
  onCancel: () => void;
  onCreditServiceAdded: (creditService: CreditService) => void;
}

const CreditServiceForm: React.FC<Props> = ({ onCancel, onCreditServiceAdded }) => {
  const [ form ] = Form.useForm<FormValues>();
  const { isLoading, namedModels, fetchNamedModels } = useNamedModels();
  const { isCreating, addCreditService } = useAddCreditService(onCreditServiceAdded);
  const [ namedModelsOptions, setNamedModelsOptions ] = useState<SelectOption[]>([]);

  const creditTypeOptions: SelectOption<CreditServiceType>[] = [
    { label: 'Execute GNT', value: 'EXECUTE_MODEL_GNT' },
    { label: 'Execute WGS', value: 'EXECUTE_MODEL_WGS' },
  ];

  useEffect(() => {
    const abortController = new AbortController();
    fetchNamedModels(false, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchNamedModels ]);

  useEffect(() => {
    const options: SelectOption[] = namedModels
      .filter((namedModel: NamedModel) => !namedModel.deprecated)
      .map((namedModel: NamedModel) => ({
        label: namedModel.modelName,
        value: namedModel.id,
      }));
    form.setFieldValue('modelName', undefined);
    setNamedModelsOptions(options);
  }, [ namedModels, form ]);

  const onFinish = (values: FormValues) => addCreditService(values.name, values.type, values.namedModelId);

  return (
    <Form
      form={form}
      name="creditService"
      style={{ width: 360 }}
      layout="vertical"
      onFinish={onFinish}
      disabled={isCreating}
    >
      <Form.Item name="name" label="Name" rules={[ { required: true, message: 'Field is mandatory' } ]}>
        <Input placeholder="Enter name" />
      </Form.Item>

      <Form.Item name="type" label="Type" rules={[ { required: true, message: 'Field is mandatory' } ]}>
        <Select placeholder="Select type" options={creditTypeOptions} />
      </Form.Item>

      <Form.Item name="namedModelId" label="Named model">
        <Select placeholder="Select named model" options={namedModelsOptions} loading={isLoading} allowClear />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Add credit service
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default CreditServiceForm;
