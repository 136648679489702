import { Org } from 'api/Orgs/types';
import { PaginatedData } from 'app/types/PaginatedData';

export type PaginatedUsers = PaginatedData<User>;

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  status: Status;
  createdAt: string;
  deletedAt: string | null;
  role: Role;
  orgName: string;
}

export interface MyUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
  isActive: boolean;
  createdAt: string;
  deletedAt: string | null;
  updatedAt: string;
  org: Pick<Org, 'id' | 'name' | 'domains'> & { availableModels: string[] };
}

export enum Role {
  SYSTEM_ADMIN = 'SystemAdmin',
  ORG_ADMIN = 'OrgAdmin',
  ORG_USER = 'OrgUser',
}

export enum Status {
  ACTIVATED = 'Activated',
  INVITED = 'Invited',
  DELETED = 'Deleted',
}

export type UpdateUserParams = Pick<User, 'firstName' | 'lastName' | 'role'>;
