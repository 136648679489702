import { Org } from 'api/Orgs/types';
import { Role, User } from 'api/Users/types';
import { useInviteUser } from 'components/Dashboard/Users/NewUserForm/hooks';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import { Button, Form, Input, Select, Space, Popconfirm, Typography } from 'antd';
import React, { useState } from 'react';

interface FormValues {
  email: string;
  role?: Role;
}

interface Props {
  onCancel: () => void;
  onUserInvited: (user: User) => void;
  org?: Org
}

const { Text } = Typography;

const NewUserForm: React.FC<Props> = ({ onCancel, onUserInvited, org }) => {
  const [ open, setOpen ] = useState(false);
  const { org: { domains: myUserOrgDomains } } = useAppSelector(selectMyUser);
  const [ form ] = Form.useForm<FormValues>();
  const getEmail = (): string => form.getFieldValue('email');
  const getRole = (): Role => form.getFieldValue('role');

  const { isLoading, inviteUser } = useInviteUser(onUserInvited);

  const submit = () => inviteUser(getEmail(), getRole(), org);
  const closeConfirm = () => setOpen(false);
  const onConfirm = () => {
    closeConfirm();
    submit();
  };

  const onFinish = (values: FormValues) => {
    const userDomain = values.email.split('@')[1];
    const domains = org ? org.domains : myUserOrgDomains;
    if (domains.length === 0 || domains.includes(userDomain)) {
      submit();
    } else {
      setOpen(true);
    }
  };

  const renderDescription = () => {
    return (
      <Text>
        <Text strong>{getEmail()}</Text> is outside the organization.
        <br />
        Are you sure you want to invite the user?
      </Text>
    );
  };

  return (
    <Form
      form={form}
      name="newUserForm"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ marginTop: 24 }}
      initialValues={{ role: Role.ORG_USER }}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[ { required: true, message: 'Field is mandatory. Please enter one email at a time' },
          { type: 'email', message: 'Email is not valid. Please enter one email at a time' } ]}
        extra="One user (one email) at a time"
      >
        <Input placeholder="Enter user’s email" disabled={isLoading} />
      </Form.Item>

      <Form.Item label="Role" name="role">
        <Select
          disabled={isLoading}
          options={[
            { value: Role.ORG_USER, label: 'User' },
            { value: Role.ORG_ADMIN, label: 'Organization Admin' },
          ]}
        />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Popconfirm
            open={open}
            title="Invite the user"
            placement="topRight"
            description={renderDescription}
            onConfirm={onConfirm}
            onCancel={closeConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              Invite
            </Button>
          </Popconfirm>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default NewUserForm;
