import { Message, NotificationType } from 'api/Notifications/types';
import { Role } from 'api/Users/types';
import PrivateRouter from 'app/common_components/PrivateRouter/PrivateRouter';
import { backgroundColorBase, borderColorSplit, goldenPurple7, white } from 'app/consts/colors';
import {
  billingUrl,
  createOrgUrl,
  editOrgUrl,
  filesUrl,
  modelsUrl,
  ordersUrl,
  orgsUrl,
  statisticsUrl,
  usersUrl,
  skywalkerUrl,
  vaderUrl,
  huttUrl,
  palpatineUrl,
} from 'app/consts/urls';
import { getNotificationProps } from 'app/helpers/notifications';
import useWSNotifications from 'app/hooks/useWSNotifications';
import logo from 'app/sources/logo.png';
import Plots from 'components/Dashboard/Plots/Plots';
import CreateOrg from 'components/Dashboard/Orgs/CreateOrg/CreateOrg';
import EditOrg from 'components/Dashboard/Orgs/EditOrg/EditOrg';
import SideMenu from 'containers/DashboardContainer/components/SideMenu/SideMenu';
import MyUser from 'containers/DashboardContainer/components/MyUser/MyUser';
import Models from 'components/Dashboard/Models/Models';
import Orders from 'components/Dashboard/Orders/Orders';
import Orgs from 'components/Dashboard/Orgs/Orgs';
import Users from 'components/Dashboard/Users/Users';
import Files from 'components/Dashboard/Files/Files';
import Statistics from 'components/Dashboard/Statistics/Statistics';
import Billing from 'components/Dashboard/Billing/Billing';
import { getModelApiAsync, getTagsAsync } from 'store/exec/execSlice';
import { getNotificationsAsync } from 'store/notifications/notificationsSlice';
import { getOrgsAsync, getOrgModelsAsync } from 'store/orgs/orgsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Layout, Space, Typography, notification } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import styles from './DashboardContainer.module.css';

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

const DashboardContainer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [ api, contextHolder ] = notification.useNotification();
  const openNotification = useCallback((message: Message, type: NotificationType) => {
    const notificationProps = getNotificationProps(message, type, navigate);
    if (notificationProps) {
      api.open(notificationProps);
      dispatch(getNotificationsAsync());
    }
  }, [ api, navigate, dispatch ]);
  useWSNotifications(openNotification);

  const [ title, setTitle ] = useState<string>('');
  const [ collapsed, setCollapsed ] = useState<boolean>(false);
  const [ collapsedWidth, setCollapsedWidth ] = useState<number>(80);
  const { role: myUserRole, org } = useAppSelector(selectMyUser);

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(getTagsAsync(abortController));

    return () => {
      abortController.abort();
    };
  }, [ dispatch ]);

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(getNotificationsAsync(abortController));

    return () => {
      abortController.abort();
    };
  }, [ dispatch ]);

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(getModelApiAsync(abortController));

    return () => {
      abortController.abort();
    };
  }, [ dispatch ]);

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(getOrgModelsAsync({ orgId: org.id, abortController }));

    return () => {
      abortController.abort();
    };
  }, [ dispatch, org ]);

  useEffect(() => {
    if (myUserRole === Role.SYSTEM_ADMIN) {
      const abortController = new AbortController();
      dispatch(getOrgsAsync(abortController));

      return () => {
        abortController.abort();
      };
    }
  }, [ dispatch, myUserRole ]);

  return (
    <Layout className={styles.layout} hasSider>

      {contextHolder}

      <Sider
        collapsible
        onBreakpoint={(broken) => {
          setCollapsed(broken);
          setCollapsedWidth(broken ? 0 : 80);
        }}
        onCollapse={value => setCollapsed(value)}
        collapsed={collapsed}
        collapsedWidth={collapsedWidth}
        zeroWidthTriggerStyle={{ top: 0, backgroundColor: white, border: `1px ${borderColorSplit} solid` }}
        breakpoint="lg"
        width={256}
        style={{ backgroundColor: white, borderRight: `1px ${borderColorSplit} solid` }}
        className={styles.sider}
        trigger={collapsed ? <RightOutlined style={{ color: goldenPurple7 }} /> : <LeftOutlined style={{ color: goldenPurple7 }} />}
      >

        <Space style={{ height: 68, padding: '16px 24px', cursor: 'pointer' }} onClick={() => navigate(ordersUrl)}>
          <img src={logo} width={28} alt="octopod" />
          {!collapsed && <Title level={5} style={{ marginBottom: 0 }} ellipsis>Ancestry API</Title>}
        </Space>

        <SideMenu />
      </Sider>

      <Layout style={{ backgroundColor: backgroundColorBase }}>
        <Header className={styles.header}>
          <Title level={5} className={styles.title} style={{ marginBottom: 0 }}>{title}</Title>

          <MyUser />
        </Header>

        <Content style={{ margin: 16, padding: 16, background: white }}>
          <Routes>
            <Route path={filesUrl} element={<Files setTitle={setTitle} />} />
            <Route path={skywalkerUrl} element={<Plots key="skywalker" setTitle={setTitle} modelApiName="skywalker" />} />
            <Route path={vaderUrl} element={<Plots key="vader" setTitle={setTitle} modelApiName="vader" />} />
            <Route path={huttUrl} element={<Plots key="hutt" setTitle={setTitle} modelApiName="hutt" />} />
            <Route path={palpatineUrl} element={<Plots key="palpatine" setTitle={setTitle} modelApiName="palpatine" />} />
            <Route
              path={usersUrl}
              element={(
                <PrivateRouter roles={[ Role.ORG_ADMIN, Role.SYSTEM_ADMIN ]}>
                  <Users setTitle={setTitle} />
                </PrivateRouter>
              )}
            />
            <Route
              path={orgsUrl}
              element={(
                <PrivateRouter roles={[ Role.SYSTEM_ADMIN ]}>
                  <Orgs setTitle={setTitle} />
                </PrivateRouter>
              )}
            />
            <Route
              path={createOrgUrl}
              element={(
                <PrivateRouter roles={[ Role.SYSTEM_ADMIN ]}>
                  <CreateOrg setTitle={setTitle} />
                </PrivateRouter>
              )}
            />
            <Route
              path={editOrgUrl}
              element={(
                <PrivateRouter roles={[ Role.SYSTEM_ADMIN ]}>
                  <EditOrg setTitle={setTitle} />
                </PrivateRouter>
              )}
            />
            <Route
              path={statisticsUrl}
              element={(
                <PrivateRouter roles={[ Role.SYSTEM_ADMIN ]}>
                  <Statistics setTitle={setTitle} />
                </PrivateRouter>
              )}
            />
            <Route
              path={modelsUrl}
              element={(
                <PrivateRouter roles={[ Role.SYSTEM_ADMIN ]}>
                  <Models setTitle={setTitle} />
                </PrivateRouter>
              )}
            />
            <Route
              path={billingUrl}
              element={(
                <PrivateRouter roles={[ Role.SYSTEM_ADMIN ]}>
                  <Billing setTitle={setTitle} />
                </PrivateRouter>
              )}
            />
            <Route path="*" element={<Orders setTitle={setTitle} />} />
          </Routes>
        </Content>
      </Layout>

    </Layout>
  );
};

export default DashboardContainer;
