import React from 'react';
import { Form, Input } from 'antd';

interface Props {
  passwordLabel?: string
}

const PasswordInputs: React.FC<Props> = ({ passwordLabel = 'Password' }) => (
  <>
    <Form.Item
      label={passwordLabel}
      name="password"
      rules={[ { required: true, message: 'Please enter your password' },
        { pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g,
          message: 'Password should contain at least 8 characters including 1 uppercase and 1 digit' } ]}
    >
      <Input.Password placeholder="Enter your password" />
    </Form.Item>

    <Form.Item
      label="Confirm password"
      name="confirmPassword"
      dependencies={[ 'password' ]}
      rules={[ { required: true, message: 'Please confirm your password' },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords do not match'));
          },
        }),
      ]}
    >
      <Input.Password placeholder="Confirm your password" />
    </Form.Item>
  </>
);

export default PasswordInputs;
