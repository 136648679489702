import TagsSelect from 'app/common_components/TagsSelect/TagsSelect';
import useOrgModelsOptions from 'app/hooks/useOrgModelsOptions';
import useOrgsOptions from 'app/hooks/useOrgsOptions';
import { SelectOption } from 'app/types/Forms';
import { Status } from 'api/Exec/types';
import { Role } from 'api/Users/types';
import usePaginationParams from 'app/hooks/usePaginationParams';
import { FilterParams } from 'app/types/Filters';
import { FilterProps } from 'components/Dashboard/types';
import { useAppSelector } from 'store/hooks';
import { selectModelsApi } from 'store/exec/execSlice';
import { selectMyUser } from 'store/users/usersSlice';

import { Button, DatePicker, Form, Select, Space } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const { RangePicker } = DatePicker;

const OrdersFilter: React.FC<FilterProps> = ({ onApply }) => {
  const { role: myUserRole } = useAppSelector(selectMyUser);
  const modelsApi = useAppSelector(selectModelsApi);
  const [ form ] = Form.useForm<FilterParams>();
  const {
    orgParam,
    orderStatusParam,
    tagParam,
    modelParam,
    modelApiParam,
    minDateParam,
    maxDateParam,
    typeParam,
    setFilters,
    clearFilters,
  } = usePaginationParams();
  const { orgsOptions } = useOrgsOptions();
  const { orgModelsOptions } = useOrgModelsOptions();

  const statusOptions: SelectOption[] = Object.values(Status).filter(s => s).map(s => ({ label: s, value: s }));
  const typeOptions: SelectOption[] = [
    { label: 'GNT', value: 'GNT' },
    { label: 'WGS', value: 'WGS' },
    { label: 'EXTERNAL', value: 'EXTERNAL' },
  ];

  const onReset = () => {
    form.setFieldValue('org', []);
    form.setFieldValue('orderStatus', undefined);
    form.setFieldValue('tag', []);
    form.setFieldValue('date', [ null, null ]);
    form.setFieldValue('model', []);
    form.setFieldValue('modelApi', undefined);
    form.setFieldValue('type', undefined);
    clearFilters();
  };

  const onFinish = (values: FilterParams) => {
    setFilters(values);
    onApply();
  };

  return (
    <Form
      form={form}
      name="ordersFilter"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ width: 290 }}
      initialValues={{
        org: orgParam !== '' ? orgParam.split(',') : [],
        orderStatus: orderStatusParam || undefined,
        tag: tagParam !== '' ? tagParam.split(',') : [],
        model: modelParam !== '' ? modelParam.split(',') : undefined,
        modelApi: modelApiParam || undefined,
        date: [
          minDateParam ? dayjs(minDateParam) : null,
          maxDateParam ? dayjs(maxDateParam) : null,
        ],
        type: typeParam || undefined,
      }}
    >
      {myUserRole === Role.SYSTEM_ADMIN && (
        <Form.Item name="org" label="Organizations">
          <Select placeholder="Select organizations" mode="multiple" options={orgsOptions} />
        </Form.Item>
      )}

      <Form.Item name="orderStatus" label="Status">
        <Select placeholder="Select status" options={statusOptions} allowClear />
      </Form.Item>

      <Form.Item name="tag" label="Tags">
        <TagsSelect disabled={false} disableTagManagement />
      </Form.Item>

      <Form.Item name="type" label="Type">
        <Select placeholder="Select type" options={typeOptions} allowClear />
      </Form.Item>

      <Form.Item name="model" label="Model">
        <Select placeholder="Select model" mode="multiple" options={orgModelsOptions} allowClear />
      </Form.Item>

      <Form.Item name="modelApi" label="Model API">
        <Select placeholder="Select model API" options={modelsApi} allowClear />
      </Form.Item>

      <Form.Item name="date" label="Started">
        <RangePicker allowEmpty={[ true, true ]} format="MM/DD/YYYY" />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default OrdersFilter;
