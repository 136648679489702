import { getCreditTariffsRequest } from 'api/Credit/api';
import { PaginatedCreditTariffs, CreditTariffsFilterParams } from 'api/Credit/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useCreditTariffs = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ creditTariffs, setCreditTariffs ] = useState<PaginatedCreditTariffs>({ count: 0, results: [] });

  const fetchCreditTariffs = useCallback((page: number, pageSize: number, filter: CreditTariffsFilterParams, abortController: AbortController) => {
    setIsLoading(true);
    getCreditTariffsRequest(page, pageSize, filter, abortController)
      .then(({ data }) => {
        setCreditTariffs(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { creditTariffs, isLoading, fetchCreditTariffs, setCreditTariffs };
};

export default useCreditTariffs;
