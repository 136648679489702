import { deleteUserRequest } from 'api/Users/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useState } from 'react';

const useDeleteUser = (onDeleted: (userId: string) => void, finalCallback: () => void) => {
  const [ isDeleting, setIsDeleting ] = useState<boolean>(false);

  const deleteUser = (userId: string) => {
    setIsDeleting(true);
    deleteUserRequest(userId)
      .then(() => {
        onDeleted(userId);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsDeleting(false);
        finalCallback();
      });
  };

  return { isDeleting, deleteUser };
};

export default useDeleteUser;
