import { TableFilterParams } from 'api/Dashboard/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import useTablePagination from 'app/hooks/useTablePagination';
import { useLabelColumns, useLabels } from 'components/Dashboard/Plots/LabelTable/hooks';
import UploadResultsForm from 'components/Dashboard/Plots/LabelTable/UploadResultsForm/UploadResultsForm';
import { HasChartFilter, LabelType } from 'components/Dashboard/Plots/types';
import TablesFilter from 'components/Dashboard/Plots/TablesFilter/TablesFilter';

import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Flex, Modal, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props extends HasChartFilter {
  type: LabelType,
}

const LabelTable: React.FC<Props> = ({ filter, type }) => {
  const [ tableFilter, setTableFilter ] = useState<TableFilterParams>({ orderId: '', sampleId: '' });
  const { columns } = useLabelColumns(type, filter.modelApiName, filter.sortBy);
  const { response, isLoading, fetchLabels, isExporting, exportLabels } = useLabels(type);
  const { tablePagination, resetLocalPagination } = useTablePagination(true);
  const pagination = tablePagination(response.count);

  const [ isUploadResultsModalOpen, setIsUploadResultsModalOpen ] = useState<boolean>(false);
  const openUploadResultsModal = () => setIsUploadResultsModalOpen(true);
  const closeUploadResultsModal = () => setIsUploadResultsModalOpen(false);

  const exportTSV = () => exportLabels(tableFilter, filter);

  useEffect(() => {
    const abortController = new AbortController();
    if (pagination.current && pagination.pageSize) {
      fetchLabels(pagination.current, pagination.pageSize, tableFilter, filter, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchLabels, pagination.current, pagination.pageSize, tableFilter, filter ]);

  const reload = () => {
    if (pagination.current && pagination.pageSize) {
      fetchLabels(pagination.current, pagination.pageSize, tableFilter, filter);
    }
  };

  const applyFilter = (values: TableFilterParams) => {
    resetLocalPagination();
    setTableFilter(values);
  };

  return (
    <>
      <TableTopPanel
        Filter={TablesFilter}
        onApplyFilter={applyFilter}
        onReload={reload}
        RightColumn={(
          <Flex>
            <Button icon={<UploadOutlined />} style={{ marginRight: 16 }} onClick={openUploadResultsModal}>Import results</Button>
            <Button type="primary" onClick={exportTSV} icon={<DownloadOutlined />} loading={isExporting}>Export TSV</Button>
          </Flex>
        )}
      />

      <Table
        rowKey={({ id }) => id}
        dataSource={response.results}
        columns={columns}
        pagination={pagination}
        scroll={{ x: true }}
        loading={isLoading}
      />

      <Modal
        title={<Typography.Title level={4}>Upload results</Typography.Title>}
        open={isUploadResultsModalOpen}
        footer={null}
        width={420}
        onCancel={closeUploadResultsModal}
        destroyOnClose
        data-testid="upload-results-modal"
      >
        <UploadResultsForm onCancel={closeUploadResultsModal} modelApiName={filter.modelApiName} />
      </Modal>
    </>
  );
};

export default LabelTable;
