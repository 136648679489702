import { Role, User } from 'api/Users/types';
import { formatDateTime } from 'app/helpers/dateTime';
import { getStatusColor, isChangingDisabled } from 'components/Dashboard/Users/helpers';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import React from 'react';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { Space, Tag, Typography } from 'antd';

const useWebUsersColumns = (onDeleteClick: (userId: string) => void) => {
  const { role: myUserRole } = useAppSelector(selectMyUser);

  const columns: ColumnsType<User> = [
    {
      title: 'Email',
      render: (_, { deletedAt, email }) => {
        if (!deletedAt) {
          return email;
        }

        return (
          <>
            <Typography.Text type="secondary">{email}</Typography.Text>
            <br />
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>Deleted {formatDateTime(deletedAt)}</Typography.Text>
          </>
        );
      },
    },
    ...(myUserRole === Role.SYSTEM_ADMIN ? [ {
      title: 'Organization',
      render: (_, { deletedAt, orgName }) => {
        return <Typography.Text type={deletedAt ? 'secondary' : undefined}>{orgName}</Typography.Text>;
      },
    } as ColumnType<User> ] : []),
    {
      title: 'Created',
      render: (_, { deletedAt, createdAt }) => {
        return <Typography.Text type={deletedAt ? 'secondary' : undefined}>{formatDateTime(createdAt)}</Typography.Text>;
      },
    },
    {
      title: 'Status',
      render: (_, { status }) => {
        const color = getStatusColor(status);

        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, { id, role, deletedAt }) => {
        const disabled = isChangingDisabled(deletedAt, myUserRole, role);

        return (
          <Space size="middle">
            <Typography.Link ellipsis onClick={() => onDeleteClick(id)} disabled={disabled}>Remove</Typography.Link>
          </Space>
        );
      },
    },
  ];

  return { columns };
};

export default useWebUsersColumns;
