import { replenishBalanceRequest } from 'api/Credit/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { updateBalance } from 'store/credits/creditsSlice';
import { useAppDispatch } from 'store/hooks';

import { message } from 'antd';
import { useState } from 'react';

const useReplenishBalance = (onCancel: () => void) => {
  const dispatch = useAppDispatch();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const replenish = (orgId: string, amount: number) => {
    setIsLoading(true);

    replenishBalanceRequest(orgId, amount)
      .then(({ data }) => {
        message.success('Replenishment was successful');
        dispatch(updateBalance(data));
        onCancel();
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { isLoading, replenish };
};

export default useReplenishBalance;
