import { ModelApiName } from 'api/Exec/types';
import TagsSelect from 'app/common_components/TagsSelect/TagsSelect';
import { getAncestralOptions, getFirstAncestralOptions } from 'components/Dashboard/Plots/helpers';
import useOrgModelsOptions from 'components/Dashboard/Plots/PlotsFilter/hooks';
import { ChartFilter, FormValues } from 'components/Dashboard/Plots/types';

import React, { useEffect } from 'react';
import { Button, Checkbox, DatePicker, Form, Select, Slider, Space } from 'antd';
import type { SliderSingleProps } from 'antd';

interface Props {
  setFilter: (filter: ChartFilter) => void;
  modelApiName: ModelApiName;
}

const { RangePicker } = DatePicker;

const PlotsFilter: React.FC<Props> = ({ modelApiName, setFilter }) => {
  const [ form ] = Form.useForm<FormValues>();
  const { orgModelsOptions } = useOrgModelsOptions(modelApiName);
  const ancestralComponent = getFirstAncestralOptions(modelApiName);

  useEffect(() => {
    form.resetFields();
  }, [ modelApiName, form ]);

  const marks: SliderSingleProps['marks'] = {};
  for (let i = 0; i <= 1; i += 0.1) {
    const mark = `${i.toFixed(1)}`;
    marks[mark] = mark;
  }

  const onFormSubmit = (values: FormValues) => {
    setFilter({
      groupBy: values.grouping.join(),
      sortBy: values.sorting.join(),
      allRecords: true,
      tagsIds: values.tags.join(','),
      modelName: values.model ? values.model : '',
      modelApiName,
      ancestralComponent: values.ancestralComponent,
      threshold: values.threshold,
      minDate: (values.date && values.date[0]) ? values.date[0].format('YYYY-MM-DD') : '',
      maxDate: (values.date && values.date[1]) ? values.date[1].format('YYYY-MM-DD') : '',
      plotRequestId: '',
    });
  };

  return (
    <Form
      name="plotsFilter"
      form={form}
      onFinish={onFormSubmit}
      autoComplete="off"
      requiredMark={false}
      layout="vertical"
      initialValues={{ grouping: [ 'sp_group' ], sorting: [], allRecords: false, tags: [], model: undefined, ancestralComponent, threshold: 0, date: null }}
    >
      <Space size="large" align="start">
        <Space direction="vertical">
          <Form.Item name="ancestralComponent" style={{ width: 350, marginBottom: 8 }} label="Ancestral Component">
            <Select placeholder="Ancestral component" options={getAncestralOptions(modelApiName)} />
          </Form.Item>

          <Form.Item name="threshold" style={{ marginBottom: 12 }} label="Threshold">
            <Slider min={0} max={1} step={0.01} marks={marks} />
          </Form.Item>
        </Space>

        <Space direction="vertical">
          <Form.Item name="tags" style={{ marginBottom: 0, maxWidth: '302px' }} label="Data source">
            <TagsSelect disabled={false} disableTagManagement placeholder="Tags" allowClear />
          </Form.Item>

          <Form.Item name="model" style={{ marginBottom: 0 }}>
            <Select placeholder="Model" options={orgModelsOptions} allowClear />
          </Form.Item>

          <Form.Item name="date" style={{ marginBottom: 0 }}>
            <RangePicker allowEmpty={[ true, true ]} format="MM/DD/YYYY" />
          </Form.Item>
        </Space>

        <Space direction="vertical">
          <Form.Item name="grouping" style={{ marginBottom: 0 }} label="Options">
            <Checkbox.Group>
              <Checkbox value="sp_group" checked={!!form.getFieldValue('grouping')}>Group by superset</Checkbox>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item name="sorting" style={{ marginBottom: 0 }}>
            <Checkbox.Group>
              <Checkbox value="sp_group" checked={!!form.getFieldValue('sorting')}>Superset sorting</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Space>
      </Space>

      <Form.Item style={{ marginBottom: 0 }}>
        <Button type="primary" htmlType="submit">
          Build plots
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PlotsFilter;
