import useOrgsOptions from 'app/hooks/useOrgsOptions';
import { SelectOption } from 'app/types/Forms';
import { CreditServiceType } from 'api/Credit/types';
import { Role } from 'api/Users/types';
import { DEFAULT_PAGE, LEDGER_DEFAULT_PAGE_SIZE } from 'app/consts/pagination';
import usePaginationParams from 'app/hooks/usePaginationParams';
import { FilterParams } from 'app/types/Filters';
import { FilterProps } from 'components/Dashboard/types';
import { useAppSelector } from 'store/hooks';
import { selectModelsApi } from 'store/exec/execSlice';
import { selectMyUser } from 'store/users/usersSlice';

import { Button, Checkbox, DatePicker, Form, Select, Space } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const { RangePicker } = DatePicker;

const CreditLedgerFilter: React.FC<FilterProps> = ({ onApply, orgId }) => {
  const { role: myUserRole } = useAppSelector(selectMyUser);
  const modelsApi = useAppSelector(selectModelsApi);
  const [ form ] = Form.useForm<FilterParams>();
  const {
    orgParam,
    creditServiceTypeParam,
    modelApiParam,
    minDateParam,
    maxDateParam,
    fullLedgerParam,
    setFilters,
    clearFilters,
  } = usePaginationParams(DEFAULT_PAGE, LEDGER_DEFAULT_PAGE_SIZE);
  const { orgsIdsOptions } = useOrgsOptions();

  const typeOptions: SelectOption<CreditServiceType>[] = [
    { label: 'Execute GNT', value: 'EXECUTE_MODEL_GNT' },
    { label: 'Execute WGS', value: 'EXECUTE_MODEL_WGS' },
    { label: 'Generate Ancestry PDF report', value: 'GENERATE_PDF_REPORT_ANCESTRY' },
    { label: 'Generate PRS PDF report', value: 'GENERATE_PDF_REPORT_PRS' },
    { label: 'Replenishment', value: 'REPLENISHMENT' },
  ];

  const onReset = () => {
    form.setFieldValue('org', []);
    form.setFieldValue('creditServiceType', undefined);
    form.setFieldValue('date', [ null, null ]);
    form.setFieldValue('modelApi', undefined);
    form.setFieldValue('fullLedger', false);
    clearFilters();
  };

  const onFinish = (values: FilterParams) => {
    setFilters(values);
    onApply();
  };

  return (
    <Form
      form={form}
      name="creditLedgerFilter"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ width: 290 }}
      initialValues={{
        org: orgParam !== '' ? orgParam.split(',') : [],
        creditServiceType: creditServiceTypeParam || undefined,
        modelApi: modelApiParam || undefined,
        date: [
          minDateParam ? dayjs(minDateParam) : null,
          maxDateParam ? dayjs(maxDateParam) : null,
        ],
        fullLedger: !!fullLedgerParam,
      }}
    >
      {myUserRole === Role.SYSTEM_ADMIN && !orgId && (
        <Form.Item name="org" label="Organizations">
          <Select placeholder="Select organizations" mode="multiple" options={orgsIdsOptions} />
        </Form.Item>
      )}

      <Form.Item name="creditServiceType" label="Service type">
        <Select placeholder="Select status" options={typeOptions} allowClear />
      </Form.Item>

      <Form.Item name="modelApi" label="Model API">
        <Select placeholder="Select model API" options={modelsApi} allowClear />
      </Form.Item>

      <Form.Item name="date" label="Started">
        <RangePicker allowEmpty={[ true, true ]} format="MM/DD/YYYY" />
      </Form.Item>

      <Form.Item valuePropName="checked" name="fullLedger">
        <Checkbox>Show full ledger</Checkbox>
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default CreditLedgerFilter;
