import { getPlotRequestsRequest } from 'api/Dashboard/api';
import { PaginatedPlotRequests } from 'api/Dashboard/types';
import { ModelApiName } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';
import { message } from 'antd';

const usePlotRequests = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ response, setResponse ] = useState<PaginatedPlotRequests>({ count: 0, results: [] });

  const fetchPlotRequests = useCallback((pageNumber: number, pageSize: number, modelApiName: ModelApiName, abortController?: AbortController) => {
    setIsLoading(true);
    getPlotRequestsRequest(pageNumber, pageSize, modelApiName, abortController)
      .then((response) => {
        setResponse(response.data);
      })
      .catch((reason) => {
        if (!abortController?.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { response, isLoading, fetchPlotRequests };
};

export default usePlotRequests;
