import authReducer from 'store/auth/authSlice';
import creditsReducer from 'store/credits/creditsSlice';
import execReducer from 'store/exec/execSlice';
import notificationsReducer from 'store/notifications/notificationsSlice';
import plotsReducer from 'store/plots/plotsSlice';
import orgsReducer from 'store/orgs/orgsSlice';
import usersReducer from 'store/users/usersSlice';

import { configureStore } from '@reduxjs/toolkit';

export const reducer = {
  auth: authReducer,
  credits: creditsReducer,
  exec: execReducer,
  notifications: notificationsReducer,
  plots: plotsReducer,
  orgs: orgsReducer,
  users: usersReducer,
};
export const store = configureStore({ reducer });
