import { CreditServiceType, CreditService } from 'api/Credit/types';
import { createCreditServiceRequest } from 'api/Credit/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useState } from 'react';

const useAddCreditService = (onCreditServiceAdded: (creditService: CreditService) => void) => {
  const [ isCreating, setIsCreating ] = useState<boolean>(false);

  const addCreditService = (name: string, type: CreditServiceType, namedModelId?: string) => {
    setIsCreating(true);

    createCreditServiceRequest(name, type, namedModelId)
      .then(({ data }) => {
        message.success('Credit service was added');
        onCreditServiceAdded(data);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return { isCreating, addCreditService };
};

export default useAddCreditService;
