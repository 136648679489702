import html2canvas from 'html2canvas';

const downloadImage = async (data: HTMLDivElement, fileName: string) => {
  const canvas = await html2canvas(data, { logging: false });
  const url = canvas.toDataURL('image/png', 1);
  const link = document.createElement('a');

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.click();
};

export default downloadImage;
