import { CreditTemplate } from 'api/Credit/types';
import { updateCreditTemplateRequest } from 'api/Credit/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useState } from 'react';

const useUpdateCreditTemplate = (onCreditTemplateUpdated: (creditTemplate: CreditTemplate) => void) => {
  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);

  const updateCreditTemplate = (creditTemplateId: string, price: number) => {
    setIsUpdating(true);

    updateCreditTemplateRequest(creditTemplateId, price)
      .then(({ data }) => {
        message.success('Template was updated');
        onCreditTemplateUpdated(data);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return { isUpdating, updateCreditTemplate };
};

export default useUpdateCreditTemplate;
