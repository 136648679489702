import { CreditTemplate } from 'api/Credit/types';
import useUpdateCreditTariff from 'components/Dashboard/Billing/CreditTemplates/UpdateCreditTemplateForm/useUpdateCreditTemplate';

import React from 'react';
import { Button, Form, InputNumber, Space } from 'antd';

interface FormValues {
  price: number;
}

interface Props {
  creditTemplate: CreditTemplate;
  onCancel: () => void;
  onCreditTemplateUpdated: (creditTemplate: CreditTemplate) => void;
}

const UpdateCreditTemplateForm: React.FC<Props> = ({ creditTemplate, onCancel, onCreditTemplateUpdated }) => {
  const [ form ] = Form.useForm<FormValues>();
  const { isUpdating, updateCreditTemplate } = useUpdateCreditTariff(onCreditTemplateUpdated);

  const onFinish = (values: FormValues) => updateCreditTemplate(creditTemplate.id, values.price);

  return (
    <Form
      form={form}
      name="updateCreditTemplate"
      style={{ width: 360 }}
      layout="vertical"
      onFinish={onFinish}
      disabled={isUpdating}
      initialValues={{ price: creditTemplate.price }}
    >
      <Form.Item name="price" label="Price" rules={[ { required: true, message: 'Field is mandatory' } ]}>
        <InputNumber placeholder="Enter price" style={{ width: '100%' }} precision={0} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Update template
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UpdateCreditTemplateForm;
