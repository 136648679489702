import { Role } from 'api/Users/types';
import {
  billingUrl,
  filesUrl,
  modelsUrl,
  ordersUrl,
  orgsUrl,
  statisticsUrl,
  usersUrl,
  skywalkerUrl,
  vaderUrl,
  plotsUrl,
  huttUrl,
  palpatineUrl,
} from 'app/consts/urls';
import { useAppSelector } from 'store/hooks';
import { selectOrgModels } from 'store/orgs/orgsSlice';
import { selectMyUser } from 'store/users/usersSlice';

import type { MenuProps } from 'antd';
import {
  AuditOutlined,
  BuildOutlined,
  ClusterOutlined,
  DollarOutlined,
  FileOutlined,
  FundProjectionScreenOutlined,
  LayoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number] & {
  roles?: Role[];
};

export const useSideMenuItems = () => {
  const orgModels = useAppSelector(selectOrgModels).map(orgModel => orgModel.modelApiName);
  const { role } = useAppSelector(selectMyUser);
  const style: CSSProperties = { borderRadius: 0, margin: '4px 0', width: '100%' };

  const menuItems: MenuItem[] = [
    {
      key: ordersUrl,
      icon: <AuditOutlined />,
      label: <Link to={ordersUrl}>Orders</Link>,
      style,
    },
    {
      key: filesUrl,
      icon: <FileOutlined />,
      label: <Link to={filesUrl}>Files</Link>,
      style,
    },
    ...(orgModels.length ? [ {
      key: plotsUrl,
      icon: <LayoutOutlined />,
      label: 'Plots',
      style,
      children: [
        ...(orgModels.includes('skywalker') ? [ { key: skywalkerUrl, label: <Link to={skywalkerUrl}>Skywalker</Link>, style } ] : []),
        ...(orgModels.includes('vader') ? [ { key: vaderUrl, label: <Link to={vaderUrl}>Vader</Link>, style } ] : []),
        ...(orgModels.includes('hutt') ? [ { key: huttUrl, label: <Link to={huttUrl}>Hutt</Link>, style } ] : []),
        ...(orgModels.includes('palpatine') ? [ { key: palpatineUrl, label: <Link to={palpatineUrl}>Palpatine</Link>, style } ] : []),
      ],
    } as MenuItem ] : []),
    {
      key: usersUrl,
      icon: <UserOutlined />,
      label: <Link to={usersUrl}>Users</Link>,
      style,
      roles: [ Role.ORG_ADMIN, Role.SYSTEM_ADMIN ],
    },
    {
      key: orgsUrl,
      icon: <ClusterOutlined />,
      label: <Link to={orgsUrl}>Organizations</Link>,
      style,
      roles: [ Role.SYSTEM_ADMIN ],
    },
    {
      key: statisticsUrl,
      icon: <FundProjectionScreenOutlined />,
      label: <Link to={statisticsUrl}>Statistics</Link>,
      style,
      roles: [ Role.SYSTEM_ADMIN ],
    },
    {
      key: modelsUrl,
      icon: <BuildOutlined />,
      label: <Link to={modelsUrl}>Models</Link>,
      style,
      roles: [ Role.SYSTEM_ADMIN ],
    },
    {
      key: billingUrl,
      icon: <DollarOutlined />,
      label: <Link to={billingUrl}>Billing</Link>,
      style,
      roles: [ Role.SYSTEM_ADMIN ],
    },
  ];

  const items = menuItems.filter(item => item.roles === undefined || item.roles.includes(role));

  return { items };
};

export const useSelectedKey = () => {
  const { pathname } = useLocation();
  const [ selectedKey, setSelectedKey ] = useState<string>('');

  useEffect(() => {
    const paths = pathname.split('/').filter(path => path !== '');
    const pathsCount = paths.length;

    if (pathsCount === 2) {
      setSelectedKey(paths[1]);
    } else if (pathsCount === 3) {
      if (paths[1] === orgsUrl) {
        setSelectedKey(orgsUrl);
      } else if (paths[1] === plotsUrl) {
        setSelectedKey(`${paths[1]}/${paths[2]}`);
      }
    } else {
      setSelectedKey(ordersUrl);
    }
  }, [ setSelectedKey, pathname ]);

  return { selectedKey };
};
