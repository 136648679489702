import { HuttLabelColors, PalpatineLabelColors, SkywalkerLabelColors, VaderLabelColors } from 'app/types/Labels';

export const SKYWALKER_SUPERSET_LABELS = [ 'AFR', 'AMR', 'EAS', 'EUR', 'LME', 'OCE', 'SAS' ] as const;
export const SKYWALKER_SET_LABELS = [
  'AHG',
  'EAF',
  'NAF',
  'WAF',
  'NAM',
  'NAN',
  'NNA',
  'ARC',
  'AUN',
  'CHI',
  'JPN',
  'SEA',
  'BYZ',
  'EEU',
  'IBE',
  'NWE',
  'RMN',
  'VIK',
  'LEV',
  'WAS',
  'MPA',
  'DSA',
] as const;
export const VADER_SUPERSET_LABELS = [ 'AFR', 'AMR', 'EAS', 'EUR', 'LME', 'OCE', 'SAS', 'SEA', 'OTHERS' ] as const;
export const VADER_SET_LABELS = [
  'AHG',
  'EAF',
  'SNG',
  'NGR',
  'CAU',
  'EEU',
  'IBE',
  'ITA',
  'NWE',
  'SCA',
  'BSQ',
  'NAM',
  'NAN',
  'NMA',
  'NNA',
  'AUS',
  'MSE',
  'PSE',
  'BNG',
  'CAS',
  'NWS',
  'SOI',
  'SAI',
  'NAF',
  'MDE',
  'ARC',
  'HAN',
  'JPN',
  'KOR',
  'NEA',
  'HIM',
  'MEL',
  'SEI',
  'OTHER',
] as const;
export const HUTT_SUPERSET_LABELS = [ 'AFR', 'NAM', 'EAS', 'EUR', 'LME', 'OCE', 'SAS' ] as const;
export const HUTT_SET_LABELS = [
  'AHG',
  'EAF',
  'NAF',
  'WAF',
  'ALK',
  'AMZ',
  'ARC',
  'CAM',
  'GRL',
  'MAY',
  'MES',
  'NAN',
  'PWL',
  'SON',
  'SAN',
  'CHI',
  'JAP',
  'SEA',
  'BAS',
  'BRI',
  'CJW',
  'EEU',
  'FRE',
  'GER',
  'IRI',
  'ITA',
  'MEI',
  'NEU',
  'ROM',
  'SCA',
  'SBL',
  'SLA',
  'SPA',
  'TAL',
  'AUS',
  'MEL',
  'DSA',
  'HJW',
  'LEV',
  'WEA',
] as const;
export const PALPATINE_SUPERSET_LABELS = [ 'CAS', 'EAS', 'NAS', 'SAS', 'SEA', 'WAS', 'XAS' ] as const;
export const PALPATINE_SET_LABELS = [
  'TRK',
  'DAI',
  'HAN',
  'KOR',
  'JAP',
  'SIB',
  'TOT',
  'BNG',
  'BIR',
  'NIN',
  'PNY',
  'SIN',
  'TOD',
  'ADI',
  'AET',
  'AUN',
  'MYA',
  'NEG',
  'VNM',
  'LEV',
  'AFR',
  'NAT',
  'EUR',
  'OCE',
] as const;

export const skywalkerLabelColors: SkywalkerLabelColors = {
  AFR: '#9B19f5',
  AHG: '#681797',
  AMR: '#50E991',
  ARC: '#FA00AA',
  AUN: '#E30B5F',
  BYZ: '#00E5FF',
  CHI: '#E30BDE',
  DSA: '#EAB300',
  EAF: '#AF0077',
  EAS: '#DC0AB4',
  EEU: '#007AFF',
  EUR: '#0BB4FF',
  IBE: '#1939FF',
  JPN: '#FF01CF',
  LEV: '#EB3927',
  LME: '#E60049',
  MPA: '#007B33',
  NAF: '#EC407A',
  NAM: '#5F9D00',
  NAN: '#79AD00',
  NNA: '#50BE00',
  NWE: '#2277EE',
  OCE: '#007B77',
  RMN: '#2959FF',
  SAS: '#FFA300',
  SEA: '#E30B81',
  VIK: '#00FBFF',
  WAF: '#9C13AF',
  WAS: '#CC2737',
};

export const vaderLabelColors: VaderLabelColors = {
  AFR: '#17a86e',
  AHG: '#81ca8f',
  AMR: '#47a2ab',
  ARC: '#91baf6',
  AUS: '#9A81CC',
  BNG: '#ED5163',
  BSQ: '#2b2412',
  CAS: '#F88693',
  CAU: '#aa8b47',
  EAF: '#47aa5a',
  EAS: '#4766aa',
  EEU: '#c5ab74',
  EUR: '#aa8b47',
  HAN: '#6b93dc',
  HIM: '#37568c',
  IBE: '#d3c197',
  ITA: '#e0d5bf',
  JPN: '#4766aa',
  KOR: '#88ade5',
  LME: '#ffc805',
  MDE: '#f8e364',
  MEL: '#6efccf',
  MSE: '#7144B6',
  NAF: '#e09b41',
  NAM: '#38898d',
  NAN: '#1e5e5e',
  NEA: '#bdd5f4',
  NGR: '#26722f',
  NMA: '#a6d5d9',
  NNA: '#47a1aa',
  NWE: '#866e38',
  NWS: '#AE5268',
  OCE: '#03eea3',
  OTHER: '#8B8982',
  OTHERS: '#8B8982',
  PSE: '#E9DBFF',
  SAI: '#E8CAD0',
  SAS: '#E94753',
  SCA: '#4c3f20',
  SEA: '#5100C8',
  SEI: '#21f4a7',
  SNG: '#338b41',
  SOI: '#E8CAD0',
};

export const huttLabelColors: HuttLabelColors = {
  AFR: '#17a86e',
  AHG: '#81ca8f',
  ALK: '#4755A9',
  AMZ: '#398E79',
  ARC: '#91baf6',
  AUS: '#3576BB',
  BAS: '#A98247',
  BRI: '#A97947',
  CAM: '#398E6B',
  CHI: '#478DA9',
  CJW: '#A97147',
  DSA: '#E74656',
  EAF: '#47aa5a',
  EAS: '#4766aa',
  EEU: '#c5ab74',
  EUR: '#aa8b47',
  FRE: '#A96947',
  GER: '#A96147',
  GRL: '#A95947',
  HJW: '#8C6C4A',
  IRI: '#87684A',
  ITA: '#e0d5bf',
  JAP: '#4A6A87',
  LEV: '#82634A',
  LME: '#ffc805',
  MAY: '#38898D',
  MEI: '#7C5F4B',
  MEL: '#6efccf',
  MES: '#54736B',
  NAF: '#FCBE03',
  NAM: '#38898d',
  NAN: '#1e5e5e',
  NEU: '#775C4B',
  OCE: '#03eea3',
  PWL: '#72584B',
  ROM: '#6D554A',
  SAN: '#3A645B',
  SAS: '#E94753',
  SBL: '#68534A',
  SCA: '#4c3f20',
  SEA: '#496A97',
  SLA: '#64504A',
  SON: '#3A7873',
  SPA: '#524847',
  TAL: '#4E4746',
  WAF: '#1E7137',
  WEA: '#DF8E0C',
};

export const palpatineLabelColors: PalpatineLabelColors = {
  ADI: '#A162F8',
  AET: '#5D09D3',
  AFR: '#E7E183',
  AUN: '#8719F5',
  BIR: '#F0B000',
  BNG: '#E68A00',
  CAS: '#50e991',
  DAI: '#DD88AB',
  EAS: '#CB79A7',
  EUR: '#E9FCAC',
  HAN: '#E792AD',
  JAP: '#D27FAA',
  KOR: '#F3A5B2',
  LEV: '#F58F00',
  MYA: '#9A36F7',
  NAS: '#00fbff',
  NAT: '#ECF08F',
  NEG: '#B52BE3',
  NIN: '#EEFF38',
  OCE: '#EAF69C',
  PNY: '#F2A131',
  SAS: '#E69F00',
  SEA: '#9b19f5',
  SIB: '#1FDAFF',
  SIN: '#FFF01A',
  TOD: '#FFD505',
  TOT: '#52B7FF',
  TRK: '#75FFD8',
  VNM: '#AC24EB',
  WAS: '#D55E00',
  XAS: '#DDCC77',
};
