import { NamedModel } from 'api/Exec/types';
import { Org, OrgModel } from 'api/Orgs/types';
import { useNamedModels, useOrgModels, useUpdateOrgModels } from 'components/Dashboard/Orgs/Wizard/OrgModels/hooks';

import { Button, Checkbox, Transfer } from 'antd';
import type { CheckboxProps, TransferProps } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
  org: Org;
}

const OrgModels: React.FC<Props> = ({ org }) => {
  const { isLoading: areNamedModelsLoading, namedModels, fetchNamedModels } = useNamedModels();
  const { isLoading: areOrgModelsLoading, orgModels, fetchOrgModels } = useOrgModels();
  const { isUpdating, updateOrgModels } = useUpdateOrgModels();
  const [ hideDeprecated, setHideDeprecated ] = useState<boolean>(true);
  const [ sourceData, setSourceData ] = useState<{ key: string, title: string }[]>([]);
  const [ targetKeys, setTargetKeys ] = useState<TransferProps['targetKeys']>([]);
  const [ selectedKeys, setSelectedKeys ] = useState<TransferProps['targetKeys']>([]);

  const onChange: TransferProps['onChange'] = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange: TransferProps['onSelectChange'] = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([ ...sourceSelectedKeys, ...targetSelectedKeys ]);
  };

  const onSave = () => {
    updateOrgModels(org.id, targetKeys);
  };

  const onCheckboxChange: CheckboxProps['onChange'] = (e) => {
    setHideDeprecated(e.target.checked);
  };

  useEffect(() => {
    const targetKeys = orgModels.map((model: OrgModel) => model.modelName);
    const sourceData = namedModels.map((model: NamedModel) => ({ key: model.modelName, title: model.modelName }));
    setSourceData(sourceData);
    setTargetKeys(targetKeys);
  }, [ namedModels, orgModels ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchNamedModels(hideDeprecated, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchNamedModels, hideDeprecated ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchOrgModels(org.id, hideDeprecated, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchOrgModels, org, hideDeprecated ]);

  return (
    <>
      <Checkbox onChange={onCheckboxChange} checked={hideDeprecated} style={{ marginTop: 8 }}>Hide deprecated models</Checkbox>

      <Transfer
        disabled={areNamedModelsLoading || areOrgModelsLoading || isUpdating}
        dataSource={sourceData}
        titles={[ 'Available models', 'Organization models' ]}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        render={item => item.title}
        style={{ marginTop: 24 }}
      />

      <Button type="primary" style={{ marginTop: 24 }} onClick={onSave}>Save</Button>
    </>
  );
};

export default OrgModels;
