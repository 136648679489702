import { ModelApiName } from 'api/Exec/types';
import {
  HUTT_SET_LABELS,
  huttLabelColors,
  PALPATINE_SET_LABELS,
  palpatineLabelColors,
  SKYWALKER_SET_LABELS,
  skywalkerLabelColors,
  VADER_SET_LABELS,
  vaderLabelColors,
} from 'app/consts/labels';
import { SelectOption } from 'app/types/Forms';
import { SkywalkerLabel, SuperSetLabel, VaderLabel, Label, HuttLabel, PalpatineLabel } from 'app/types/Labels';

const getAdmixLegendItem = (label: Label, colors: any) => ({
  name: label,
  marker: {
    symbol: 'square' as any,
    style: {
      fill: colors[label],
    },
  },
  value: '',
});

export const getAdmixLegend = (modelApiName: ModelApiName) => {
  if (modelApiName === 'vader') {
    return VADER_SET_LABELS.map(label => getAdmixLegendItem(label, vaderLabelColors));
  } else if (modelApiName === 'hutt') {
    return HUTT_SET_LABELS.map(label => getAdmixLegendItem(label, huttLabelColors));
  } else if (modelApiName === 'palpatine') {
    return PALPATINE_SET_LABELS.map(label => getAdmixLegendItem(label, palpatineLabelColors));
  }

  return SKYWALKER_SET_LABELS.map(label => getAdmixLegendItem(label, skywalkerLabelColors));
};

export const getColor = (modelApiName: ModelApiName, series: Label, defaultColor = 'white') => {
  if (modelApiName === 'vader') {
    return vaderLabelColors[series as VaderLabel] || defaultColor;
  } else if (modelApiName === 'hutt') {
    return huttLabelColors[series as HuttLabel] || defaultColor;
  } else if (modelApiName === 'palpatine') {
    return palpatineLabelColors[series as PalpatineLabel] || defaultColor;
  }

  return skywalkerLabelColors[series as SkywalkerLabel] || defaultColor;
};

export const getAncestralOptions = (modelApiName: ModelApiName): SelectOption[] => {
  if (modelApiName === 'vader') {
    return [
      { label: 'AFR - Africa', value: 'AFR' },
      { label: 'AMR - America', value: 'AMR' },
      { label: 'EAS - East Asia', value: 'EAS' },
      { label: 'EUR - Europe', value: 'EUR' },
      { label: 'LME - Levantine & Middle East', value: 'LME' },
      { label: 'OCE - Oceania', value: 'OCE' },
      { label: 'SAS - South Asia', value: 'SAS' },
      { label: 'SEA - Southeast Asia', value: 'SEA' },
      { label: 'Others', value: 'OTHERS' },
    ];
  } else if (modelApiName === 'hutt') {
    return [
      { label: 'AFR - Africa', value: 'AFR' },
      { label: 'NAM - North America', value: 'NAM' },
      { label: 'EAS - East Asia', value: 'EAS' },
      { label: 'EUR - Europe', value: 'EUR' },
      { label: 'LME - Levantine & Middle East', value: 'LME' },
      { label: 'OCE - Oceania', value: 'OCE' },
      { label: 'SAS - South Asia', value: 'SAS' },
    ];
  } else if (modelApiName === 'palpatine') {
    return [
      { label: 'CAS - Central Asia', value: 'CAS' },
      { label: 'EAS - East Asia', value: 'EAS' },
      { label: 'NAS - North Asia', value: 'NAS' },
      { label: 'SAS - South Asia', value: 'SAS' },
      { label: 'SEA - Southeast Asia', value: 'SEA' },
      { label: 'WAS - West Asia', value: 'WAS' },
      { label: 'LME - Levantine & Middle East', value: 'LME' },
      { label: 'XAS - Non-Asian', value: 'XAS' },
    ];
  }

  // for skywalker
  return [
    { label: 'AFR - Africa', value: 'AFR' },
    { label: 'AMR - America', value: 'AMR' },
    { label: 'EAS - East Asia', value: 'EAS' },
    { label: 'EUR - Europe', value: 'EUR' },
    { label: 'LME - Levantine & Middle East', value: 'LME' },
    { label: 'OCE - Oceania', value: 'OCE' },
    { label: 'SAS - South Asia', value: 'SAS' },
  ];
};

export const getFirstAncestralOptions = (modelApiName: ModelApiName): SuperSetLabel => getAncestralOptions(modelApiName).shift()!.value as SuperSetLabel;
