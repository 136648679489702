import { Model, NamedModel } from 'api/Exec/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import NamedModelForm from 'components/Dashboard/Models/NamedModelForm/NamedModelForm';
import { DashboardPageProps } from 'components/Dashboard/types';
import useColumns from 'components/Dashboard/Models/NamedModels/hooks';

import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Typography } from 'antd';

interface Props extends DashboardPageProps {
  initNamedModels: NamedModel[];
  models: Model[];
}

const NamedModels: React.FC<Props> = ({ setTitle, models, initNamedModels }) => {
  const [ namedModelToEdit, setNamedModelToEdit ] = useState<NamedModel | null>(null);
  const [ isAddNamedModelModalOpen, setIsAddNamedModelModalOpen ] = useState<boolean>(false);
  const openAddNamedModelModal = (namedModelId: NamedModel | null) => {
    setIsAddNamedModelModalOpen(true);
    setNamedModelToEdit(namedModelId);
  };
  const closeNamedModelModal = () => {
    setIsAddNamedModelModalOpen(false);
    setNamedModelToEdit(null);
  };
  const { columns } = useColumns(openAddNamedModelModal);
  const { searchParam } = usePaginationParams();
  const { tablePagination } = useTablePagination();
  const [ namedModels, setNamedModels ] = useState<NamedModel[]>([]);
  const pagination = tablePagination(namedModels.length);

  const addNamedModelAndCloseModal = (namedModel: NamedModel) => {
    setNamedModels((prevState: NamedModel[]) => [ namedModel, ...prevState ]);
    closeNamedModelModal();
  };

  const updateNamedModelAndCloseModal = (updatedNamedModel: NamedModel) => {
    const namedModelIndex = namedModels.findIndex((namedModel: NamedModel) => namedModel.id === updatedNamedModel.id);
    setNamedModels([
      ...namedModels.slice(0, namedModelIndex),
      updatedNamedModel,
      ...namedModels.slice(namedModelIndex + 1, namedModels.length),
    ]);

    closeNamedModelModal();
  };

  useEffect(() => {
    setTitle('Named models');
  }, [ setTitle ]);

  useEffect(() => {
    if (searchParam) {
      setNamedModels(initNamedModels.filter((model: NamedModel) => model.modelName.toLowerCase().includes(searchParam.toLowerCase())));
    } else {
      setNamedModels(initNamedModels);
    }
  }, [ searchParam, initNamedModels ]);

  return (
    <>
      <TableTopPanel
        RightColumn={<Button type="primary" onClick={() => openAddNamedModelModal(null)} icon={<PlusOutlined />}>Add named model</Button>}
        placeholder="Search named models"
      />

      <Table
        rowKey={record => String(record.id)}
        columns={columns}
        dataSource={namedModels}
        pagination={pagination}
        scroll={{ x: true }}
      />

      <Modal
        title={<Typography.Title level={4}>{namedModelToEdit ? 'Edit' : 'Add'} named model</Typography.Title>}
        open={isAddNamedModelModalOpen}
        footer={null}
        width={420}
        onCancel={closeNamedModelModal}
        destroyOnClose
      >
        <NamedModelForm
          onCancel={closeNamedModelModal}
          models={models}
          onNamedModelAdded={addNamedModelAndCloseModal}
          onNamedModelUpdated={updateNamedModelAndCloseModal}
          namedModel={namedModelToEdit}
        />
      </Modal>
    </>
  );
};

export default NamedModels;
