import { CreditLedgerFilterParams } from 'api/Credit/types';
import { Org } from 'api/Orgs/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import { DEFAULT_PAGE, LEDGER_DEFAULT_PAGE_SIZE } from 'app/consts/pagination';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import { useColumns, useCreditLedger } from 'components/Dashboard/Billing/CreditLedger/hooks';
import CreditLedgerFilter from 'components/Dashboard/Billing/CreditLedger/CreditLedgerFilter/CreditLedgerFilter';
import { getCreditBalanceAsync } from 'store/credits/creditsSlice';
import { useAppDispatch } from 'store/hooks';

import React, { useCallback, useEffect } from 'react';
import { Table } from 'antd';

interface Props {
  org?: Org;
}

const CreditLedger: React.FC<Props> = ({ org }) => {
  const dispatch = useAppDispatch();
  const {
    pageParam,
    pageSizeParam,
    searchParam,
    orgParam,
    creditServiceTypeParam,
    modelApiParam,
    minDateParam,
    maxDateParam,
    fullLedgerParam,
    setPaginationParams,
  } = usePaginationParams(DEFAULT_PAGE, LEDGER_DEFAULT_PAGE_SIZE);

  useEffect(() => {
    setPaginationParams(DEFAULT_PAGE, LEDGER_DEFAULT_PAGE_SIZE);
  }, []);

  const { columns } = useColumns(org);
  const { creditLedger, fetchCreditLedger, isLoading } = useCreditLedger();
  const { tablePagination } = useTablePagination();
  const pagination = tablePagination(creditLedger.count, [ 100, LEDGER_DEFAULT_PAGE_SIZE, 1000 ]);

  const getFilter = useCallback((): CreditLedgerFilterParams => ({
    search: searchParam,
    orgs: org ? [ org.id ] : orgParam.split(','),
    type: creditServiceTypeParam,
    modelApi: modelApiParam,
    minDate: minDateParam,
    maxDate: maxDateParam,
    showFullLedger: !!fullLedgerParam,
  }), [ org, orgParam, creditServiceTypeParam, modelApiParam, minDateParam, maxDateParam, searchParam, fullLedgerParam ]);

  const reload = async () => {
    await fetchCreditLedger(pageParam, pageSizeParam, getFilter());
    if (org) {
      dispatch(getCreditBalanceAsync({ orgId: org.id }));
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchCreditLedger(pageParam, pageSizeParam, getFilter(), abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchCreditLedger, getFilter, pageParam, pageSizeParam ]);

  return (
    <>
      <TableTopPanel placeholder="Search by order id" onReload={reload} Filter={CreditLedgerFilter} orgId={org?.id} />

      <Table
        rowKey={creditLedger => creditLedger.id}
        columns={columns}
        dataSource={creditLedger.results}
        pagination={pagination}
        loading={isLoading}
        scroll={{ x: true }}
      />
    </>
  );
};

export default CreditLedger;
