import { getOrdersRequest } from 'api/Exec/api';
import { OrdersFilterParams, PaginatedOrders } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useOrders = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ response, setResponse ] = useState<PaginatedOrders>({ count: 0, results: [] });

  const fetchOrders = useCallback((pageNumber: number, pageSize: number, filter: OrdersFilterParams, abortController?: AbortController) => {
    setIsLoading(true);
    getOrdersRequest(pageNumber, pageSize, filter, abortController)
      .then((response) => {
        setResponse(response.data);
      })
      .catch((reason) => {
        if (!abortController?.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { response, isLoading, fetchOrders, setResponse };
};

export default useOrders;
