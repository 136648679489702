import { CreditTariff } from 'api/Credit/types';
import useUpdateCreditTariff from 'components/Dashboard/Billing/CreditTariffs/UpdateCreditTariffForm/useUpdateCreditTariff';

import React from 'react';
import { Button, Form, InputNumber, Space } from 'antd';

interface FormValues {
  price: number;
}

interface Props {
  creditTariff: CreditTariff;
  onCancel: () => void;
  onCreditTariffUpdated: (creditTariff: CreditTariff) => void;
}

const UpdateCreditTariffForm: React.FC<Props> = ({ creditTariff, onCancel, onCreditTariffUpdated }) => {
  const [ form ] = Form.useForm<FormValues>();
  const { isUpdating, updateCreditTariff } = useUpdateCreditTariff(onCreditTariffUpdated);

  const onFinish = (values: FormValues) => updateCreditTariff(creditTariff.id, values.price);

  return (
    <Form
      form={form}
      name="updateCreditTariff"
      style={{ width: 360 }}
      layout="vertical"
      onFinish={onFinish}
      disabled={isUpdating}
      initialValues={{ price: creditTariff.price }}
    >
      <Form.Item name="price" label="Price" rules={[ { required: true, message: 'Field is mandatory' } ]}>
        <InputNumber placeholder="Enter price" style={{ width: '100%' }} precision={0} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Update tariff plan
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UpdateCreditTariffForm;
