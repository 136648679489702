import { OrderStatistics } from 'api/Statistics/types';

import { ColumnsType } from 'antd/es/table';

const useColumns = () => {
  const columns: ColumnsType<OrderStatistics> = [
    {
      title: 'Model name',
      dataIndex: 'modelApiName',
      key: 'modelApiName',
      render: (_, { modelApiName }) => {
        return modelApiName || 'Total';
      },
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
      align: 'center',
    },
    {
      title: 'Failed',
      dataIndex: 'failed',
      key: 'failed',
      align: 'center',
    },
    {
      title: 'Reports failed',
      dataIndex: 'reportsFailed',
      key: 'reportsFailed',
      align: 'center',
    },
    {
      title: 'In progress',
      dataIndex: 'inProgress',
      key: 'inProgress',
      align: 'center',
    },
    {
      title: 'GNT',
      dataIndex: 'gnt',
      key: 'gnt',
      align: 'center',
    },
    {
      title: 'WGS',
      dataIndex: 'wgs',
      key: 'wgs',
      align: 'center',
    },
  ];

  return { columns };
};

export default useColumns;
