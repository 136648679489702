import { File } from 'api/Data/types';
import { addSampleAliasRequest } from 'api/Data/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { message } from 'antd';

export const useAddSampleAlias = (onFileUploaded: (file: File) => void) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const addSampleAlias = (fileId: string, sampleAlias: string) => {
    setIsLoading(true);

    addSampleAliasRequest(fileId, sampleAlias)
      .then(({ data }) => {
        onFileUploaded(data);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { isLoading, addSampleAlias };
};
