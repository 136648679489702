import { ORG_ID_NEW } from 'components/Dashboard/Orgs/consts';

export const homeUrl = '/';

export const dashboardUrl = 'dashboard';
export const plotsUrl = 'plots';
export const skywalkerUrl = `${plotsUrl}/skywalker`;
export const vaderUrl = `${plotsUrl}/vader`;
export const huttUrl = `${plotsUrl}/hutt`;
export const palpatineUrl = `${plotsUrl}/palpatine`;
export const ordersUrl = 'orders';
export const filesUrl = 'files';
export const usersUrl = 'users';
export const orgsUrl = 'organizations';
export const createOrgUrl = `${orgsUrl}/${ORG_ID_NEW}`;
export const editOrgUrl = `${orgsUrl}/:orgId`;
export const statisticsUrl = 'statistics';
export const modelsUrl = 'models';
export const billingUrl = 'billing';

export const completeRegistrationUrl = 'complete-registration';
export const resetPasswordUrl = 'reset-password';
