const usersUrl = 'users';
const userResourceUrl = (userId: string) => `${usersUrl}/${userId}`;
const usersListUrl = (page: number, pageSize: number, email: string, orgs: string[], showApiKeys = false) => (
  `${usersUrl}?page=${page}&page_size=${pageSize}&email=${email}&org_names=${orgs}&show_api_keys=${showApiKeys}`
);
const activateUserUrl = `${usersUrl}/activate`;
const resendInviteUrl = (userId: string) => `${userResourceUrl(userId)}/resend-invite`;
const forgotPasswordUrl = `${usersUrl}/forgot-password`;
const resetPasswordUrl = `${usersUrl}/reset-password`;

export default { userResourceUrl, usersListUrl, usersUrl, activateUserUrl, forgotPasswordUrl, resetPasswordUrl, resendInviteUrl };
