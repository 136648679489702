import { getAdmixLegend, getColor } from 'components/Dashboard/Plots/helpers';
import { HasChartFilter } from 'components/Dashboard/Plots/types';
import useAdmix from 'components/Dashboard/Plots/Admix/hooks';
import Groups from 'components/Dashboard/Plots/Admix/Groups/Groups';
import { useAppSelector } from 'store/hooks';
import { selectAdmixImage } from 'store/plots/plotsSlice';

import { Column, ColumnConfig } from '@ant-design/plots';
import { Empty, Spin, Typography } from 'antd';
import React, { useEffect, forwardRef } from 'react';

const Admix = forwardRef<HTMLDivElement, HasChartFilter>(({ filter }, ref) => {
  const {
    columns,
    meta,
    fetchAdmix,
    isLoading,
    image,
    downloadAdmix,
    isDownloading,
    setImage,
  } = useAdmix();
  const admixImage = useAppSelector(state => selectAdmixImage(state, filter.plotRequestId));

  useEffect(() => {
    const abortController = new AbortController();
    if (filter.plotRequestId) {
      if (admixImage) {
        setImage({ base64: admixImage.base64, filename: admixImage.filename });
      } else {
        downloadAdmix(filter.plotRequestId, abortController);
      }
    } else {
      fetchAdmix(filter, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ downloadAdmix, fetchAdmix, filter, admixImage, setImage ]);

  const columnConfig: ColumnConfig = {
    data: columns,
    xField: 'x',
    yField: 'y',
    seriesField: 'series',
    isPercent: true,
    isStack: true,
    intervalPadding: 0,
    height: 480,
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      flipPage: false,
      itemSpacing: 10,
      items: getAdmixLegend(filter.modelApiName),
      animate: false,
    },
    loading: isLoading,
    xAxis: {
      tickInterval: 0,
    },
    yAxis: {
      grid: null,
      tickCount: 11,
      label: {
        formatter: (_, item) => item.value.toFixed(1),
      },
    },
    color: ({ series }) => getColor(filter.modelApiName, series),
    tooltip: {
      customItems: (originalItems) => {
        return originalItems.length > 1 ? originalItems : [];
      },
    },
  };

  if (isDownloading) {
    return (
      <Empty
        description="Plot is loading"
        image={<Spin />}
        style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
      />
    );
  }

  return (
    <div ref={ref} style={{ position: 'relative' }}>
      {image.base64 ? (
        <img src={`data:image/png;base64,${image.base64}`} alt="Admix plot" style={{ maxWidth: '100%' }} />
      ) : (
        <>
          <Groups {...meta} />
          <Column {...columnConfig} />
          <Typography.Text type="secondary" style={{ fontSize: 12, marginLeft: 24, marginTop: -8, display: 'block' }}>
            Samples count: {meta.total_count}
          </Typography.Text>
        </>
      )}
    </div>
  );
});

export default Admix;
