import { TableFilterParams } from 'api/Dashboard/types';
import { FilterProps } from 'components/Dashboard/types';

import { Button, Form, Input, Space } from 'antd';
import React from 'react';

const TablesFilter: React.FC<FilterProps<TableFilterParams>> = ({ onApply }) => {
  const [ form ] = Form.useForm<TableFilterParams>();

  const onReset = () => {
    form.resetFields();
    onApply({ orderId: '', sampleId: '' });
  };

  const onFinish = (values: TableFilterParams) => onApply(values);

  return (
    <Form
      form={form}
      name="orgFilter"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ width: 290 }}
      initialValues={{ orderId: '', sampleId: '' }}
    >
      <Form.Item name="sampleId" label="Sample id">
        <Input placeholder="Enter sample id" />
      </Form.Item>

      <Form.Item name="orderId" label="Order id">
        <Input placeholder="Enter order id" />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default TablesFilter;
