import { ModelApiName } from 'api/Exec/types';
import { SelectOption } from 'app/types/Forms';
import { selectOrgModels } from 'store/orgs/orgsSlice';
import { useAppSelector } from 'store/hooks';

import { useMemo } from 'react';

const useOrgModelsOptions = (modelApiName: ModelApiName) => {
  const orgModels = useAppSelector(selectOrgModels);

  const orgModelsOptions = useMemo(() => {
    return orgModels
      .filter(model => model.modelApiName === modelApiName)
      .map((model): SelectOption => ({
        value: model.modelName,
        label: model.modelName,
      }));
  }, [ orgModels, modelApiName ]);

  return { orgModelsOptions };
};

export default useOrgModelsOptions;
