import { File } from 'api/Data/types';
import { useAddSampleAlias } from 'components/Dashboard/Files/AddSampleAliasForm/hooks';

import { Button, Form, Input, Space } from 'antd';
import React from 'react';

interface FormValues {
  sampleAlias: string;
}

interface Props {
  file: File;
  onCancel: () => void;
  onSampleAliasAdded: (file: File) => void;
}

const AddSampleAliasForm: React.FC<Props> = ({ file, onCancel, onSampleAliasAdded }) => {
  const [ form ] = Form.useForm<FormValues>();

  const { isLoading, addSampleAlias } = useAddSampleAlias(onSampleAliasAdded);

  const onFinish = (values: FormValues) => {
    addSampleAlias(file.id, values.sampleAlias);
  };

  return (
    <Form
      form={form}
      name="addSampleAliasForm"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ marginTop: 24 }}
      disabled={isLoading}
      initialValues={{ sampleAlias: file.sampleAlias }}
    >
      <Form.Item
        name="sampleAlias"
        label="Sample alias"
      >
        <Input placeholder="Enter sample alias" />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Add
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AddSampleAliasForm;
