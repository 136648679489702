import { AxiosResponse } from 'axios';

export const getFileFromAxiosBlobResponse = (response: AxiosResponse) => {
  const { data, headers } = response;
  const fileName = getFileName(headers['content-disposition']);
  const file = window.URL.createObjectURL(data);
  const link = document.createElement('a');

  link.setAttribute('href', file);
  link.setAttribute('download', fileName);
  link.click();

  setTimeout(() => window.URL.revokeObjectURL(file), 0); // otherwise there will be unnecessarily spiking memory
};

export const getFileName = (content: string | null | undefined): string => {
  let fileName = 'results';

  if (content && content.includes('filename="')) {
    fileName = content.split('filename="')[1].slice(0, -1);
  }

  return fileName;
};
