import { dashboardUrl, homeUrl, ordersUrl } from 'app/consts/urls';
import useAuth from 'app/hooks/useAuth';
import useMyUser from 'app/hooks/useMyUser';
import AppLoader from 'app/common_components/AppLoader/AppLoader';
import AuthContainer from 'containers/AuthContainer/AuthContainer';
import DashboardContainer from 'containers/DashboardContainer/DashboardContainer';
import { useAppSelector } from 'store/hooks';
import { selectUsersStatus, selectMyUser } from 'store/users/usersSlice';

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

const App: React.FC = () => {
  useAuth();
  useMyUser();

  const myUserStatus = useAppSelector(selectUsersStatus);
  const { id } = useAppSelector(selectMyUser);

  return myUserStatus === 'loading' ? <AppLoader /> : (
    <Routes>
      <Route path={`/${dashboardUrl}/*`} element={id ? <DashboardContainer /> : <Navigate to={homeUrl} />} />
      <Route path="/*" element={!id ? <AuthContainer /> : <Navigate to={`/${dashboardUrl}/${ordersUrl}`} />} />
    </Routes>
  );
};

export default App;
