import { PdfReportsFilterParams } from 'api/Data/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import useTablePagination from 'app/hooks/useTablePagination';
import { usePdfReportColumns, usePdfReports } from 'components/Dashboard/Orders/hooks';
import PdfReportsFilter from 'components/Dashboard/Orders/PdfReportsFilter/PdfReportsFilter';

import { Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

interface Props {
  orderId: string;
}

const PdfReports: React.FC<Props> = ({ orderId }) => {
  const { isLoading, fetchPdfReports, response } = usePdfReports();
  const { columns } = usePdfReportColumns();
  const [ filter, setFilter ] = useState<PdfReportsFilterParams>({
    reportVersion: '',
    requestVersion: '',
    sampleId: '',
  });

  const { tablePagination, resetLocalPagination } = useTablePagination(true);
  const pagination = useMemo(() => tablePagination(response.count), [ response.count, tablePagination ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (pagination.current && pagination.pageSize) {
      fetchPdfReports(orderId, pagination.current, pagination.pageSize, filter, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchPdfReports, orderId, pagination.current, pagination.pageSize, filter ]);

  const applyFilter = (values: PdfReportsFilterParams) => {
    resetLocalPagination();
    setFilter(values);
  };
  
  const reload = () => {
    if (pagination.current && pagination.pageSize) {
      fetchPdfReports(orderId, pagination.current, pagination.pageSize, filter);
    }
  };

  return (
    <>
      <TableTopPanel Filter={PdfReportsFilter} onApplyFilter={applyFilter} onReload={reload} />

      <Table
        rowKey={pdfReport => pdfReport.id}
        columns={columns}
        dataSource={response.results}
        pagination={pagination}
        loading={isLoading}
        scroll={{ x: true }}
      />
    </>
  );
};

export default PdfReports;
