const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const WEBSOCKET_ACCESS_TOKEN_KEY = 'websocketToken';

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);
export const setAccessToken = (token: string) => localStorage.setItem(ACCESS_TOKEN_KEY, token);
export const removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN_KEY);

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);
export const setRefreshToken = (token: string) => localStorage.setItem(REFRESH_TOKEN_KEY, token);
export const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN_KEY);

export const getWebsocketAccessToken = () => localStorage.getItem(WEBSOCKET_ACCESS_TOKEN_KEY);
export const setWebsocketAccessToken = (token: string) => localStorage.setItem(WEBSOCKET_ACCESS_TOKEN_KEY, token);
export const removeWebsocketAccessToken = () => localStorage.removeItem(WEBSOCKET_ACCESS_TOKEN_KEY);
