import { axiosInstance } from 'api/instance';
import { Statistics } from 'api/Statistics/types';
import urls from 'api/Statistics/urls';

import { AxiosResponse } from 'axios';

export const getStatisticsRequest = (
  orgs = '',
  minData = '',
  maxDate = '',
  { signal }: AbortController,
): Promise<AxiosResponse<Statistics>> => {
  const orgNames = orgs.split(',');
  return axiosInstance.get<Statistics>(urls.statisticsUrl(orgNames, minData, maxDate), { signal });
};

export const getOrdersUsageRequest = (
  orgs = '',
  minData = '',
  maxDate = '',
  plotDateRange = '',
  { signal }: AbortController,
): Promise<AxiosResponse<string>> => {
  const orgNames = orgs.split(',');
  return axiosInstance.get<string>(urls.ordersUsageUrl(orgNames, minData, maxDate, plotDateRange), { signal });
};
