import { CreditService, PaginatedCreditServices } from 'api/Credit/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import CreditServiceForm from 'components/Dashboard/Billing/CreditServices/CreditServiceForm/CreditServiceForm';
import { useCreditServices, useColumns } from 'components/Dashboard/Billing/CreditServices/hooks';

import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Typography } from 'antd';

const CreditServices: React.FC = () => {
  const { isLoading, creditServices, fetchCreditServices, setCreditServices } = useCreditServices();
  const { pageParam, pageSizeParam } = usePaginationParams();

  const onCreditServiceDeleted = (creditServiceId: string) => {
    const creditServiceIndex = creditServices.results.findIndex((creditService: CreditService) => creditService.id === creditServiceId);
    setCreditServices({
      count: creditServices.count,
      results: [
        ...creditServices.results.slice(0, creditServiceIndex),
        ...creditServices.results.slice(creditServiceIndex + 1, creditServices.results.length),
      ],
    });
  };

  const { columns } = useColumns(onCreditServiceDeleted);
  const { tablePagination } = useTablePagination();
  const pagination = tablePagination(creditServices.count);

  const [ isAddCreditServiceModalOpen, setIsAddCreditServiceModalOpen ] = useState<boolean>(false);
  const openAddCreditServiceModal = () => setIsAddCreditServiceModalOpen(true);
  const closeAddCreditServiceModal = () => setIsAddCreditServiceModalOpen(false);

  useEffect(() => {
    const abortController = new AbortController();
    fetchCreditServices(pageParam, pageSizeParam, abortController);

    return () => {
      abortController.abort();
    };
  }, [ pageParam, pageSizeParam, fetchCreditServices ]);

  const onCreditServiceAdded = (creditService: CreditService) => {
    setCreditServices((prevState: PaginatedCreditServices) => {
      if (prevState.results.length === pagination.pageSize) {
        prevState.results.pop();
      }
      prevState.results.unshift(creditService);

      return { count: prevState.count + 1, results: [ ...prevState.results ] };
    });
    closeAddCreditServiceModal();
  };

  return (
    <>
      <TableTopPanel RightColumn={<Button disabled type="primary" onClick={openAddCreditServiceModal} icon={<PlusOutlined />}>Add service</Button>} />

      <Table
        rowKey={record => String(record.id)}
        columns={columns}
        dataSource={creditServices.results}
        pagination={pagination}
        scroll={{ x: true }}
        loading={isLoading}
      />

      <Modal
        title={<Typography.Title level={4}>Add service</Typography.Title>}
        open={isAddCreditServiceModalOpen}
        footer={null}
        width={420}
        onCancel={closeAddCreditServiceModal}
      >
        <CreditServiceForm onCancel={closeAddCreditServiceModal} onCreditServiceAdded={onCreditServiceAdded} />
      </Modal>
    </>
  );
};

export default CreditServices;
