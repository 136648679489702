import { Org } from 'api/Orgs/types';
import OrgBilling from 'components/Dashboard/Orgs/Wizard/OrgBilling/OrgBilling';
import OrgForm from 'components/Dashboard/Orgs/Wizard/OrgForm/OrgForm';
import OrgModels from 'components/Dashboard/Orgs/Wizard/OrgModels/OrgModels';
import SftpUsers from 'components/Dashboard/Orgs/Wizard/SftpUsers/SftpUsers';
import ApiUsers from 'components/Dashboard/Users/ApiUsers/ApiUsers';
import WebUsers from 'components/Dashboard/Users/WebUsers/WebUsers';

import { Tabs, TabsProps } from 'antd';
import React from 'react';

interface Props {
  org?: Org;
}

const Wizard: React.FC<Props> = ({ org }) => {
  const isCreateFlow = org === undefined;

  const tabs: TabsProps['items'] = [
    {
      key: 'general',
      label: 'General',
      children: <OrgForm org={org} />,
    },
    {
      key: 'sftp',
      label: 'SFTP users',
      disabled: isCreateFlow,
      children: org ? <SftpUsers org={org} /> : null,
    },
    {
      key: 'api',
      label: 'API keys',
      disabled: isCreateFlow,
      children: <ApiUsers org={org} />,
    },
    {
      key: 'users',
      label: 'Web users',
      disabled: isCreateFlow,
      children: <WebUsers org={org} />,
    },
    {
      key: 'models',
      label: 'Models',
      disabled: isCreateFlow,
      children: org ? <OrgModels org={org} /> : null,
    },
    {
      key: 'billing',
      label: 'Billing',
      disabled: isCreateFlow,
      children: org ? <OrgBilling org={org} /> : null,
    },
  ];

  return <Tabs defaultActiveKey="general" items={tabs} type="card" destroyInactiveTabPane />;
};

export default Wizard;
