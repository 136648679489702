import { activateUserRequest } from 'api/Users/api';
import { textColorSecondary } from 'app/consts/colors';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { homeUrl } from 'app/consts/urls';
import PasswordInputs from 'app/common_components/PasswordInputs/PasswordInputs';
import { useAppDispatch } from 'store/hooks';
import { login } from 'store/auth/authSlice';

import { Typography, Form, Input, Button, Alert, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface FormValues {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
}

const CompleteRegistration: React.FC = () => {
  const [ form ] = Form.useForm<FormValues>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [ error, setError ] = useState<string>();

  const [ searchParams ] = useSearchParams();
  const code = searchParams.get('code') || '';

  useEffect(() => {
    if (!code) {
      navigate(homeUrl);
    }
  }, [ code, navigate ]);

  const onFinish = (values: FormValues) => {
    activateUserRequest(code, values.password, values.firstName, values.lastName)
      .then((response) => {
        const { data: { access, refresh, websocketAccess } } = response;
        dispatch(login({ access, refresh, websocketAccess }));
      })
      .catch((reason) => {
        setError(getErrorFromPromiseReason(reason));
      });
  };

  return (
    <>
      <Typography.Title level={3} style={{ marginBottom: 0 }}>Complete registration</Typography.Title>
      <Typography.Text style={{ color: textColorSecondary }}>Please enter your name and set the password</Typography.Text>

      <Form
        form={form}
        name="completeRegistration"
        style={{ marginTop: 24 }}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item
          label="First name"
          name="firstName"
          rules={[ { required: true, message: 'Please enter your first name' } ]}
        >
          <Input placeholder="Enter your first name" />
        </Form.Item>

        <Form.Item
          label="Last name"
          name="lastName"
          rules={[ { required: true, message: 'Please enter your last name' } ]}
        >
          <Input placeholder="Enter your last name" />
        </Form.Item>

        <PasswordInputs />

        {error && (
          <Form.Item>
            <Alert message={error} type="error" showIcon banner />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            Submit
          </Button>
        </Form.Item>

        <Space style={{ justifyContent: 'center', width: '100%' }}>
          <Typography.Text style={{ color: textColorSecondary }}>
            Go to
          </Typography.Text>
          <Typography.Link onClick={() => navigate(homeUrl)}>
            Sign In
          </Typography.Link>
        </Space>
      </Form>
    </>
  );
};

export default CompleteRegistration;
