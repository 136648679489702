import { getAccessToken, getRefreshToken, getWebsocketAccessToken } from 'app/helpers/auth';
import { useAppDispatch } from 'store/hooks';
import { login, logout } from 'store/auth/authSlice';

import { useEffect } from 'react';

const useAuth = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const access = getAccessToken() || '';
    const refresh = getRefreshToken() || '';
    const websocketAccess = getWebsocketAccessToken() || '';

    if (access) {
      dispatch(login({ access, refresh, websocketAccess }));
    } else {
      dispatch(logout());
    }
  }, [ dispatch ]);
};

export default useAuth;
