import { getModelsImagesRequest } from 'api/Exec/api';
import { DockerImage } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';

const useDockerImages = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ dockerImages, setDockerImages ] = useState<DockerImage[]>([]);

  const fetchDockerImages = useCallback((abortController: AbortController) => {
    getModelsImagesRequest(abortController)
      .then((response) => {
        setDockerImages(response.data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    fetchDockerImages(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchDockerImages ]);

  return { dockerImages, isLoading };
};

export default useDockerImages;
