import { Role } from 'api/Users/types';
import { NOT_PERMITTED } from 'app/consts/messages';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import { Typography } from 'antd';
import React from 'react';

interface Props {
  children: JSX.Element;
  roles: Role[];
}

const PrivateRoute: React.FC<Props> = ({ children, roles }) => {
  const { role } = useAppSelector(selectMyUser);
  const userHasRequiredRole = roles.includes(role);

  if (!userHasRequiredRole) {
    return (
      <Typography.Text type="danger">
        {NOT_PERMITTED}
      </Typography.Text>
    );
  }

  return children;
};

export default PrivateRoute;
