import { SelectOption } from 'app/types/Forms';
import { Org } from 'api/Orgs/types';
import { selectOrgs } from 'store/orgs/orgsSlice';
import { useAppSelector } from 'store/hooks';

import { useMemo } from 'react';

const useOrgsOptions = () => {
  const orgs = useAppSelector(selectOrgs);

  const orgsOptions = useMemo(() => orgs
    .map((org: Org): SelectOption => ({
      value: org.name,
      label: org.name,
    })), [ orgs ]);

  const orgsIdsOptions = useMemo(() => orgs
    .map((org: Org): SelectOption => ({
      value: org.id,
      label: org.name,
    })), [ orgs ]);

  const getOrgNameByOrgId = (orgId: string) => orgs.find(o => o.id === orgId)?.name;

  return { orgsOptions, orgsIdsOptions, getOrgNameByOrgId };
};

export default useOrgsOptions;
