import { OrgQueue, Queue } from 'api/Statistics/types';

import { ColumnsType } from 'antd/es/table';

const useOrgQueueColumns = () => {
  const orgQueueColumns: ColumnsType<OrgQueue> = [
    {
      title: 'Org name',
      dataIndex: 'orgName',
      key: 'orgName',
    },
    {
      title: ' Total orders',
      dataIndex: 'ordersTotal',
      key: 'ordersTotal',
      align: 'center',
    },
  ];

  const queueColumns: ColumnsType<Queue> = [
    {
      title: 'Queue name',
      dataIndex: 'queueName',
      key: 'queueName',
    },
    {
      title: 'Orders',
      dataIndex: 'ordersTotal',
      key: 'ordersTotal',
    },
  ];

  return { orgQueueColumns, queueColumns };
};

export default useOrgQueueColumns;
