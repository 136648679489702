import { MAX_ROWS_IN_SEARCH_FILE_SELECT } from 'app/consts/files';
import { dashboardUrl, filesUrl } from 'app/consts/urls';

import React from 'react';
import { Divider, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

export interface Props {
  menu: React.ReactElement;
  fileName: string;
  count: number;
}

const Dropdown: React.FC<Props> = ({ menu, fileName, count }) => {
  const navigate = useNavigate();
  const goToFiles = () => navigate(`/${dashboardUrl}/${filesUrl}?search=${fileName}`, { replace: true });

  return (
    <>
      {menu}
      {count > MAX_ROWS_IN_SEARCH_FILE_SELECT && (
        <>
          <Divider style={{ margin: '8px 0' }} />
          <div style={{ padding: '0 8px 4px' }}>
            <Typography.Text type="secondary">More than 10 files were found.</Typography.Text>
            <br />
            <Typography.Link onClick={goToFiles}>View all results on the files page</Typography.Link>
          </div>
        </>
      )}
    </>
  );
};

export default Dropdown;
