import { SelectOption } from 'app/types/Forms';
import { selectMyUser } from 'store/users/usersSlice';
import { useAppSelector } from 'store/hooks';

import { useMemo } from 'react';

const useOrgModelsOptions = () => {
  const { org: { availableModels } } = useAppSelector(selectMyUser);

  const orgModelsOptions = useMemo(() => {
    return availableModels.map((model: string): SelectOption => ({
      value: model,
      label: model,
    }));
  }, [ availableModels ]);

  return { orgModelsOptions };
};

export default useOrgModelsOptions;
