import { axiosInstance } from 'api/instance';
import { PaginatedFiles, File, FilesFilterParams, Sample, PaginatedPdfReports, PdfReportsFilterParams } from 'api/Data/types';
import urls from 'api/Data/urls';

import { AxiosResponse } from 'axios';

export const getFilesRequest = (page: number, pageSize: number, filter: FilesFilterParams, abort?: AbortController): Promise<AxiosResponse<PaginatedFiles>> => {
  return axiosInstance.get<PaginatedFiles>(urls.filesListUrl(page, pageSize, filter), { signal: abort?.signal });
};

export const uploadFileRequest = (file: FormData): Promise<AxiosResponse<File>> => {
  return axiosInstance.post<File>(urls.uploadFileUrl, file, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const downloadFileRequest = (fileId: string): Promise<AxiosResponse> => {
  return axiosInstance.get(urls.downloadFileUrl(fileId), { responseType: 'blob' });
};

export const downloadResultsRequest = (orderId: string, resultType: string, pdfRequestId?: string): Promise<AxiosResponse> => {
  return axiosInstance.get(urls.downloadResultsUrl(orderId, resultType, pdfRequestId), { responseType: 'blob' });
};

export const addSampleAliasRequest = (fileId: string, sampleAlias: string): Promise<AxiosResponse<File>> => {
  return axiosInstance.put<File>(urls.fileResourceUrl(fileId), { sampleAlias });
};

export const deleteFileRequest = (fileId: string): Promise<AxiosResponse> => {
  return axiosInstance.delete(urls.fileResourceUrl(fileId));
};

export const requestPdfRequest = (orderId: string, sampleId: string): Promise<AxiosResponse<{ detail: string }>> => {
  return axiosInstance.post<{ detail: string }>(urls.requestPdfUrl(orderId, sampleId));
};

export const getSamplesRequest = (orderId: string, { signal }: AbortController): Promise<AxiosResponse<Sample[]>> => {
  return axiosInstance.get<Sample[]>(urls.samplesUrl(orderId), { signal });
};

export const getPdfReportsRequest = (
  orderId: string,
  page: number,
  pageSize: number,
  filter: PdfReportsFilterParams,
  abortController?: AbortController,
): Promise<AxiosResponse<PaginatedPdfReports>> => {
  return axiosInstance.get<PaginatedPdfReports>(urls.pdfReportsListUrl(orderId, page, pageSize, filter), { signal: abortController?.signal });
};

export const uploadResultsRequest = (formData: FormData): Promise<AxiosResponse<File>> => {
  return axiosInstance.post<File>(urls.resultsUploadUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};
