import { textColorSecondary } from 'app/consts/colors';
import { useConfirmCode } from 'components/Auth/SignIn/EnterCodeForm/useConfirmCode';
import { useRequestNewCode } from 'components/Auth/SignIn/EnterCodeForm/useRequestNewCode';

import { Alert, Typography, Form, Input, Button, Space } from 'antd';
import React, { ChangeEvent, KeyboardEvent, MouseEvent, ClipboardEvent } from 'react';

interface FormValues {
  [key: string]: string;
}

interface Props {
  mfaSessionId: string;
}

const EnterCodeForm: React.FC<Props> = ({ mfaSessionId }) => {
  const [ form ] = Form.useForm<FormValues>();
  const { error, isLoading, confirm } = useConfirmCode();
  const { error: requestError, requestNewCode, timeLeft } = useRequestNewCode();

  const onFinish = (values: FormValues) => {
    const code: string = Object.values(values).reduce((result: string, current: string) => result + current, '');
    confirm(code, mfaSessionId);
  };

  const inputIds = [ 1, 2, 3, 4, 5, 6 ];

  const onInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const numericId = Number(event.target.id.replace(/[a-zA-Z]|_/g, ''));
      if (numericId < inputIds.length) {
        (document.querySelector(`#enterCode_code${numericId + 1}`) as HTMLInputElement).select();
      }
    }
  };

  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const numericId = Number((event.target as HTMLInputElement).id.replace(/[a-zA-Z]|_/g, ''));
    if (event.code === 'ArrowRight' && numericId < inputIds.length) {
      (document.querySelector(`#enterCode_code${numericId + 1}`) as HTMLInputElement).select();
    }

    if (event.code === 'ArrowLeft' && numericId > 1) {
      (document.querySelector(`#enterCode_code${numericId - 1}`) as HTMLInputElement).select();
    }
  };

  const onClick = (event: MouseEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).select();
  };

  const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const stringFromClipboard = event.clipboardData.getData('Text').split('');
    const numericId = Number((event.target as HTMLInputElement).id.replace(/[a-zA-Z]|_/g, ''));
    for (let i = numericId, arrayIndex = 0; i <= inputIds.length; i++, arrayIndex++) {
      form.setFieldValue(`code${i}`, stringFromClipboard[arrayIndex]);
    }
    event.preventDefault();
  };

  const onRequestNewCode = () => {
    requestNewCode(mfaSessionId);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography.Title level={3} style={{ marginBottom: 0 }}>Please enter 6-digits code</Typography.Title>
      <Typography.Text style={{ color: textColorSecondary }}>We&apos;ve just sent a confirmation code to the email address you provided.</Typography.Text>

      <Form
        name="enterCode"
        form={form}
        style={{ marginTop: 24 }}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
        disabled={isLoading}
      >
        <Space>
          {inputIds.map(id => (
            <Form.Item name={`code${id}`} rules={[ { required: true, message: '' } ]} key={id} style={{ maxWidth: 35 }}>
              <Input
                maxLength={1}
                onClick={onClick}
                onKeyUp={onKeyUp}
                onInput={onInput}
                onPaste={onPaste}
                inputMode="numeric"
                data-testid={`code${id}`}
                style={{ textAlign: 'center' }}
              />
            </Form.Item>
          ))}
        </Space>

        <Form.Item>
          <Typography.Link onClick={onRequestNewCode} disabled={timeLeft > 0}>
            Request a new code
          </Typography.Link>
          <br />
          {timeLeft > 0 && `You can request a new code after ${timeLeft} seconds`}
        </Form.Item>

        {(error || requestError) && (
          <Form.Item>
            <Alert message={error || requestError} type="error" showIcon banner style={{ textAlign: 'left' }} />
          </Form.Item>
        )}

        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={isLoading}>
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EnterCodeForm;
