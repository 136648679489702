import { postRequestNewCodeRequest } from 'api/Auth/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { setMfaSessionId } from 'store/auth/authSlice';
import { useAppDispatch } from 'store/hooks';

import { useState, useEffect } from 'react';
import { message } from 'antd';

export const useRequestNewCode = () => {
  const dispatch = useAppDispatch();

  const [ error, setError ] = useState<string>();
  const [ timeLeft, setTimeLeft ] = useState(60); // seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    if (timeLeft <= 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [ timeLeft ]);

  const requestNewCode = (mfaSessionId: string) => {
    postRequestNewCodeRequest(mfaSessionId)
      .then((response) => {
        setTimeLeft(60);
        message.success('New confirmation code was sent to your email address');
        dispatch(setMfaSessionId(response.data.mfaSessionId));
      })
      .catch((reason) => {
        const error = getErrorFromPromiseReason(reason);
        if (error && error.startsWith('You can request new code')) {
          const value = error.match(/\d+/g);
          if (value) {
            setTimeLeft(Number(value.join('')));
          }
        } else {
          setError(error);
        }
      });
  };

  return { error, requestNewCode, timeLeft };
};
