import { PlotRequest } from 'api/Dashboard/types';
import { formatDate, formatDateTime } from 'app/helpers/dateTime';
import useTagsOptions from 'app/hooks/useTagsOptions';
import { ChartFilter } from 'components/Dashboard/Plots/types';

import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Flex, Tag, Tooltip, Typography } from 'antd';

const usePlotRequestsColumns = (onBuildPlotClick: () => void, setFilter: (value: React.SetStateAction<ChartFilter>) => void) => {
  const { getTagNameById } = useTagsOptions();

  const buildPlot = (id: string, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onBuildPlotClick();
    setFilter(prevState => ({ ...prevState, plotRequestId: id }));
  };

  const renderDateTag = (minDate: string, maxDate: string) => {
    if (minDate && !maxDate) {
      return `From: ${formatDate(minDate)}`;
    }

    if (!minDate && maxDate) {
      return `To: ${formatDate(maxDate)}`;
    }

    return `${formatDate(minDate)} - ${formatDate(maxDate)}`;
  };

  const columns: ColumnsType<PlotRequest> = [
    {
      title: 'Requested',
      render: (_, { id, createdAt, completed }) => {
        if (!completed) {
          return formatDateTime(createdAt);
        }

        return (
          <Tooltip title="Download plots on click">
            <Typography.Link onClick={e => buildPlot(id, e)} style={{ whiteSpace: 'nowrap' }}>{formatDateTime(createdAt)}</Typography.Link>
          </Tooltip>
        );
      },
    },
    {
      key: 'itemsCount',
      title: 'Samples',
      align: 'center',
      dataIndex: 'itemsCount',
    },
    {
      title: 'Grouping',
      align: 'center',
      render: (_, { filter: { groupBy } }) => {
        return groupBy ? <CheckOutlined data-testid="grouping" /> : '';
      },
    },
    {
      title: 'Sorting',
      align: 'center',
      render: (_, { filter: { sortBy } }) => {
        return sortBy ? <CheckOutlined data-testid="sorting" /> : '';
      },
    },
    {
      title: 'Model',
      render: (_, { filter: { modelName } }) => {
        return modelName ? <Tag>{modelName}</Tag> : '';
      },
    },
    {
      title: 'Date range',
      render: (_, { filter: { minDate, maxDate } }) => {
        return (minDate || maxDate) ? <Tag>{renderDateTag(minDate, maxDate)}</Tag> : '';
      },
    },
    {
      title: 'Threshold',
      render: (_, { filter: { ancestralComponent, threshold } }) => {
        return threshold > 0 ? <Tag>{ancestralComponent} {threshold}</Tag> : '';
      },
    },
    {
      title: 'Tags',
      render: (_, { filter: { tagsIds } }) => {
        const tagsIdsArray = tagsIds ? tagsIds.split(',') : [];

        return (
          <Flex wrap="wrap" gap="small">
            {tagsIdsArray.length > 0 && tagsIdsArray.map(id => <Tag key={id}>{getTagNameById(id)}</Tag>)}
          </Flex>
        );
      },
    },
    {
      title: 'Status',
      render: (_, { completed }) => (
        completed ? <Tag color="purple">Completed</Tag> : <Tag color="blue">Building</Tag>
      ),
    },
  ];

  return { columns };
};

export default usePlotRequestsColumns;
