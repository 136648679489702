import { goldenPurple5, goldenPurple6, goldenPurple7, textColor, white } from 'app/consts/colors';
import { IBM_PLEX_SANS } from 'app/consts/fonts';

import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    colorPrimary: goldenPurple6,
    fontFamily: IBM_PLEX_SANS,
    colorText: textColor,
    colorLink: goldenPurple6,
    colorLinkHover: goldenPurple5,
    colorLinkActive: goldenPurple7,
  },
  components: {
    Button: {
      borderRadius: 6,
    },
    Input: {
      borderRadius: 6,
    },
    Form: {
      paddingXS: 0,
    },
    Transfer: {
      listWidth: 300,
      listHeight: 500,
    },
    Layout: {
      triggerBg: white,
    },
  },
};

export default theme;
