import { Role } from 'api/Users/types';
import useOrgsOptions from 'app/hooks/useOrgsOptions';
import usePaginationParams from 'app/hooks/usePaginationParams';
import { FilterParams } from 'app/types/Filters';
import { FilterProps } from 'components/Dashboard/types';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import { Button, Checkbox, DatePicker, Form, Select, Space } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const { RangePicker } = DatePicker;

const FilesFilter: React.FC<FilterProps> = ({ onApply }) => {
  const { role: myUserRole } = useAppSelector(selectMyUser);
  const [ form ] = Form.useForm<FilterParams>();
  const { orgParam, minDateParam, maxDateParam, typeParam, setFilters, clearFilters } = usePaginationParams();
  const { orgsOptions } = useOrgsOptions();

  const onReset = () => {
    form.setFieldValue('org', []);
    form.setFieldValue('date', [ null, null ]);
    form.setFieldValue('type', false);
    clearFilters();
  };

  const onFinish = (values: FilterParams) => {
    setFilters(values);
    onApply();
  };

  return (
    <Form
      form={form}
      name="filesFilter"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ width: 290 }}
      initialValues={{
        org: orgParam !== '' ? orgParam.split(',') : [],
        date: [
          minDateParam ? dayjs(minDateParam) : null,
          maxDateParam ? dayjs(maxDateParam) : null,
        ],
        type: !!typeParam,
      }}
    >
      {myUserRole === Role.SYSTEM_ADMIN && (
        <Form.Item name="org" label="Organizations">
          <Select placeholder="Select organizations" mode="multiple" options={orgsOptions} />
        </Form.Item>
      )}

      <Form.Item name="date" label="Uploaded">
        <RangePicker allowEmpty={[ true, true ]} format="MM/DD/YYYY" />
      </Form.Item>

      <Form.Item valuePropName="checked" name="type">
        <Checkbox>Show external</Checkbox>
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default FilesFilter;
