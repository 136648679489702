import { PdfReportsFilterParams } from 'api/Data/types';
import { FilterProps } from 'components/Dashboard/types';

import { Button, Form, Input, InputNumber, Space } from 'antd';
import React from 'react';

const PdfReportsFilter: React.FC<FilterProps<PdfReportsFilterParams>> = ({ onApply }) => {
  const [ form ] = Form.useForm<PdfReportsFilterParams>();

  const onReset = () => {
    form.resetFields();
    onApply({ requestVersion: '', reportVersion: '', sampleId: '' });
  };

  const onFinish = (values: PdfReportsFilterParams) => {
    onApply({
      ...values,
      requestVersion: values.requestVersion ?? '',
    });
  };

  return (
    <Form
      form={form}
      name="pdfReportsFilter"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ width: 290 }}
      initialValues={{ requestVersion: '', reportVersion: '', sampleId: '' }}
    >
      <Form.Item name="requestVersion" label="Request number">
        <InputNumber placeholder="Enter request number" style={{ width: '100%' }} precision={0} />
      </Form.Item>

      <Form.Item name="reportVersion" label="Report version">
        <Input placeholder="Enter report version" />
      </Form.Item>

      <Form.Item name="sampleId" label="Sample id">
        <Input placeholder="Enter sample id" />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default PdfReportsFilter;
