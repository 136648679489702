import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'app/consts/pagination';
import usePaginationParams from 'app/hooks/usePaginationParams';

import { PaginationProps, TablePaginationConfig } from 'antd';
import { useCallback, useMemo, useState } from 'react';

const useTablePagination = (useLocalState?: boolean) => {
  const [ page, setPage ] = useState<number>(DEFAULT_PAGE);
  const [ pageSize, setPageSize ] = useState<number>(DEFAULT_PAGE_SIZE);
  const { pageParam, pageSizeParam, setPaginationParams } = usePaginationParams();

  const onPaginationChange: PaginationProps['onChange'] = useCallback((pageNumber: number, pageSize: number) => {
    setPaginationParams(pageNumber, pageSize);
  }, [ setPaginationParams ]);

  const onLocalPaginationChange: PaginationProps['onChange'] = useCallback((pageNumber: number, pageSize: number) => {
    setPage(pageNumber);
    setPageSize(pageSize);
  }, [ setPage, setPageSize ]);

  const tablePagination = useMemo(() => (total: number, pageSizeOptions = [ 10, 25, 50 ]): TablePaginationConfig => ({
    total,
    onChange: useLocalState ? onLocalPaginationChange : onPaginationChange,
    pageSize: useLocalState ? pageSize : pageSizeParam,
    current: useLocalState ? page : pageParam,
    showSizeChanger: true,
    showTotal: (total, range) => `${range[0]}–${range[1]} of ${total}`,
    showQuickJumper: true,
    pageSizeOptions,
  }), [ pageParam, pageSizeParam, onPaginationChange, onLocalPaginationChange, page, pageSize, useLocalState ]);

  const resetLocalPagination = () => onLocalPaginationChange(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);

  return { tablePagination, resetLocalPagination };
};

export default useTablePagination;
