import { SelectOption } from 'app/types/Forms';
import { Model, NamedModel } from 'api/Exec/types';
import { useAddNamedModel, useEditNamedModel } from 'components/Dashboard/Models/NamedModelForm/hooks';

import React from 'react';
import { Button, Form, Select, Input, Space } from 'antd';

interface FormValues {
  modelName: string;
  modelImage: string;
}

interface Props {
  onCancel: () => void;
  models: Model[];
  onNamedModelAdded: (namedModel: NamedModel) => void;
  onNamedModelUpdated: (namedModel: NamedModel) => void;
  namedModel: NamedModel | null;
}

const NamedModelForm: React.FC<Props> = ({ onCancel, models, onNamedModelAdded, onNamedModelUpdated, namedModel }) => {
  const [ form ] = Form.useForm<FormValues>();
  const { addNamedModel, isCreating } = useAddNamedModel(onNamedModelAdded);
  const { editNamedModel, isUpdating } = useEditNamedModel(onNamedModelUpdated);
  const modelsOptions: SelectOption[] = models.filter((model: Model) => model.enabled).map((model: Model) => ({
    label: `${model.modelImageTag} ${model.modelApiName}`,
    value: model.id,
  }));
  const modelImageId = namedModel ? modelsOptions.find(m => m.label === namedModel.modelImageTag)?.value : undefined;

  const onFinish = (values: FormValues) => {
    const { modelName, modelImage } = values;
    if (namedModel) {
      editNamedModel(namedModel.id, modelImage, modelName);
    } else {
      addNamedModel(modelImage, modelName);
    }
  };

  return (
    <Form
      form={form}
      name="namedModel"
      style={{ width: 360 }}
      layout="vertical"
      onFinish={onFinish}
      disabled={isCreating || isUpdating}
      initialValues={{
        modelName: namedModel ? namedModel.modelName : '',
        modelImage: modelImageId,
      }}
    >
      <Form.Item
        name="modelName"
        label="Model name"
        rules={[ { required: true, message: 'Field is mandatory' } ]}
      >
        <Input placeholder="Enter model name" />
      </Form.Item>

      <Form.Item
        name="modelImage"
        label="Model image"
        rules={[ { required: true, message: 'Field is mandatory' } ]}
      >
        <Select placeholder="Select model image" options={modelsOptions} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            {namedModel ? 'Edit' : 'Add'} named model
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default NamedModelForm;
