import { dashboardUrl, ordersUrl } from 'app/consts/urls';
import { SetLabelsItem, SuperSetLabelsItem } from 'api/Dashboard/types';
import { ModelApiName } from 'api/Exec/types';
import {
  SKYWALKER_SET_LABELS,
  VADER_SET_LABELS,
  HUTT_SET_LABELS,
  PALPATINE_SET_LABELS,
  HUTT_SUPERSET_LABELS,
  PALPATINE_SUPERSET_LABELS,
  SKYWALKER_SUPERSET_LABELS,
  VADER_SUPERSET_LABELS,
} from 'app/consts/labels';
import { SetLabelType, SuperSetLabelType } from 'app/types/Labels';
import { LabelType } from 'components/Dashboard/Plots/types';

import { Typography } from 'antd';
import { ColumnsType, ColumnType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useLabelColumns = (type: LabelType, modelApiName: ModelApiName, isSorted: string) => {
  const navigate = useNavigate();
  const goToOrders = (orderId: string) => navigate(`/${dashboardUrl}/${ordersUrl}?search=${orderId}`, { replace: true });
  const [ labels, setLabels ] = useState<SetLabelType | SuperSetLabelType | []>([]);

  useEffect(() => {
    if (modelApiName === 'vader') {
      setLabels(type === 'set' ? VADER_SET_LABELS : VADER_SUPERSET_LABELS);
    } else if (modelApiName === 'hutt') {
      setLabels(type === 'set' ? HUTT_SET_LABELS : HUTT_SUPERSET_LABELS);
    } else if (modelApiName === 'palpatine') {
      setLabels(type === 'set' ? PALPATINE_SET_LABELS : PALPATINE_SUPERSET_LABELS);
    } else if (modelApiName === 'skywalker') {
      setLabels(type === 'set' ? SKYWALKER_SET_LABELS : SKYWALKER_SUPERSET_LABELS);
    }
  }, [ modelApiName, type ]);

  const columns: ColumnsType<SetLabelsItem | SuperSetLabelsItem> = [
    ...(isSorted ? [ {
      title: 'Group',
      dataIndex: 'group',
    } as ColumnType<SetLabelsItem | SuperSetLabelsItem> ] : []),
    {
      title: 'Sample id',
      dataIndex: 'sampleId',
    },
    {
      title: 'Order id',
      render: (_, { orderId }) => <Typography.Link onClick={() => goToOrders(orderId)} style={{ whiteSpace: 'nowrap' }}>{orderId}</Typography.Link>,
    },
    ...labels.map((label) => {
      return {
        title: label,
        dataIndex: label.toLowerCase(),
        render: value => value?.toFixed(6),
      } as ColumnType<SetLabelsItem | SuperSetLabelsItem>;
    }),
  ];

  return { columns };
};

export default useLabelColumns;
