import { plotsUrl } from 'app/consts/urls';
import { useSideMenuItems, useSelectedKey } from 'containers/DashboardContainer/components/SideMenu/hooks';

import React from 'react';
import { Menu } from 'antd';

const SideMenu: React.FC = () => {
  const { items } = useSideMenuItems();
  const { selectedKey } = useSelectedKey();

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={[ plotsUrl ]}
      selectedKeys={[ selectedKey ]}
      style={{ border: 'none' }}
      items={items}
    />
  );
};

export default SideMenu;
