import { copy } from 'app/helpers/copy';

import { Space, Typography } from 'antd';
import React from 'react';

interface Props {
  hint: string
}

const ConnectionHint: React.FC<Props> = ({ hint }) => {
  const copyHint = () => copy(hint, 'Hint was copied');

  return (
    <Space direction="vertical" size="middle" align="center">
      <Typography.Text code>{hint}</Typography.Text>
      <Typography.Link onClick={copyHint}>Copy connection hint</Typography.Link>
    </Space>
  );
};

export default ConnectionHint;
