import 'components/Dashboard/Plots/Plots.css';
import { ModelApiName } from 'api/Exec/types';
import { homeUrl } from 'app/consts/urls';
import downloadImage from 'app/helpers/downloadImage';
import { SearchParams } from 'app/types/PaginationParams';
import { getFirstAncestralOptions } from 'components/Dashboard/Plots/helpers';
import { DashboardPageProps } from 'components/Dashboard/types';
import { ChartFilter } from 'components/Dashboard/Plots/types';
import Admix from 'components/Dashboard/Plots/Admix/Admix';
import PlotsFilter from 'components/Dashboard/Plots/PlotsFilter/PlotsFilter';
import Donut from 'components/Dashboard/Plots/Donut/Donut';
import LabelTable from 'components/Dashboard/Plots/LabelTable/LabelTable';
import PlotRequests from 'components/Dashboard/Plots/PlotRequests/PlotRequests';
import { useAppSelector } from 'store/hooks';
import { selectOrgModels, selectOrgModelsStatus } from 'store/orgs/orgsSlice';

import { DownOutlined } from '@ant-design/icons';
import type { CollapseProps, MenuProps, TabsProps } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { Button, Collapse, Dropdown, Space, Tabs, Typography } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface Props extends DashboardPageProps {
  modelApiName: ModelApiName;
}

const Plots: React.FC<Props> = ({ setTitle, modelApiName }) => {
  const [ activeKey, setActiveKey ] = useState<string>('');
  const [ searchParams, setSearchParams ] = useSearchParams();
  const plotRequestIdParam = searchParams.get(SearchParams.PLOT_REQUEST_ID) || '';
  const navigate = useNavigate();
  const orgModels = useAppSelector(selectOrgModels).map(orgModel => orgModel.modelApiName);
  const status = useAppSelector(selectOrgModelsStatus);

  const switchCollapseKey = (key: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => setActiveKey((prevState) => {
    const target = event.target as HTMLDivElement;
    // collapse/extend only on header clicks
    if (target.closest('.ant-collapse-header')) {
      return prevState === key ? '' : key;
    }
    return key;
  });

  const collapse = () => setActiveKey('');

  useEffect(() => {
    if (status === 'idle' && !orgModels.includes(modelApiName)) {
      navigate(homeUrl);
    }
  }, [ status, orgModels, modelApiName, navigate ]);

  useEffect(() => {
    setTitle('Plots');
  });

  const initFilter: ChartFilter = useMemo(() => {
    return {
      groupBy: 'sp_group',
      sortBy: '',
      allRecords: false,
      tagsIds: '',
      modelName: '',
      modelApiName,
      ancestralComponent: getFirstAncestralOptions(modelApiName),
      threshold: 0,
      minDate: '',
      maxDate: '',
      plotRequestId: plotRequestIdParam,
    };
  }, [ plotRequestIdParam, modelApiName ]);
  const admixRef = useRef<HTMLDivElement>(null);
  const donutRef = useRef<HTMLDivElement>(null);

  const [ filter, setFilter ] = useState<ChartFilter>(initFilter);

  useEffect(() => {
    setFilter(prevState => ({ ...prevState, plotRequestId: plotRequestIdParam }));
  }, [ plotRequestIdParam, searchParams, setSearchParams ]);

  const downloadPlot = async (e: MenuInfo, ref: React.RefObject<HTMLDivElement>, name: string) => {
    e.domEvent.stopPropagation();
    if (ref === null || ref.current === null) {
      return;
    }

    await downloadImage(ref.current, `${name}.png`);
  };

  const exportDropdownItems: MenuProps['items'] = [
    {
      key: 'admixChart',
      label: 'Admixture plot',
      onClick: e => downloadPlot(e, admixRef, 'Admix'),
    },
    {
      key: 'donutChart',
      label: 'Donut plot',
      onClick: e => downloadPlot(e, donutRef, 'Donut'),
    },
  ];

  const collapseItems: CollapseProps['items'] = [ {
    key: 'configuration',
    onClick: e => switchCollapseKey('configuration', e),
    label: <Typography.Link>Plots configuration</Typography.Link>,
    children: <PlotsFilter modelApiName={modelApiName} setFilter={setFilter} />,
    extra: (
      <Dropdown menu={{ items: exportDropdownItems }}>
        <Button onClick={e => e.stopPropagation()}>
          <Space>
            Export plots
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    ),
  }, {
    destroyInactivePanel: true,
    key: 'plotRequests',
    onClick: e => switchCollapseKey('plotRequests', e),
    label: <Typography.Link>Requests history</Typography.Link>,
    children: <PlotRequests modelApiName={modelApiName} onBuildPlotClick={collapse} setFilter={setFilter} />,
  } ];
  
  const tabs: TabsProps['items'] = [
    {
      key: 'plots',
      label: 'Plots',
      children: (
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Space direction="vertical" style={{ width: '100%' }} size="middle">
            <Collapse
              className="collapsibleArea"
              size="large"
              accordion
              bordered
              items={collapseItems}
              collapsible="header"
              activeKey={activeKey}
            />
            <Admix ref={admixRef} filter={filter} />
          </Space>
          <Space size="large">
            <Donut ref={donutRef} filter={filter} />
            {/* <Heatmap /> */}
          </Space>
        </Space>
      ),
    },
    {
      key: 'tableSet',
      label: 'Set table',
      children: <LabelTable filter={filter} type="set" />,
      destroyInactiveTabPane: true,
    },
    {
      key: 'tableSuperSet',
      label: 'Superset table',
      children: <LabelTable filter={filter} type="superset" />,
      destroyInactiveTabPane: true,
    },
  ];

  return <Tabs defaultActiveKey="1" items={tabs} />;
};

export default Plots;
