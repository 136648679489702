import { postConfirmRequest } from 'api/Auth/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { login } from 'store/auth/authSlice';
import { useAppDispatch } from 'store/hooks';

import { useState } from 'react';

export const useConfirmCode = () => {
  const dispatch = useAppDispatch();

  const [ error, setError ] = useState<string>();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const confirm = (code: string, mfaSessionId: string) => {
    setIsLoading(true);

    postConfirmRequest(code, mfaSessionId)
      .then((response) => {
        const { data: { access, refresh, websocketAccess } } = response;
        dispatch(login({ access, refresh, websocketAccess }));
      })
      .catch((reason) => {
        setError(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { error, isLoading, confirm, setError };
};
