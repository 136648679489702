import useReplenishBalance from 'components/Dashboard/Orgs/Wizard/OrgBilling/ReplenishForm/useReplenishBalance';

import React from 'react';
import { Button, Form, InputNumber, Space } from 'antd';

interface FormValues {
  amount: number;
}

interface Props {
  orgId: string;
  onCancel: () => void;
}

const ReplenishForm: React.FC<Props> = ({ orgId, onCancel }) => {
  const [ form ] = Form.useForm<FormValues>();
  const { isLoading, replenish } = useReplenishBalance(onCancel);

  const onFinish = (values: FormValues) => replenish(orgId, values.amount);

  return (
    <Form
      form={form}
      name="replenish"
      style={{ width: 360 }}
      layout="vertical"
      onFinish={onFinish}
      disabled={isLoading}
    >
      <Form.Item name="amount" label="Amount of credits" rules={[ { required: true, message: 'Field is mandatory' } ]}>
        <InputNumber placeholder="Enter amount of credits" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Replenish
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ReplenishForm;
