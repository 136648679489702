import { deleteCreditTariffRequest } from 'api/Credit/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useState } from 'react';

const useDeleteCreditTariff = (onCreditTariffDeleted: (creditTariffId: string) => void) => {
  const [ deletingId, setDeletingId ] = useState<string | null>(null);

  const deleteCreditTariff = (creditTariffId: string) => {
    setDeletingId(creditTariffId);

    deleteCreditTariffRequest(creditTariffId)
      .then(() => {
        message.success('Tariff was removed');
        onCreditTariffDeleted(creditTariffId);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setDeletingId(null);
      });
  };

  return { deletingId, deleteCreditTariff };
};

export default useDeleteCreditTariff;
