import { CreditTariffsFilterParams, CreditTariff, PaginatedCreditTariffs } from 'api/Credit/types';
import { Org } from 'api/Orgs/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import { useColumns, useCreditTariffs } from 'components/Dashboard/Billing/CreditTariffs/hooks';
import AddCreditTariffForm from 'components/Dashboard/Billing/CreditTariffs/AddCreditTariffForm/AddCreditTariffForm';
import CreditTariffsFilter from 'components/Dashboard/Billing/CreditTariffs/CreditTariffsFilter/CreditTariffsFilter';
import UpdateCreditTariffForm from 'components/Dashboard/Billing/CreditTariffs/UpdateCreditTariffForm/UpdateCreditTariffForm';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import React, { useCallback, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Typography } from 'antd';

interface Props {
  org?: Org;
}

const CreditTariffs: React.FC<Props> = ({ org }) => {
  const { org: { id: myUserOrgId } } = useAppSelector(selectMyUser);
  const { isLoading, creditTariffs, fetchCreditTariffs, setCreditTariffs } = useCreditTariffs();
  const { pageParam, pageSizeParam, orgParam, creditServiceTypeParam, modelApiParam } = usePaginationParams();
  const [ updatedCreditTariff, setUpdatedCreditTariff ] = useState<CreditTariff | null>(null);
  const openUpdateCreditTariffModal = (creditTariff: CreditTariff) => setUpdatedCreditTariff(creditTariff);
  const closeUpdateCreditTariffModal = () => setUpdatedCreditTariff(null);

  const onCreditTariffDeleted = (creditTariffId: string) => {
    const creditTariffIndex = creditTariffs.results.findIndex((creditTariff: CreditTariff) => creditTariff.id === creditTariffId);
    setCreditTariffs({
      count: creditTariffs.count,
      results: [
        ...creditTariffs.results.slice(0, creditTariffIndex),
        ...creditTariffs.results.slice(creditTariffIndex + 1, creditTariffs.results.length),
      ],
    });
  };

  const { columns } = useColumns(onCreditTariffDeleted, openUpdateCreditTariffModal, org);
  const { tablePagination } = useTablePagination();
  const pagination = tablePagination(creditTariffs.count);

  const [ isAddCreditTariffModalOpen, setIsAddCreditTariffModalOpen ] = useState<boolean>(false);
  const openAddCreditTariffModal = () => setIsAddCreditTariffModalOpen(true);
  const closeAddCreditTariffModal = () => setIsAddCreditTariffModalOpen(false);

  const getFilter = useCallback((): CreditTariffsFilterParams => ({
    orgs: org ? [ org.id ] : orgParam.split(','),
    type: creditServiceTypeParam,
    modelApi: modelApiParam,
  }), [ org, orgParam, creditServiceTypeParam, modelApiParam ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchCreditTariffs(pageParam, pageSizeParam, getFilter(), abortController);

    return () => {
      abortController.abort();
    };
  }, [ pageParam, pageSizeParam, fetchCreditTariffs, getFilter ]);

  const onCreditTariffAdded = (creditTariff: CreditTariff) => {
    setCreditTariffs((prevState: PaginatedCreditTariffs) => {
      if (prevState.results.length === pagination.pageSize) {
        prevState.results.pop();
      }
      prevState.results.unshift(creditTariff);

      return { count: prevState.count + 1, results: [ ...prevState.results ] };
    });
    closeAddCreditTariffModal();
  };

  const onCreditTariffUpdated = (updatedCreditTariff: CreditTariff) => {
    const creditTariffIndex = creditTariffs.results.findIndex((creditTariff: CreditTariff) => creditTariff.id === updatedCreditTariff.id);
    setCreditTariffs({
      count: creditTariffs.count,
      results: [
        ...creditTariffs.results.slice(0, creditTariffIndex),
        updatedCreditTariff,
        ...creditTariffs.results.slice(creditTariffIndex + 1, creditTariffs.results.length),
      ],
    });
    closeUpdateCreditTariffModal();
  };

  return (
    <>
      <TableTopPanel
        RightColumn={<Button disabled type="primary" onClick={openAddCreditTariffModal} icon={<PlusOutlined />}>Add tariff plan</Button>}
        Filter={CreditTariffsFilter}
        orgId={org?.id}
      />

      <Table
        rowKey={record => String(record.id)}
        columns={columns}
        dataSource={creditTariffs.results}
        pagination={pagination}
        scroll={{ x: true }}
        loading={isLoading}
      />

      <Modal
        title={<Typography.Title level={4}>Add tariff plan</Typography.Title>}
        open={isAddCreditTariffModalOpen}
        footer={null}
        width={420}
        onCancel={closeAddCreditTariffModal}
      >
        <AddCreditTariffForm orgId={org?.id || myUserOrgId} onCancel={closeAddCreditTariffModal} onCreditTariffAdded={onCreditTariffAdded} />
      </Modal>

      {updatedCreditTariff && (
        <Modal
          title={<Typography.Title level={4}>Update tariff plan</Typography.Title>}
          open={!!updatedCreditTariff}
          footer={null}
          width={420}
          onCancel={closeUpdateCreditTariffModal}
          destroyOnClose
        >
          <UpdateCreditTariffForm creditTariff={updatedCreditTariff} onCancel={closeUpdateCreditTariffModal} onCreditTariffUpdated={onCreditTariffUpdated} />
        </Modal>
      )}
    </>
  );
};

export default CreditTariffs;
