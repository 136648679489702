import { getNamedModelsRequest } from 'api/Exec/api';
import { NamedModel } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useNamedModels = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ namedModels, setNamedModels ] = useState<NamedModel[]>([]);

  const fetchNamedModels = useCallback((hideDeprecated = false, abortController: AbortController) => {
    setIsLoading(true);
    getNamedModelsRequest(hideDeprecated, abortController)
      .then((response) => {
        setNamedModels(response.data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { namedModels, isLoading, fetchNamedModels };
};

export default useNamedModels;
