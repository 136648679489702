import TagsList from 'app/common_components/TagsList/TagsList';
import Dropdown from 'app/common_components/TagsSelect/Dropdown/Dropdown';
import useTagsOptions from 'app/hooks/useTagsOptions';

import { Select, Typography, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { SelectProps } from 'antd/es/select';

export interface Props extends Omit<SelectProps, 'options' | 'children'> {
  disabled: boolean;
  disableTagManagement?: boolean
}

const TagsSelect: React.FC<Props> = ({ disabled, disableTagManagement, ...props }) => {
  const [ isManageTagsModalOpen, setIsManageTagsModalOpen ] = useState<boolean>(false);
  const openManageTagsModal = () => setIsManageTagsModalOpen(true);
  const closeManageTagsModal = () => setIsManageTagsModalOpen(false);

  const { tagOptions } = useTagsOptions();

  const renderDropdown = useCallback((menu: React.ReactElement) => {
    return <Dropdown menu={menu} disableTagManagement={disableTagManagement} onSettingsClick={openManageTagsModal} />;
  }, [ disableTagManagement ]);

  const onSearch = (inputValue: string, option?: { value: string, label: string }) => {
    return option ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 : false;
  };

  return (
    <>
      <Select
        mode="multiple"
        placeholder="Select tags"
        {...props}
        options={tagOptions}
        disabled={disabled}
        dropdownRender={renderDropdown}
        filterOption={onSearch}
      />
      <Modal
        title={<Typography.Title level={4}>Manage tags</Typography.Title>}
        open={isManageTagsModalOpen}
        footer={null}
        width={420}
        onCancel={closeManageTagsModal}
        destroyOnClose
      >
        <TagsList onCancel={closeManageTagsModal} tagOptions={tagOptions} />
      </Modal>
    </>
  );
};

export default TagsSelect;
