import { CurrentQueue } from 'api/Statistics/types';

import { ColumnsType } from 'antd/es/table';

const useCurrentQueueColumns = () => {
  const currentQueueColumns: ColumnsType<CurrentQueue> = [
    {
      title: 'Queue name',
      dataIndex: 'queueName',
      key: 'queueName',
    },
    {
      title: 'Queue type',
      dataIndex: 'queueType',
      key: 'queueType',
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      align: 'center',
    },
  ];

  return { currentQueueColumns };
};

export default useCurrentQueueColumns;
