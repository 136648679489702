import { axiosInstance } from 'api/instance';
import { CommonAuthResponse } from 'api/Auth/types';
import { MyUser, PaginatedUsers, Role, User, UpdateUserParams } from 'api/Users/types';
import urls from 'api/Users/urls';

import { AxiosResponse } from 'axios';

export const getUserRequest = (userId: string): Promise<AxiosResponse<MyUser>> => {
  return axiosInstance.get<MyUser>(urls.userResourceUrl(userId));
};

export const updateUserRequest = (userId: string, data: UpdateUserParams): Promise<AxiosResponse<User>> => {
  return axiosInstance.put<User>(urls.userResourceUrl(userId), data);
};

export const getUsersRequest = (
  page = 1,
  pageSize = 10,
  email = '',
  orgs = '',
  showApiKeys = false,
  abortController?: AbortController,
): Promise<AxiosResponse<PaginatedUsers>> => {
  const orgNames = orgs.split(',');
  return axiosInstance.get<PaginatedUsers>(urls.usersListUrl(
    page,
    pageSize,
    encodeURIComponent(email),
    orgNames,
    showApiKeys,
  ), { signal: abortController?.signal });
};

export const deleteUserRequest = (userId: string): Promise<AxiosResponse> => {
  return axiosInstance.delete(urls.userResourceUrl(userId));
};

export const inviteUserRequest = (email: string, role: Role, orgId?: string): Promise<AxiosResponse<User>> => {
  return axiosInstance.post<User>(urls.usersUrl, { email, role, orgId });
};

export const resendInviteRequest = (userId: string): Promise<AxiosResponse> => {
  return axiosInstance.post(urls.resendInviteUrl(userId));
};

export const activateUserRequest = (code: string, password: string, firstName: string, lastName: string): Promise<AxiosResponse<CommonAuthResponse>> => {
  return axiosInstance.post<CommonAuthResponse>(urls.activateUserUrl, { code, password, firstName, lastName });
};

export const forgotPasswordRequest = (email: string): Promise<AxiosResponse> => {
  return axiosInstance.post(urls.forgotPasswordUrl, { email });
};

export const resetPasswordRequest = (code: string, password: string): Promise<AxiosResponse> => {
  return axiosInstance.post(urls.resetPasswordUrl, { code, password });
};
