import { getAdmixRequest, getAdmixDownloadRequest } from 'api/Dashboard/api';
import { AdmixItem, AdmixResponse, AdmixMeta, AdmixDownloadResponse } from 'api/Dashboard/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { ChartFilter } from 'components/Dashboard/Plots/types';
import { useAppDispatch } from 'store/hooks';
import { addAdmixImage } from 'store/plots/plotsSlice';

import { useCallback, useState } from 'react';
import { message } from 'antd';

const useAdmix = () => {
  const dispatch = useAppDispatch();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isDownloading, setIsDownloading ] = useState<boolean>(false);
  const [ columns, setColumns ] = useState<AdmixItem[]>([]);
  const [ meta, setMeta ] = useState<AdmixMeta>({ groups: [], total_count: 0 });
  const [ image, setImage ] = useState<AdmixDownloadResponse>({ base64: '', filename: '' });

  const fetchAdmix = useCallback((filter: ChartFilter, abortController: AbortController) => {
    setIsLoading(true);
    setImage({ base64: '', filename: '' });

    getAdmixRequest(filter, abortController)
      .then((response) => {
        if (response.status === 202) {
          setIsDownloading(true);
          message.info('You`ll receive a notification when plots are ready');
        } else {
          const data = JSON.parse(response.data) as AdmixResponse;
          setMeta(data.meta);
          setColumns(data.items);
        }
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const downloadAdmix = useCallback((plotRequestId: string, abortController: AbortController) => {
    setIsDownloading(true);

    getAdmixDownloadRequest(plotRequestId, abortController)
      .then(({ data }) => {
        dispatch(addAdmixImage({ ...data, id: plotRequestId }));
        setIsDownloading(false);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsDownloading(false);
        }
      });
  }, [ dispatch ]);

  return { columns, meta, isLoading, fetchAdmix, image, downloadAdmix, isDownloading, setImage };
};

export default useAdmix;
