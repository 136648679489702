import { ColumnItem, OrderStatistics } from 'api/Statistics/types';
import { getOrdersPlotAdmixData, getColor, getLabel } from 'components/Dashboard/Statistics/helpers';

import React from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';

interface Props {
  statistics: OrderStatistics[];
}

const Orders: React.FC<Props> = ({ statistics }) => {
  const data = getOrdersPlotAdmixData(statistics);

  const columnConfig: ColumnConfig = {
    data,
    xField: 'x',
    yField: 'y',
    seriesField: 'series',
    isGroup: true,
    height: 500,
    autoFit: true,
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      flipPage: false,
      itemSpacing: 10,
      animate: false,
    },
    label: {
      position: 'middle',
      formatter: value => getLabel(value as ColumnItem),
      layout: [ { type: 'adjust-color' } ],
    },
    color: ({ series }) => getColor(series),
  };

  return <Column {...columnConfig} />;
};

export default Orders;
