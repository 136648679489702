import { OrdersFilterParams } from 'api/Exec/types';

const rootUrl = 'exec';
const ordersUrl = `${rootUrl}/orders`;
const cancelOrderUrl = `${rootUrl}/cancel`;
const ordersListUrl = (page: number, pageSize: number, filter: OrdersFilterParams) => {
  const { search, orgs, tags, status, model, modelApi, minDate, maxDate, type } = filter;
  return [
    ordersUrl,
    `?page=${page}`,
    `&page_size=${pageSize}`,
    `&filter=${search}`,
    `&org_names=${orgs}`,
    `&tags_ids=${tags}`,
    `&status=${status}`,
    `&model_name=${model}`,
    `&model_api_name=${modelApi}`,
    `&min_date=${minDate}`,
    `&max_date=${maxDate}`,
    `&type=${type}`,
    '&hide_results=true',
  ].join('');
};
const ordersItemUrl = (orderId: string) => `${ordersUrl}/${orderId}`;
const modelsUrl = `${rootUrl}/model`;
const modelsApiUrl = `${modelsUrl}/api`;
const modelsImagesUrl = `${modelsUrl}/image`;
const namedModelsUrl = `${rootUrl}/named_model`;
const namedModelsListUrl = (hideDeprecated = false) => `${namedModelsUrl}?hide_deprecated=${hideDeprecated}`;
const namedModelsItemUrl = (namedModelId: string) => `${namedModelsUrl}/${namedModelId}`;
const tagsUrl = `${rootUrl}/tags`;
const tagsItemUrl = (tagId: string) => `${tagsUrl}/${tagId}`;
const tagsListUrl = (page: number, pageSize: number, name: string) => (
  `${tagsUrl}?page=${page}&page_size=${pageSize}&name=${name}`
);

export default {
  ordersUrl,
  ordersListUrl,
  ordersItemUrl,
  cancelOrderUrl,
  modelsUrl,
  modelsApiUrl,
  modelsImagesUrl,
  namedModelsUrl,
  namedModelsListUrl,
  namedModelsItemUrl,
  tagsUrl,
  tagsListUrl,
  tagsItemUrl,
};
