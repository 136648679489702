import { getOrdersUsageRequest } from 'api/Statistics/api';
import { OrdersUsage } from 'api/Statistics/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useOrdersUsage = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ response, setResponse ] = useState<OrdersUsage['items']>([]);

  const fetchOrdersUsage = useCallback((orgs: string, minDate: string, maxDate: string, plotDateRange: string, abortController: AbortController) => {
    setIsLoading(true);
    getOrdersUsageRequest(orgs, minDate, maxDate, plotDateRange, abortController)
      .then((response) => {
        const data = JSON.parse(response.data) as OrdersUsage;
        setResponse(data.items);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { response, isLoading, fetchOrdersUsage };
};

export default useOrdersUsage;
