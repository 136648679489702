import { SIZE_LABELS } from 'app/consts/files';

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return `0 ${SIZE_LABELS[0]}`;
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1000));
  const value = parseFloat((bytes / 1000 ** i).toFixed(Math.abs(decimals)));

  return `${value} ${SIZE_LABELS[i]}`;
};

export default formatBytes;
