import { AdmixMeta } from 'api/Dashboard/types';
import styles from 'components/Dashboard/Plots/Admix/Groups/Groups.module.css';

import React from 'react';

const Groups: React.FC<AdmixMeta> = ({ total_count, groups }) => {
  const groupsCount = total_count + groups.length - 1;
  const marginRight = (1 / groupsCount) * 100;

  return (
    <div className={styles.wrapper}>
      {groups.map((group) => {
        const width = (group.count / groupsCount) * 100;
        return (
          <span key={group.name} style={{ width: `${width}%`, minWidth: 32, textAlign: 'center', marginRight: `${marginRight}%` }}>
            {group.name}
          </span>
        );
      })}
    </div>
  );
};

export default Groups;
