import { CreditTemplate } from 'api/Credit/types';
import { Org } from 'api/Orgs/types';
import { formatDateTime } from 'app/helpers/dateTime';
import { pluralize } from 'app/helpers/pluralize';
import useOrgsOptions from 'app/hooks/useOrgsOptions';

import { Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { upperFirst } from 'lodash';
import React from 'react';

const useColumns = (setUpdatedCreditTemplate: (creditTemplate: CreditTemplate) => void, org?: Org, hasOrgJustCreated?: boolean) => {
  const { getOrgNameByOrgId } = useOrgsOptions();

  const columns: ColumnsType<CreditTemplate> = [
    {
      title: 'Model',
      dataIndex: 'modelApiName',
    },
    {
      title: 'Type',
      render: (_, { type }) => upperFirst(type.toLowerCase().replaceAll('_', ' ')),
    },
    {
      title: 'Cost',
      render: (_, { price }) => pluralize(Number(price), 'credit'),
    },
    ...(!org ? [ {
      title: 'Organization',
      render: (_, { orgId }) => getOrgNameByOrgId(orgId),
    } ] as ColumnsType<CreditTemplate> : []),
    ...(!hasOrgJustCreated ? [ {
      title: 'Created',
      render: (_, { createdAt }) => <Typography.Text style={{ whiteSpace: 'nowrap' }}>{formatDateTime(createdAt)}</Typography.Text>,
    }, {
      title: 'Updated',
      render: (_, { updatedAt }) => <Typography.Text style={{ whiteSpace: 'nowrap' }}>{formatDateTime(updatedAt)}</Typography.Text>,
    } ] as ColumnsType<CreditTemplate> : []),
    {
      title: 'Actions',
      key: 'actions',
      render: (_, creditTemplate) => (
        <Space size="middle">
          <Typography.Link onClick={() => setUpdatedCreditTemplate(creditTemplate)}>Update</Typography.Link>
        </Space>
      ),
    },
  ];

  return { columns };
};

export default useColumns;
