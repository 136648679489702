import { AdmixDownloadResponse, DonutResponse } from 'api/Dashboard/types';
import { PlotsState } from 'store/plots/types';
import { RootState } from 'store/types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: PlotsState = {
  admixImages: [],
  donutCharts: [],
};

export const plotsSlice = createSlice({
  name: 'plots',
  initialState,
  reducers: {
    addAdmixImage: (state, action: PayloadAction<AdmixDownloadResponse & { id: string }>) => {
      state.admixImages = [ action.payload, ...state.admixImages ];
    },
    addDonutChart: (state, action: PayloadAction<DonutResponse & { id: string }>) => {
      state.donutCharts = [ action.payload, ...state.donutCharts ];
    },
  },
});

export const { addAdmixImage, addDonutChart } = plotsSlice.actions;

export const selectAdmixImage = (state: RootState, id: string) => state.plots.admixImages.find(i => i.id === id);
export const selectDonutChart = (state: RootState, id: string) => state.plots.donutCharts.find(c => c.id === id);

export default plotsSlice.reducer;
