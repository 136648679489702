import { getOrgModelsRequest } from 'api/Orgs/api';
import { OrgModel } from 'api/Orgs/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useOrgModels = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ orgModels, setOrgModels ] = useState<OrgModel[]>([]);

  const fetchOrgModels = useCallback((orgId: string, hideDeprecated = false, abortController: AbortController) => {
    setIsLoading(true);
    getOrgModelsRequest(orgId, hideDeprecated, abortController)
      .then((response) => {
        setOrgModels(response.data);
      })
      .catch((reason) => {
        if (!abortController?.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { orgModels, isLoading, fetchOrgModels };
};

export default useOrgModels;
