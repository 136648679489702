import { deleteFileRequest } from 'api/Data/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { message } from 'antd';

const useDeleteFile = (onDeleted: (fileId: string) => void, finalCallback: () => void) => {
  const [ isDeleting, setIsDeleting ] = useState<boolean>(false);

  const deleteFile = (fileId: string) => {
    setIsDeleting(true);
    deleteFileRequest(fileId)
      .then(() => {
        onDeleted(fileId);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsDeleting(false);
        finalCallback();
      });
  };

  return { isDeleting, deleteFile };
};

export default useDeleteFile;
