import { SourceFilesStatistics } from 'api/Statistics/types';
import { goldenPurple6 } from 'app/consts/colors';
import formatBytes from 'app/helpers/formatBytes';

import React from 'react';
import { Card, Statistic, Row, Col } from 'antd';

interface Props {
  statistics: SourceFilesStatistics;
}

const SourceFiles: React.FC<Props> = ({ statistics }) => {
  return (
    <>
      <Row gutter={[ 16, 16 ]} style={{ marginBottom: 16 }}>
        <Col lg={4} xl={3} xxl={2}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="Total"
              value={statistics.amount}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>

        <Col lg={4} xl={3} xxl={2}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="Valid"
              value={statistics.valid}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>

        <Col lg={5} xl={4} xxl={3}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="Not valid"
              value={statistics.notValid}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>

        <Col xl={5} xxl={4}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="Source files size"
              precision={2}
              value={formatBytes(statistics.fileSizeBytes)}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[ 16, 16 ]} style={{ marginBottom: 16 }}>
        <Col lg={4} xl={3} xxl={2}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="GNT"
              value={statistics.gnt}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>

        <Col lg={4} xl={3} xxl={2}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="WGS"
              value={statistics.wgs}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>

        <Col lg={5} xl={4} xxl={3}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="Sftp upload"
              value={statistics.sftpUpload}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>

        <Col lg={5} xl={4} xxl={3}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="Web upload"
              value={statistics.webUpload}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[ 16, 16 ]} style={{ marginBottom: 24 }}>
        <Col lg={5} xl={4} xxl={3}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="Check in process"
              value={statistics.checkInProcess}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>

        <Col lg={5} xl={4} xxl={3}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="Single sample"
              value={statistics.singleSample}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>

        <Col lg={5} xl={4} xxl={3}>
          <Card bordered style={{ textAlign: 'center' }}>
            <Statistic
              title="Multi sample"
              value={statistics.multiSample}
              valueStyle={{ color: goldenPurple6 }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SourceFiles;
