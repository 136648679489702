import PasswordInputs from 'app/common_components/PasswordInputs/PasswordInputs';

import { Typography, Form, Button, Alert, Space } from 'antd';
import React from 'react';

export interface FormValues {
  password: string;
  confirmPassword: string;
}

interface Props {
  onSubmit: (values: FormValues) => void;
  error: string;
  passwordHasBeenReset: boolean;
  goToSignIn: () => void;
}

const ResetPasswordForm: React.FC<Props> = ({ onSubmit, error, passwordHasBeenReset, goToSignIn }) => {
  const [ form ] = Form.useForm<FormValues>();

  const successMessage = (
    <Space size="small">
      Your password has been reset. Go to
      <Typography.Link onClick={goToSignIn}>Sign in</Typography.Link>
    </Space>
  );

  return (
    <>
      <Typography.Title level={3} style={{ marginBottom: 0 }}>Enter new password</Typography.Title>

      <Form
        form={form}
        name="resetPassword"
        style={{ marginTop: 24 }}
        layout="vertical"
        onFinish={onSubmit}
        autoComplete="off"
        requiredMark={false}
      >
        <PasswordInputs passwordLabel="New password" />

        {error && (
          <Form.Item>
            <Alert message={error} type="error" showIcon banner />
          </Form.Item>
        )}

        {passwordHasBeenReset && (
          <Form.Item>
            <Alert message={successMessage} type="success" showIcon banner />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            Done
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ResetPasswordForm;
