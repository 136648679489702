import { ModelApiName } from 'api/Exec/types';
import { axiosInstance } from 'api/instance';
import {
  AdmixDownloadResponse,
  DonutResponse,
  PaginatedSetLabels,
  PaginatedSuperSetLabels,
  TableFilterParams,
  PaginatedPlotRequests,
} from 'api/Dashboard/types';
import urls from 'api/Dashboard/urls';
import { ChartFilter } from 'components/Dashboard/Plots/types';

import { AxiosResponse } from 'axios';

export const getAdmixRequest = (filter: ChartFilter, { signal }: AbortController): Promise<AxiosResponse<string>> => {
  return axiosInstance.get<string>(urls.admixUrl(filter), { signal });
};

export const getAdmixDownloadRequest = (plotRequestId: string, { signal }: AbortController): Promise<AxiosResponse<AdmixDownloadResponse>> => {
  return axiosInstance.get(urls.downloadAdmixUrl(plotRequestId), { signal });
};

export const getDonutRequest = (filter: ChartFilter, { signal }: AbortController): Promise<AxiosResponse<DonutResponse>> => {
  return axiosInstance.get<DonutResponse>(urls.donutUrl(filter), { signal });
};

export const getPlotRequestsRequest = (
  page = 1,
  pageSize = 10,
  modelApiName: ModelApiName,
  abortController?: AbortController,
): Promise<AxiosResponse<PaginatedPlotRequests>> => {
  return axiosInstance.get<PaginatedPlotRequests>(urls.plotRequestsUrl(page, pageSize, modelApiName), { signal: abortController?.signal });
};

export const getSetRequest = (
  page = 1,
  pageSize = 10,
  tableFilter: TableFilterParams,
  filter: ChartFilter,
  abortController?: AbortController,
): Promise<AxiosResponse<PaginatedSetLabels>> => {
  return axiosInstance.get<PaginatedSetLabels>(urls.setUrl(page, pageSize, tableFilter, filter), { signal: abortController?.signal });
};

export const getSetExportRequest = (tableFilter: TableFilterParams, filter: ChartFilter): Promise<AxiosResponse> => {
  return axiosInstance.get(urls.setExportUrl(tableFilter, filter), { responseType: 'blob' });
};

export const getSuperSetRequest = (
  page = 1,
  pageSize = 10,
  tableFilter: TableFilterParams,
  filter: ChartFilter,
  abortController?: AbortController,
): Promise<AxiosResponse<PaginatedSuperSetLabels>> => {
  return axiosInstance.get<PaginatedSuperSetLabels>(urls.superSetUrl(page, pageSize, tableFilter, filter), { signal: abortController?.signal });
};

export const getSuperSetExportRequest = (tableFilter: TableFilterParams, filter: ChartFilter): Promise<AxiosResponse> => {
  return axiosInstance.get(urls.superSetExportUrl(tableFilter, filter), { responseType: 'blob' });
};
