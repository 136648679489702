import { getModelsRequest } from 'api/Exec/api';
import { Model } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useModels = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ models, setModels ] = useState<Model[]>([]);

  const fetchModels = useCallback((abortController: AbortController) => {
    setIsLoading(true);
    getModelsRequest(abortController)
      .then((response) => {
        setModels(response.data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { models, isLoading, fetchModels, setModels };
};

export default useModels;
