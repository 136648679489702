import { getNotificationsRequest, watchNotificationRequest } from 'api/Notifications/api';
import { Notification } from 'api/Notifications/types';
import { MarkAsReadPayload, NotificationsState } from 'store/notifications/types';
import { RootState } from 'store/types';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: NotificationsState = {
  notifications: [],
  status: 'idle',
};

export const getNotificationsAsync = createAsyncThunk(
  'notifications/getNotifications',
  async (abortController?: AbortController) => {
    const response = await getNotificationsRequest(1, 10, undefined, abortController);
    return response.data.results;
  },
);

export const markAsReadAsync = createAsyncThunk(
  'notifications/markAsRead',
  async ({ notificationId, watchAll }: MarkAsReadPayload) => {
    await watchNotificationRequest(notificationId, watchAll);
    return { notificationId, watchAll };
  },
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNotificationsAsync.fulfilled, (state, action: PayloadAction<Notification[]>) => {
        state.status = 'idle';
        state.notifications = action.payload;
      })
      .addCase(getNotificationsAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(markAsReadAsync.fulfilled, (state, action: PayloadAction<MarkAsReadPayload>) => {
        if (action.payload.watchAll) {
          state.notifications = state.notifications.map(n => ({ ...n, watched: true }));
        } else if (action.payload.notificationId) {
          const notificationIndex = state.notifications.findIndex(n => n.id === action.payload.notificationId);
          state.notifications = [
            ...state.notifications.slice(0, notificationIndex),
            { ...state.notifications[notificationIndex], watched: true },
            ...state.notifications.slice(notificationIndex + 1, state.notifications.length),
          ];
        }
      });
  },
});

export const selectNotifications = (state: RootState) => state.notifications.notifications;

export default notificationsSlice.reducer;
