import { NamedModel } from 'api/Exec/types';

import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';

const useColumns = (editNamedModel: (namedModel: NamedModel) => void) => {
  const columns: ColumnsType<NamedModel> = [
    {
      title: 'Name',
      dataIndex: 'modelName',
      key: 'modelName',
    },
    {
      title: 'API name',
      dataIndex: 'modelApiName',
      key: 'modelApiName',
    },
    {
      title: 'Image tag',
      dataIndex: 'modelImageTag',
      key: 'modelImageTag',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, namedModel) => {
        return (
          <Typography.Link ellipsis onClick={() => editNamedModel(namedModel)}>Edit</Typography.Link>
        );
      },
    },
  ];

  return { columns };
};

export default useColumns;
