import EnterCodeForm from 'components/Auth/SignIn/EnterCodeForm/EnterCodeForm';
import LogInForm from 'components/Auth/SignIn/LogInForm/LogInForm';
import { useAppSelector } from 'store/hooks';
import { selectMFASessionId } from 'store/auth/authSlice';

import React from 'react';

const SignIn: React.FC = () => {
  const mfaSessionId = useAppSelector(selectMFASessionId);

  return mfaSessionId ? <EnterCodeForm mfaSessionId={mfaSessionId} /> : <LogInForm />;
};

export default SignIn;
