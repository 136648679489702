import { File } from 'api/Data/types';
import { MAX_FILE_SIZE_TO_UPLOAD } from 'app/consts/files';
import { useFileUpload } from 'components/Dashboard/Files/AddFileForm/hooks';

import { Button, Form, Select, Space, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

interface FormValues {
  fileType: string;
  file: UploadFile[];
}

interface Props {
  onCancel: () => void;
  onFileUploaded: (file: File) => void;
}

const AddFileForm: React.FC<Props> = ({ onCancel, onFileUploaded }) => {
  const [ form ] = Form.useForm<FormValues>();
  const [ fileList, setFileList ] = useState<UploadFile[]>([]);
  const [ isFileSizeAcceptable, setIsFileSizeAcceptable ] = useState<boolean>(false);

  const { isUploading, uploadFile } = useFileUpload(onFileUploaded);

  const props: UploadProps = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setIsFileSizeAcceptable(file.size <= MAX_FILE_SIZE_TO_UPLOAD);

      return false;
    },
    maxCount: 1,
    fileList,
  };

  const onFinish = (values: FormValues) => {
    if (values.file[0].originFileObj) {
      uploadFile(values.file[0].originFileObj);
    }
  };

  const normalizeFile = (e: { file: RcFile, fileList: UploadFile[] }) => e.fileList;

  return (
    <Form
      form={form}
      name="addFileForm"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ marginTop: 24 }}
      initialValues={{ fileType: 'genotyping' }}
    >
      <Form.Item
        name="fileType"
        label="File type"
        tooltip="Max file size is 50 Mbs"
        rules={[ { required: true, message: 'Field is mandatory' } ]}
      >
        <Select placeholder="Select file type" disabled>
          <Select.Option value="genotyping">Genotyping</Select.Option>
          <Select.Option value="wgs">Whole genome sequence</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="file"
        valuePropName="fileList"
        getValueFromEvent={normalizeFile}
        rules={[ { required: true, message: 'File is mandatory' },
          () => ({
            validator(_, value) {
              if (value && value.length && !isFileSizeAcceptable) {
                return Promise.reject(new Error('Max file size is 50 Mbs'));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Upload {...props} disabled={isUploading} data-testid="upload-source-file">
          <Button icon={<UploadOutlined />} disabled={isUploading}>Select file</Button>
        </Upload>
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isUploading}>
            Submit
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AddFileForm;
