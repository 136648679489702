import { TableFilterParams } from 'api/Dashboard/types';
import { ModelApiName } from 'api/Exec/types';
import { ChartFilter } from 'components/Dashboard/Plots/types';

const rootUrl = 'dashboard';
const admixUrl = (filter: ChartFilter) => {
  const {
    sortBy,
    groupBy,
    allRecords,
    tagsIds,
    ancestralComponent,
    threshold,
    modelName,
    modelApiName,
    minDate,
    maxDate,
  } = filter;
  const url = [
    `${rootUrl}/admix`,
    `?sort_by=${sortBy}`,
    `&group_by=${groupBy}`,
    `&tags_ids=${tagsIds}`,
    `&ancestral_component=${ancestralComponent}`,
    `&threshold=${threshold}`,
    `&model_name=${modelName}`,
    `&model_api_name=${modelApiName}`,
    `&min_date=${minDate}`,
    `&max_date=${maxDate}`,
    `&all_records=${allRecords}`,
  ];
  return url.join('');
};
const downloadAdmixUrl = (plotRequestId: string) => `${rootUrl}/admix?plot_request_id=${plotRequestId}`;
const donutUrl = (filter: ChartFilter) => {
  const {
    allRecords,
    tagsIds,
    ancestralComponent,
    threshold,
    modelName,
    modelApiName,
    minDate,
    maxDate,
    plotRequestId,
  } = filter;
  return [
    `${rootUrl}/donut`,
    `?tags_ids=${tagsIds}`,
    `&ancestral_component=${ancestralComponent}`,
    `&threshold=${threshold}`,
    `&model_name=${modelName}`,
    `&model_api_name=${modelApiName}`,
    `&min_date=${minDate}`,
    `&max_date=${maxDate}`,
    `&all_records=${allRecords}`,
    `&plot_request_id=${plotRequestId}`,
  ].join('');
};
const heatMapUrl = `${rootUrl}/heatmap`;
const plotRequestsUrl = (page: number, pageSize: number, modelApiName: ModelApiName) => (
  `${rootUrl}/plot_request?page=${page}&page_size=${pageSize}&model_api_name=${modelApiName}`
);

const setUrl = (page: number, pageSize: number, tableFilter: TableFilterParams, filter: ChartFilter) => {
  const { sampleId, orderId } = tableFilter;
  const {
    sortBy,
    groupBy,
    allRecords,
    tagsIds,
    ancestralComponent,
    threshold,
    modelName,
    modelApiName,
    minDate,
    maxDate,
  } = filter;
  return [
    `${rootUrl}/table_set`,
    `?page=${page}`,
    `&page_size=${pageSize}`,
    `&sample_id=${sampleId}`,
    `&order_id=${orderId}`,
    `&sort_by=${sortBy}`,
    `&group_by=${groupBy}`,
    `&tags_ids=${tagsIds}`,
    `&ancestral_component=${ancestralComponent}`,
    `&threshold=${threshold}`,
    `&model_name=${modelName}`,
    `&model_api_name=${modelApiName}`,
    `&min_date=${minDate}`,
    `&max_date=${maxDate}`,
    `&all_records=${allRecords}`,
  ].join('');
};
const setExportUrl = (tableFilter: TableFilterParams, filter: ChartFilter) => {
  const { sampleId, orderId } = tableFilter;
  const {
    sortBy,
    groupBy,
    allRecords,
    tagsIds,
    ancestralComponent,
    threshold,
    modelName,
    modelApiName,
    minDate,
    maxDate,
  } = filter;
  return [
    `${rootUrl}/table_set/export`,
    `?sample_id=${sampleId}`,
    `&order_id=${orderId}`,
    `&sort_by=${sortBy}`,
    `&group_by=${groupBy}`,
    `&tags_ids=${tagsIds}`,
    `&ancestral_component=${ancestralComponent}`,
    `&threshold=${threshold}`,
    `&model_name=${modelName}`,
    `&model_api_name=${modelApiName}`,
    `&min_date=${minDate}`,
    `&max_date=${maxDate}`,
    `&all_records=${allRecords}`,
  ].join('');
};

const superSetUrl = (page: number, pageSize: number, tableFilter: TableFilterParams, filter: ChartFilter) => {
  const { sampleId, orderId } = tableFilter;
  const {
    sortBy,
    groupBy,
    allRecords,
    tagsIds,
    ancestralComponent,
    threshold,
    modelName,
    modelApiName,
    minDate,
    maxDate,
  } = filter;
  return [
    `${rootUrl}/table_superset`,
    `?page=${page}`,
    `&page_size=${pageSize}`,
    `&sample_id=${sampleId}`,
    `&order_id=${orderId}`,
    `&sort_by=${sortBy}`,
    `&group_by=${groupBy}`,
    `&tags_ids=${tagsIds}`,
    `&ancestral_component=${ancestralComponent}`,
    `&threshold=${threshold}`,
    `&model_name=${modelName}`,
    `&model_api_name=${modelApiName}`,
    `&min_date=${minDate}`,
    `&max_date=${maxDate}`,
    `&all_records=${allRecords}`,
  ].join('');
};
const superSetExportUrl = (tableFilter: TableFilterParams, filter: ChartFilter) => {
  const { sampleId, orderId } = tableFilter;
  const {
    sortBy,
    groupBy,
    allRecords,
    tagsIds,
    ancestralComponent,
    threshold,
    modelName,
    modelApiName,
    minDate,
    maxDate,
  } = filter;
  return [
    `${rootUrl}/table_superset/export`,
    `?sample_id=${sampleId}`,
    `&order_id=${orderId}`,
    `&sort_by=${sortBy}`,
    `&group_by=${groupBy}`,
    `&tags_ids=${tagsIds}`,
    `&ancestral_component=${ancestralComponent}`,
    `&threshold=${threshold}`,
    `&model_name=${modelName}`,
    `&model_api_name=${modelApiName}`,
    `&min_date=${minDate}`,
    `&max_date=${maxDate}`,
    `&all_records=${allRecords}`,
  ].join('');
};

export default { admixUrl, downloadAdmixUrl, donutUrl, heatMapUrl, plotRequestsUrl, setUrl, setExportUrl, superSetUrl, superSetExportUrl };
