import usePaginationParams from 'app/hooks/usePaginationParams';
import CreditExchanges from 'components/Dashboard/Billing/CreditExchanges/CreditExchanges';
import CreditLedger from 'components/Dashboard/Billing/CreditLedger/CreditLedger';
import CreditServices from 'components/Dashboard/Billing/CreditServices/CreditServices';
import CreditTariffs from 'components/Dashboard/Billing/CreditTariffs/CreditTariffs';
import CreditTemplates from 'components/Dashboard/Billing/CreditTemplates/CreditTemplates';
import { DashboardPageProps } from 'components/Dashboard/types';

import { Tabs, TabsProps } from 'antd';
import React, { useEffect } from 'react';

const Billing: React.FC<DashboardPageProps> = ({ setTitle }) => {
  const { clearSearchParams } = usePaginationParams();

  useEffect(() => {
    setTitle('Billing');
  }, [ setTitle ]);

  const tabs: TabsProps['items'] = [
    {
      key: 'ledger',
      label: 'Ledger',
      children: <CreditLedger />,
    },
    {
      key: 'services',
      label: 'Services',
      children: <CreditServices />,
    },
    {
      key: 'tariffs',
      label: 'Tariffs',
      children: <CreditTariffs />,
    },
    {
      key: 'templates',
      label: 'Templates',
      children: <CreditTemplates />,
    },
    {
      key: 'exchanges',
      label: 'Exchanges',
      children: <CreditExchanges />,
    },
  ];

  return <Tabs defaultActiveKey="ledger" items={tabs} onChange={clearSearchParams} destroyInactiveTabPane />;
};

export default Billing;
