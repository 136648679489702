import { CreditService, CreditTariff } from 'api/Credit/types';
import { SelectOption } from 'app/types/Forms';
import { useCreditServices } from 'components/Dashboard/Billing/CreditServices/hooks';
import useAddCreditTariff from 'components/Dashboard/Billing/CreditTariffs/AddCreditTariffForm/useAddCreditTariff';

import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Select, Space } from 'antd';

interface FormValues {
  creditServiceId: string;
  price: number;
}

interface Props {
  orgId: string;
  onCancel: () => void;
  onCreditTariffAdded: (creditTariff: CreditTariff) => void;
}

const AddCreditTariffForm: React.FC<Props> = ({ orgId, onCancel, onCreditTariffAdded }) => {
  const [ form ] = Form.useForm<FormValues>();
  const { isLoading, creditServices, fetchCreditServices } = useCreditServices();
  const { isCreating, addCreditTariff } = useAddCreditTariff();
  const [ creditServicesOptions, setCreditServicesOptions ] = useState<SelectOption[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchCreditServices(1, 100, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchCreditServices ]);

  useEffect(() => {
    const options: SelectOption[] = creditServices.results.map((creditService: CreditService) => ({
      label: creditService.name,
      value: creditService.id,
    }));
    setCreditServicesOptions(options);
  }, [ creditServices ]);

  const onFinish = async (values: FormValues) => {
    const creditTariff = await addCreditTariff(orgId, values.creditServiceId, values.price);
    if (creditTariff) {
      onCreditTariffAdded(creditTariff);
      form.resetFields();
    }
  };

  return (
    <Form
      form={form}
      name="addCreditTariff"
      style={{ width: 360 }}
      layout="vertical"
      onFinish={onFinish}
      disabled={isCreating}
    >
      <Form.Item name="creditServiceId" label="Service" rules={[ { required: true, message: 'Field is mandatory' } ]}>
        <Select placeholder="Select service" options={creditServicesOptions} loading={isLoading} />
      </Form.Item>

      <Form.Item name="price" label="Price" rules={[ { required: true, message: 'Field is mandatory' } ]}>
        <InputNumber placeholder="Enter price" style={{ width: '100%' }} precision={0} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Add tariff plan
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AddCreditTariffForm;
