import { updateNamedModelRequest } from 'api/Exec/api';
import { NamedModel } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useState } from 'react';

const useEditNamedModel = (onNamedModelUpdated: (namedModel: NamedModel) => void) => {
  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);

  const editNamedModel = (namedModelId: string, modelId: string, modelName: string) => {
    setIsUpdating(true);

    updateNamedModelRequest(namedModelId, modelId, modelName)
      .then(({ data }) => {
        message.success('Named model was updated');
        onNamedModelUpdated(data);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return { isUpdating, editNamedModel };
};

export default useEditNamedModel;
