import { Statistics } from 'api/Statistics/types';
import ResultFiles from 'components/Dashboard/Statistics/Files/ResultFiles/ResultFiles';
import SourceFiles from 'components/Dashboard/Statistics/Files/SourceFiles/SourceFiles';

import React from 'react';
import { Typography } from 'antd';

interface Props {
  statistics: Statistics;
}

const Files: React.FC<Props> = ({ statistics }) => {
  return (
    <>
      <Typography.Title level={4}>Source files</Typography.Title>

      <SourceFiles statistics={statistics.sourceFilesStatistics} />

      <Typography.Title level={4}>Result files</Typography.Title>

      <ResultFiles statistics={statistics.resultFilesStatistics} />
    </>
  );
};

export default Files;
