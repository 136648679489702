import { getUsersRequest } from 'api/Users/api';
import { PaginatedUsers } from 'api/Users/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useUsers = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ response, setResponse ] = useState<PaginatedUsers>({ count: 0, results: [] });

  const fetchUsers = useCallback((
    pageNumber: number,
    pageSize: number,
    email: string,
    orgs: string,
    showApiKeys: boolean,
    abortController?: AbortController,
  ) => {
    setIsLoading(true);
    getUsersRequest(pageNumber, pageSize, email, orgs, showApiKeys, abortController)
      .then((response) => {
        setResponse(response.data);
      })
      .catch((reason) => {
        if (!abortController?.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { response, isLoading, fetchUsers, setResponse };
};

export default useUsers;
