import { getCreditBalancesRequest } from 'api/Credit/api';
import { CreditBalance } from 'api/Credit/types';
import { CreditsState, CreditBalancePayload } from 'store/credits/types';
import { RootState } from 'store/types';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: CreditsState = {
  creditBalance: {
    id: '',
    orgId: '',
    balance: 0,
    createdAt: '',
    updatedAt: '',
  },
  status: 'loading',
};

export const getCreditBalanceAsync = createAsyncThunk(
  'orgs/getCreditBalance',
  async ({ orgId, abortController }: CreditBalancePayload) => {
    const response = await getCreditBalancesRequest(1, 10, orgId, abortController);
    return response.data.results;
  },
);

export const creditsSlice = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    updateBalance: (state, action: PayloadAction<CreditBalance>) => {
      state.creditBalance = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCreditBalanceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCreditBalanceAsync.fulfilled, (state, action: PayloadAction<CreditBalance[]>) => {
        state.status = 'idle';
        state.creditBalance = action.payload[0];
      })
      .addCase(getCreditBalanceAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectCreditBalance = (state: RootState) => state.credits.creditBalance;

export const { updateBalance } = creditsSlice.actions;

export default creditsSlice.reducer;
