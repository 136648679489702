import { createOrderRequest } from 'api/Exec/api';
import { Order } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { message } from 'antd';

const useCreateOrder = (onOrderCreated: (order: Order) => void) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const createOrder = (fileId: string, modelName: string, tagIds: string[]) => {
    setIsLoading(true);

    createOrderRequest(fileId, modelName, tagIds)
      .then(({ data }) => {
        onOrderCreated(data[0]);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { isLoading, createOrder };
};

export default useCreateOrder;
