import { requestPdfRequest, getSamplesRequest } from 'api/Data/api';
import { Sample } from 'api/Data/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';
import { message } from 'antd';

const useRequestPdf = (onPdfRequested: (orderId: string) => void, finalCallback: () => void) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ samples, setSamples ] = useState<Sample[]>([]);

  const requestPdf = (orderId: string, sampleId: string) => {
    setIsLoading(true);
    requestPdfRequest(orderId, sampleId)
      .then(({ data }) => {
        onPdfRequested(orderId);
        message.success(data.detail);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        finalCallback();
        setIsLoading(false);
      });
  };

  const getSamples = useCallback((orderId: string, abortController: AbortController) => {
    setIsLoading(true);
    getSamplesRequest(orderId, abortController)
      .then(({ data }) => {
        setSamples(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { isLoading, requestPdf, getSamples, samples };
};

export default useRequestPdf;
