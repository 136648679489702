import { SelectOption } from 'app/types/Forms';
import { Tag } from 'api/Exec/types';
import { selectTags } from 'store/exec/execSlice';
import { useAppSelector } from 'store/hooks';

import { useMemo } from 'react';

const useTagsOptions = () => {
  const tags = useAppSelector(selectTags);

  const tagOptions = useMemo(() => tags.map((tag: Tag): SelectOption => ({
    value: tag.id,
    label: tag.name,
  })), [ tags ]);

  const getTagNameById = (tagId: string): string | undefined => tags.find((tag: Tag) => tag.id === tagId)?.name;

  return { tagOptions, getTagNameById };
};

export default useTagsOptions;
