import { CreditTransactionStatus } from 'api/Credit/types';

export const getStatusColor = (status: CreditTransactionStatus) => {
  switch (status) {
    case 'COMPLETED':
      return 'purple';
    case 'RESERVED':
      return 'default';
    default:
      return 'red';
  }
};
