import useOrgsOptions from 'app/hooks/useOrgsOptions';
import usePaginationParams from 'app/hooks/usePaginationParams';
import { FilterParams } from 'app/types/Filters';
import { FilterProps } from 'components/Dashboard/types';

import { Button, Form, Select, Space } from 'antd';
import React from 'react';

const UsersFilter: React.FC<FilterProps> = ({ onApply }) => {
  const [ form ] = Form.useForm<FilterParams>();
  const { orgParam, setFilters, clearFilters } = usePaginationParams();
  const { orgsOptions } = useOrgsOptions();

  const onReset = () => {
    form.setFieldValue('org', []);
    clearFilters();
  };

  const onFinish = (values: FilterParams) => {
    setFilters(values);
    onApply();
  };

  return (
    <Form
      form={form}
      name="usersFilter"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ width: 290 }}
      initialValues={{
        org: orgParam !== '' ? orgParam.split(',') : [],
      }}
    >
      <Form.Item name="org" label="Organizations">
        <Select
          placeholder="Select organizations"
          mode="multiple"
          options={orgsOptions}
        />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UsersFilter;
