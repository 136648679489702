import { textColorSecondary } from 'app/consts/colors';
import { resetPasswordUrl } from 'app/consts/urls';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authenticateAsync, selectAuthError, selectAuthStatus, resetError } from 'store/auth/authSlice';

import { Alert, Typography, Form, Input, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  email: string;
  password: string;
}

const LogInForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ form ] = Form.useForm<FormValues>();
  const [ timeLeft, setTimeLeft ] = useState(0); // seconds

  const error = useAppSelector(selectAuthError);
  const status = useAppSelector(selectAuthStatus);
  const isLoading = status === 'loading';

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    if (timeLeft <= 0) {
      clearInterval(timer);
      dispatch(resetError());
    }

    return () => {
      clearInterval(timer);
    };
  }, [ timeLeft, dispatch ]);

  useEffect(() => {
    if (error && error.startsWith('Too many login attempts')) {
      const value = error.match(/\d+/g);
      if (value) {
        setTimeLeft(Number(value.join('')));
      }
    }
  }, [ error ]);

  const goToResetPassword = () => navigate(`/${resetPasswordUrl}`);

  const onFinish = (values: FormValues) => {
    dispatch(authenticateAsync(values));
  };

  return (
    <>
      <Typography.Title level={3} style={{ marginBottom: 0 }}>Welcome back!</Typography.Title>
      <Typography.Text style={{ color: textColorSecondary }}>Login to continue</Typography.Text>
  
      <Form
        name="auth"
        form={form}
        style={{ marginTop: 24 }}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
        disabled={isLoading}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[ { required: true, message: 'Please enter your email' },
            { type: 'email', message: 'Please enter valid email' } ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
  
        <Form.Item
          label="Password"
          name="password"
          rules={[ { required: true, message: 'Please enter your password' } ]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>

        {error && timeLeft <= 0 && (
          <Form.Item>
            <Alert message={error} type="error" showIcon banner />
          </Form.Item>
        )}

        {timeLeft > 0 && (
          <Form.Item>
            <Alert message={`Too many attempts. Please try after ${timeLeft} seconds`} type="error" showIcon banner />
          </Form.Item>
        )}
  
        <Form.Item>
          <Typography.Link onClick={goToResetPassword}>
            Forgot password
          </Typography.Link>
        </Form.Item>
  
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={isLoading}>
            Sign in
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LogInForm;
