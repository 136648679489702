import { Model } from 'api/Exec/types';

import { Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';

const useColumns = () => {
  const columns: ColumnsType<Model> = [
    {
      title: 'Assigned model name',
      dataIndex: 'assignedName',
      key: 'name',
    },
    {
      title: 'Image tag',
      dataIndex: 'modelImageTag',
      key: 'modelImageTag',
    },
    {
      title: 'Git commit',
      dataIndex: 'modelGitCommit',
      key: 'modelGitCommit',
      render: (_, { modelGitCommit }) => {
        return <Typography.Link href={modelGitCommit} target="_blank">{modelGitCommit}</Typography.Link>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { enabled, id }) => {
        return (
          <Tag color={enabled ? 'purple' : 'error'} key={id}>
            {enabled ? 'Enabled' : 'Disabled'}
          </Tag>
        );
      },
    },
  ];

  return { columns };
};

export default useColumns;
