import { CreditExchange, PaginatedCreditExchanges } from 'api/Credit/types';
import { Org } from 'api/Orgs/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import { useColumns, useCreditExchanges } from 'components/Dashboard/Billing/CreditExchanges/hooks';
import AddCreditExchangeForm from 'components/Dashboard/Billing/CreditExchanges/AddCreditExchangeForm/AddCreditExchangeForm';
import UpdateCreditExchangeForm from 'components/Dashboard/Billing/CreditExchanges/UpdateCreditExchangeForm/UpdateCreditExchangeForm';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Typography } from 'antd';

interface Props {
  org?: Org;
}

const CreditExchanges: React.FC<Props> = ({ org }) => {
  const { org: { id: myUserOrgId } } = useAppSelector(selectMyUser);
  const { isLoading, creditExchanges, fetchCreditExchanges, setCreditExchanges } = useCreditExchanges();
  const { pageParam, pageSizeParam } = usePaginationParams();

  const onCreditExchangeDeleted = (creditExchangeId: string) => {
    const creditTariffIndex = creditExchanges.results.findIndex((creditExchange: CreditExchange) => creditExchange.id === creditExchangeId);
    setCreditExchanges({
      count: creditExchanges.count,
      results: [
        ...creditExchanges.results.slice(0, creditTariffIndex),
        ...creditExchanges.results.slice(creditTariffIndex + 1, creditExchanges.results.length),
      ],
    });
  };

  useEffect(() => {
    const orgId = org?.id || '';
    const abortController = new AbortController();
    fetchCreditExchanges(pageParam, pageSizeParam, orgId, abortController);

    return () => {
      abortController.abort();
    };
  }, [ pageParam, pageSizeParam, fetchCreditExchanges, org ]);

  const [ updatedCreditExchange, setUpdatedCreditExchange ] = useState<CreditExchange | null>(null);
  const openUpdateCreditExchangeModal = (creditExchange: CreditExchange) => setUpdatedCreditExchange(creditExchange);
  const closeUpdateCreditExchangeModal = () => setUpdatedCreditExchange(null);

  const { columns } = useColumns(onCreditExchangeDeleted, openUpdateCreditExchangeModal, org);
  const { tablePagination } = useTablePagination();
  const pagination = tablePagination(creditExchanges.results.length);

  const [ isAddCreditExchangeModalOpen, setIsAddCreditExchangeModalOpen ] = useState<boolean>(false);
  const openAddCreditExchangeModal = () => setIsAddCreditExchangeModalOpen(true);
  const closeAddCreditExchangeModal = () => setIsAddCreditExchangeModalOpen(false);

  const onCreditExchangeAdded = (creditExchange: CreditExchange) => {
    setCreditExchanges((prevState: PaginatedCreditExchanges) => {
      if (prevState.results.length === pagination.pageSize) {
        prevState.results.pop();
      }
      prevState.results.unshift(creditExchange);

      return { count: prevState.count + 1, results: [ ...prevState.results ] };
    });
    closeAddCreditExchangeModal();
  };

  const onCreditTariffUpdated = (updatedCreditExchange: CreditExchange) => {
    const creditTariffIndex = creditExchanges.results.findIndex((creditExchange: CreditExchange) => creditExchange.id === updatedCreditExchange.id);
    setCreditExchanges({
      count: creditExchanges.count,
      results: [
        ...creditExchanges.results.slice(0, creditTariffIndex),
        updatedCreditExchange,
        ...creditExchanges.results.slice(creditTariffIndex + 1, creditExchanges.results.length),
      ],
    });
    closeUpdateCreditExchangeModal();
  };

  return (
    <>
      <TableTopPanel RightColumn={<Button type="primary" onClick={openAddCreditExchangeModal} icon={<PlusOutlined />}>Add exchange</Button>} />

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={creditExchanges.results}
        pagination={pagination}
        scroll={{ x: true }}
        loading={isLoading}
      />

      <Modal
        title={<Typography.Title level={4}>Add exchange</Typography.Title>}
        open={isAddCreditExchangeModalOpen}
        footer={null}
        width={420}
        onCancel={closeAddCreditExchangeModal}
        destroyOnClose
      >
        <AddCreditExchangeForm
          orgId={org?.id || myUserOrgId}
          onCancel={closeAddCreditExchangeModal}
          onCreditExchangeAdded={onCreditExchangeAdded}
        />
      </Modal>

      {updatedCreditExchange && (
        <Modal
          title={<Typography.Title level={4}>Update exchange</Typography.Title>}
          open={!!updatedCreditExchange}
          footer={null}
          width={420}
          onCancel={closeUpdateCreditExchangeModal}
          destroyOnClose
        >
          <UpdateCreditExchangeForm
            creditExchange={updatedCreditExchange}
            onCancel={closeUpdateCreditExchangeModal}
            onCreditExchangeUpdated={onCreditTariffUpdated}
          />
        </Modal>
      )}
    </>
  );
};

export default CreditExchanges;
