import { Org, SftpUser, SSHKey } from 'api/Orgs/types';
import useTablePagination from 'app/hooks/useTablePagination';
import { useSftpUsers, useSftpUsersColumns } from 'components/Dashboard/Orgs/Wizard/SftpUsers/hooks';
import AddSshKeyForm from 'components/Dashboard/Orgs/Wizard/SftpUsers/AddSshKeyForm/AddSshKeyForm';
import SshKeysList from 'components/Dashboard/Orgs/Wizard/SftpUsers/SshKeysList/SshKeysList';

import { Button, Modal, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  org: Org;
}

interface SftpUserSshKeys {
  sftpUserId: string;
  sshKeys: SSHKey[];
}

const { Title, Text } = Typography;

const SftpUsers: React.FC<Props> = ({ org }) => {
  const {
    isLoading,
    sftpUsers,
    fetchSftpUsers,
    deleteSftpUser,
    createSftpUser,
    createSftpUserSshKey,
    deleteSftpUserSshKey,
  } = useSftpUsers();

  const [ isNewSftpUserModalOpen, setIsNewSftpUserModalOpen ] = useState<boolean>(false);
  const openNewSftpUserModal = () => setIsNewSftpUserModalOpen(true);
  const closeNewSftpUserModal = () => setIsNewSftpUserModalOpen(false);

  const [ sftpUserIdToDelete, setSftpUserIdToDelete ] = useState<string | null>(null);
  const openDeleteSftpUserModal = (userId: string) => setSftpUserIdToDelete(userId);
  const closeDeleteSftpUserModal = () => setSftpUserIdToDelete(null);

  const [ sftpUserSshKeysToManage, setSftpUserSshKeysToManage ] = useState<SftpUserSshKeys | null>(null);
  const openManageSshKeysModal = (sftpUserId: string, sshKeys: SSHKey[]) => setSftpUserSshKeysToManage({ sftpUserId, sshKeys });
  const closeManageSshKeysModal = () => setSftpUserSshKeysToManage(null);

  const [ isAddSshKeyModalOpen, setIsAddSshKeyModalOpen ] = useState<boolean>(false);
  const [ sshKeyIdToRemove, setSshKeyIdToRemove ] = useState<string | null>(null);

  const { columns } = useSftpUsersColumns(openDeleteSftpUserModal, openManageSshKeysModal);

  const removeSftpUser = async (sftpUserId: string) => {
    await deleteSftpUser(org.id, sftpUserId);
    closeDeleteSftpUserModal();
  };

  const addSftpUser = async (sshKey?: string) => {
    await createSftpUser(org.id, sshKey);
    closeNewSftpUserModal();
  };

  const addSftpUserSshKey = async (sshKey?: string) => {
    if (sftpUserSshKeysToManage) {
      const sftpUser = await createSftpUserSshKey(org.id, sftpUserSshKeysToManage.sftpUserId, sshKey);
      if (sftpUser) {
        setSftpUserSshKeysToManage({ sftpUserId: sftpUser.id, sshKeys: sftpUser.sshKeys });
      }
      setIsAddSshKeyModalOpen(false);
    }
  };

  const removeSftpUserSshKey = async (sshKeyId: string) => {
    if (sftpUserSshKeysToManage) {
      const sftpUser = await deleteSftpUserSshKey(org.id, sftpUserSshKeysToManage.sftpUserId, sshKeyId);
      if (sftpUser) {
        setSftpUserSshKeysToManage({ sftpUserId: sftpUser.id, sshKeys: sftpUser.sshKeys });
      }
      setSshKeyIdToRemove(null);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchSftpUsers(org.id, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchSftpUsers, org.id ]);

  const { tablePagination } = useTablePagination();
  const pagination = tablePagination(sftpUsers.length);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          icon={<PlusOutlined style={{ fontSize: 16 }} />}
          onClick={openNewSftpUserModal}
          style={{ marginBottom: 16 }}
        >
          Create SFTP user
        </Button>
      </div>

      <Table
        rowKey={(sftpUser: SftpUser) => sftpUser.id}
        columns={columns}
        dataSource={sftpUsers}
        scroll={{ x: true }}
        pagination={pagination}
        loading={isLoading}
      />

      <Modal
        title={<Title level={4}>New SFTP user</Title>}
        open={isNewSftpUserModalOpen}
        footer={null}
        width={420}
        onCancel={closeNewSftpUserModal}
        destroyOnClose
        data-testid="new-sftp-user-modal"
      >
        <AddSshKeyForm
          onSubmit={addSftpUser}
          onCancel={closeNewSftpUserModal}
          isLoading={isLoading}
        />
      </Modal>

      <Modal
        title={<Title level={4}>Delete SFTP user</Title>}
        open={!!sftpUserIdToDelete}
        width={420}
        onCancel={closeDeleteSftpUserModal}
        onOk={() => removeSftpUser(sftpUserIdToDelete!)}
        confirmLoading={isLoading}
      >
        <div style={{ margin: '24px 0' }}>
          <Text>Are you sure you want to delete this SFTP user?</Text>
        </div>
      </Modal>

      {sftpUserSshKeysToManage && (
        <Modal
          title={<Typography.Title level={4}>Manage SSH keys</Typography.Title>}
          open={!!sftpUserSshKeysToManage}
          footer={null}
          width={480}
          onCancel={closeManageSshKeysModal}
          destroyOnClose
        >
          <SshKeysList
            sshKeys={sftpUserSshKeysToManage.sshKeys}
            onCancel={closeManageSshKeysModal}
            onAddSshKey={addSftpUserSshKey}
            isAddSshKeyModalOpen={isAddSshKeyModalOpen}
            setIsAddSshKeyModalOpen={setIsAddSshKeyModalOpen}
            onDeleteSshKey={removeSftpUserSshKey}
            sshKeyIdToRemove={sshKeyIdToRemove}
            setSshKeyIdToRemove={setSshKeyIdToRemove}
            isLoading={isLoading}
          />
        </Modal>
      )}
    </>
  );
};

export default SftpUsers;
