import { Org } from 'api/Orgs/types';

import { Button, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const useColumns = () => {
  const navigate = useNavigate();
  const goToEditOrg = (orgId: string) => navigate(orgId);

  const columns: ColumnsType<Org> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Domains',
      render: (_, { domains }) => {
        return <Typography.Text>{domains.join(', ')}</Typography.Text>;
      },
    },
    {
      title: 'Web users',
      dataIndex: 'webUsersCount',
    },
    {
      title: 'SFTP users',
      dataIndex: 'sftpUsersCount',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, { id }) => <Button type="link" onClick={() => goToEditOrg(id)}>Edit</Button>,
    },
  ];

  return { columns };
};

export default useColumns;
