import { CreditExchange } from 'api/Credit/types';
import { Org } from 'api/Orgs/types';
import { formatDateTime } from 'app/helpers/dateTime';
import useOrgsOptions from 'app/hooks/useOrgsOptions';
import { useDeleteCreditExchange } from 'components/Dashboard/Billing/CreditExchanges/hooks';

import { Popconfirm, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';

const useColumns = (
  onCreditExchangeDeleted: (creditExchangeId: string) => void,
  setUpdatedCreditExchange: (creditExchange: CreditExchange) => void,
  org?: Org,
) => {
  const { getOrgNameByOrgId } = useOrgsOptions();
  const { deleteCreditExchange, deletingId } = useDeleteCreditExchange(onCreditExchangeDeleted);

  const columns: ColumnsType<CreditExchange> = [
    {
      title: 'Service',
      render: (_, { creditTariff }) => creditTariff.creditService.name,
    },
    {
      title: 'Credits threshold',
      render: (_, { creditTariff }) => creditTariff.price,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
    },
    {
      title: 'Currency',
      dataIndex: 'currencyFrom',
    },
    ...(!org ? [ {
      title: 'Organization',
      render: (_, { orgId }) => getOrgNameByOrgId(orgId),
    } ] as ColumnsType<CreditExchange> : []),
    {
      title: 'Created',
      render: (_, { createdAt }) => <Typography.Text style={{ whiteSpace: 'nowrap' }}>{formatDateTime(createdAt)}</Typography.Text>,
    },
    {
      title: 'Updated',
      render: (_, { updatedAt }) => <Typography.Text style={{ whiteSpace: 'nowrap' }}>{formatDateTime(updatedAt)}</Typography.Text>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, creditExchange) => (
        <Space size="middle">
          <Typography.Link onClick={() => setUpdatedCreditExchange(creditExchange)}>Update</Typography.Link>
          <Popconfirm
            title="Delete the exchange"
            description="Are you sure you want to delete this exchange"
            onConfirm={() => deleteCreditExchange(creditExchange.id)}
          >
            <Typography.Link disabled={creditExchange.id === deletingId}>Remove</Typography.Link>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return { columns };
};

export default useColumns;
