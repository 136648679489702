import { Org } from 'api/Orgs/types';
import { dashboardUrl, orgsUrl } from 'app/consts/urls';
import CreditTemplates from 'components/Dashboard/Billing/CreditTemplates/CreditTemplates';
import { OrgLocationState } from 'components/Dashboard/Orgs/types';
import Wizard from 'components/Dashboard/Orgs/Wizard/Wizard';
import { DashboardPageProps } from 'components/Dashboard/types';
import { useAppSelector } from 'store/hooks';
import { selectOrgs } from 'store/orgs/orgsSlice';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Modal, Typography } from 'antd';

const EditOrg: React.FC<DashboardPageProps> = ({ setTitle }) => {
  const navigate = useNavigate();
  const [ org, setOrg ] = useState<Org | null | undefined>(null);
  const { orgId } = useParams<{ orgId: string }>();
  const orgs = useAppSelector(selectOrgs);
  const location = useLocation();
  const [ isCreditTemplatesFormOpened, setIsCreditTemplatesFormOpened ] = useState<boolean>(false);
  const closeCreditTemplatesFormModal = () => setIsCreditTemplatesFormOpened(false);

  useEffect(() => {
    const state = location.state as OrgLocationState || {};
    if (state.hasOrgJustCreated) {
      setIsCreditTemplatesFormOpened(true);
    }
  }, [ location ]);

  useEffect(() => {
    if (orgs.length > 0) {
      setOrg(orgs.find(org => org.id === orgId));
    }
  }, [ orgs, orgId, setOrg ]);

  useEffect(() => {
    if (org === undefined) {
      navigate(`/${dashboardUrl}/${orgsUrl}`);
    }
  }, [ org, navigate ]);

  useEffect(() => {
    setTitle(`Edit organization: ${org?.name}`);
  }, [ setTitle, org ]);

  if (org) {
    return (
      <>
        <Wizard org={org} />

        <Modal
          title={<Typography.Title level={4}>Credit templates of organization</Typography.Title>}
          open={isCreditTemplatesFormOpened}
          footer={[
            <Button type="primary" key="close" onClick={closeCreditTemplatesFormModal}>
              Confirm
            </Button>,
          ]}
          width={1000}
          onCancel={closeCreditTemplatesFormModal}
          destroyOnClose
        >
          <Typography.Text type="danger">
            Please review created credit templates of the organization before attaching execution models.
          </Typography.Text>
          <CreditTemplates org={org} hasOrgJustCreated />
        </Modal>
      </>
    );
  }

  return <p>Could not load the organization</p>;
};

export default EditOrg;
