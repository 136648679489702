import { textColorSecondary } from 'app/consts/colors';

import { Typography, Form, Input, Button, Alert } from 'antd';
import React from 'react';

export interface FormValues {
  email: string;
}

interface Props {
  onSubmit: (values: FormValues) => void;
  error: string;
  codeHasBeenSent: boolean;
}

const ForgotPasswordForm: React.FC<Props> = ({ onSubmit, error, codeHasBeenSent }) => {
  const [ form ] = Form.useForm<FormValues>();

  return (
    <>
      <Typography.Title level={3} style={{ marginBottom: 0 }}>Reset password</Typography.Title>
      <Typography.Text style={{ color: textColorSecondary }}>
        Enter your registration email and we will send you the reset code
      </Typography.Text>
  
      <Form
        form={form}
        name="forgotPassword"
        style={{ marginTop: 24 }}
        layout="vertical"
        onFinish={onSubmit}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[ { required: true, message: 'Please enter your email' },
            { type: 'email', message: 'Please enter valid email' } ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        {error && (
          <Form.Item>
            <Alert message={error} type="error" showIcon banner />
          </Form.Item>
        )}

        {codeHasBeenSent && (
          <Form.Item>
            <Alert message="Reset code has been sent to your email" type="success" showIcon banner />
          </Form.Item>
        )}
  
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
