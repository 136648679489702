import { axiosInstance } from 'api/instance';
import { AuthResponse, CommonAuthResponse, MFAAuthResponse } from 'api/Auth/types';
import urls from 'api/Auth/urls';

import { AxiosResponse } from 'axios';

export const postAuthRequest = (email: string, password: string): Promise<AxiosResponse<AuthResponse>> => {
  return axiosInstance.post<AuthResponse>(urls.authUrl, { email, password });
};

export const postConfirmRequest = (code: string, mfaSessionId: string): Promise<AxiosResponse<CommonAuthResponse>> => {
  return axiosInstance.post<CommonAuthResponse>(urls.confirmUrl, { code, mfaSessionId });
};

export const postRequestNewCodeRequest = (mfaSessionId: string): Promise<AxiosResponse<MFAAuthResponse>> => {
  return axiosInstance.post<MFAAuthResponse>(urls.requestNewCodeUrl, { mfaSessionId });
};

export const postRefreshTokenRequest = (refresh: string, access: string): Promise<AxiosResponse<CommonAuthResponse>> => {
  return axiosInstance.post<CommonAuthResponse>(urls.refreshUrl, { refresh, access });
};

export const postLogoutRequest = (refresh: string): Promise<AxiosResponse> => {
  return axiosInstance.post(urls.logoutUrl, { refresh });
};
