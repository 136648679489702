import { getCreditTemplatesRequest } from 'api/Credit/api';
import { PaginatedCreditTemplates, CreditTariffsFilterParams } from 'api/Credit/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useCreditTemplates = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ creditTemplates, setCreditTemplates ] = useState<PaginatedCreditTemplates>({ count: 0, results: [] });

  const fetchCreditTemplates = useCallback((page: number, pageSize: number, filter: CreditTariffsFilterParams, abortController: AbortController) => {
    setIsLoading(true);
    getCreditTemplatesRequest(page, pageSize, filter, abortController)
      .then(({ data }) => {
        setCreditTemplates(data);
      })
      .catch((reason) => {
        if (!abortController?.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { creditTemplates, isLoading, fetchCreditTemplates, setCreditTemplates };
};

export default useCreditTemplates;
