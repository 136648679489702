import { Sample } from 'api/Data/types';
import { ALL_SAMPLES_ID } from 'components/Dashboard/Orders/consts';
import { useRequestPdf } from 'components/Dashboard/Orders/hooks';

import { Button, Form, Space, Radio } from 'antd';
import React, { useEffect } from 'react';

interface FormValues {
  sampleId: string;
}

interface Props {
  orderId: string;
  onCancel: () => void;
  onPdfRequested: (orderId: string) => void;
}

const RequestPdfForm: React.FC<Props> = ({ orderId, onCancel, onPdfRequested }) => {
  const [ form ] = Form.useForm<FormValues>();

  const { requestPdf, isLoading, getSamples, samples } = useRequestPdf(onPdfRequested, onCancel);

  useEffect(() => {
    const abortController = new AbortController();
    getSamples(orderId, abortController);

    return () => {
      abortController.abort();
    };
  }, [ getSamples, orderId ]);

  const onFinish = (values: FormValues) => {
    requestPdf(orderId, values.sampleId);
  };

  return (
    <Form
      form={form}
      name="requestForm"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ marginTop: 24 }}
      initialValues={{ sampleId: ALL_SAMPLES_ID }}
    >
      <Form.Item name="sampleId" label="Please select a sample">
        <Radio.Group disabled={isLoading} style={{ maxHeight: 400, overflow: 'auto', marginTop: 8, width: '100%' }}>
          <Space direction="vertical">
            <Radio value={ALL_SAMPLES_ID}>All</Radio>
            {samples.map((sample: Sample) => <Radio value={sample.resultId} key={sample.resultId}>{sample.sample}</Radio>)}
          </Space>
        </Radio.Group>
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            Request
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default RequestPdfForm;
