import { updateTagRequest } from 'api/Exec/api';
import { changeTag } from 'store/exec/execSlice';
import { useAppDispatch } from 'store/hooks';

import { useCallback, useState } from 'react';

const useUpdateTag = (finalCallback: () => void) => {
  const dispatch = useAppDispatch();
  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);

  const updateTag = useCallback((tagId: string, name: string) => {
    setIsUpdating(true);
    updateTagRequest(tagId, name)
      .then(({ data }) => {
        dispatch(changeTag(data));
      })
      .finally(() => {
        setIsUpdating(false);
        finalCallback();
      });
  }, [ dispatch, finalCallback ]);

  return { isUpdating, updateTag };
};

export default useUpdateTag;
