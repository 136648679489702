import { getColor } from 'components/Dashboard/Plots/helpers';
import { HasChartFilter } from 'components/Dashboard/Plots/types';
import useDonut from 'components/Dashboard/Plots/Donut/hooks';
import { useAppSelector } from 'store/hooks';
import { selectDonutChart } from 'store/plots/plotsSlice';

import { Sunburst, SunburstConfig } from '@ant-design/plots';
import React, { forwardRef, useEffect } from 'react';
import { cloneDeep } from 'lodash';

const Donut = forwardRef<HTMLDivElement, HasChartFilter>(({ filter }, ref) => {
  const { sunburstData, fetchDonut, isLoading, setSunburstData } = useDonut();
  const donutChart = useAppSelector(state => selectDonutChart(state, filter.plotRequestId));

  useEffect(() => {
    const abortController = new AbortController();
    if (donutChart) {
      // antd Sunburst chart is modifying data so that we need to clone redux state object firstly
      setSunburstData(cloneDeep(donutChart));
    } else {
      fetchDonut(filter, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchDonut, filter, setSunburstData, donutChart ]);

  const sunburstConfig: SunburstConfig = {
    autoFit: false,
    width: 500,
    height: 500,
    data: sunburstData,
    innerRadius: 0.3,
    loading: isLoading,
    label: {
      style: {
        fontSize: 10,
        fill: 'black',
      },
      content: (data) => {
        const { name, value } = data;
        const val = value.toFixed(2);
        return val > 0 ? `${name}\n${val}` : '';
      },
      layout: [
        {
          type: 'fixed-overlap',
        },
      ],
    },
    tooltip: {
      customItems: items => items.map((item) => {
        return { ...item, name: item.data.path, value: item.data.value.toFixed(2) };
      }),
    },
    color: ({ name }) => getColor(filter.modelApiName, name, '#AC9797'),
  };

  return <div ref={ref}><Sunburst {...sunburstConfig} /></div>;
});

export default Donut;
