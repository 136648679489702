import { updateUserRequest } from 'api/Users/api';
import { UpdateUserParams, User } from 'api/Users/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { message } from 'antd';

const useUpdateUser = (onUserUpdated: (user: User) => void, finalCallback: () => void) => {
  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);

  const updateUser = (userId: string, params: UpdateUserParams) => {
    setIsUpdating(true);
    updateUserRequest(userId, params)
      .then(({ data }) => {
        onUserUpdated(data);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsUpdating(false);
        finalCallback();
      });
  };

  return { isUpdating, updateUser };
};

export default useUpdateUser;
