import { OrderStatistics } from 'api/Statistics/types';
import useColumns from 'components/Dashboard/Statistics/Orders/useColumns';
import OrdersPlot from 'components/Dashboard/Statistics/Orders/OrdersPlot/OrdersPlot';
import 'components/Dashboard/Statistics/Orders/Orders.css';

import React from 'react';
import { Table } from 'antd';

interface Props {
  statistics: OrderStatistics[];
}

const Orders: React.FC<Props> = ({ statistics }) => {
  const { columns } = useColumns();

  return (
    <>
      <OrdersPlot statistics={statistics} />

      <Table
        data-testid="orders-table"
        rowKey={record => String(record.modelApiName)}
        columns={columns}
        dataSource={statistics}
        pagination={false}
        scroll={{ x: true }}
        style={{ marginBottom: 24, marginTop: 24 }}
        rowClassName={(_, index) => (index === 0 ? 'totalRow' : '')}
      />
    </>
  );
};

export default Orders;
