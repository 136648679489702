import { Org } from 'api/Orgs/types';
import { inviteUserRequest } from 'api/Users/api';
import { Role, User } from 'api/Users/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { message } from 'antd';

export const useInviteUser = (onUserInvited: (user: User) => void) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const inviteUser = (email: string, role: Role, org?: Org) => {
    setIsLoading(true);

    inviteUserRequest(email, role, org?.id)
      .then((response) => {
        onUserInvited(response.data);
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { isLoading, inviteUser };
};
