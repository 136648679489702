export const white = '#FFFFFF';
export const black = '#000000';

export const goldenPurple5 = '#9254DE';
export const goldenPurple6 = '#722ED1'; // primary color
export const goldenPurple7 = '#531DAB';

export const textColor = 'rgba(0, 0, 0, 0.85)';
export const textColorSecondary = 'rgba(0, 0, 0, 0.45)';
export const borderColorSplit = 'rgba(0, 0, 0, 0.06)';
export const backgroundColorBase = 'rgba(0, 0, 0, 0.04)';
export const backgroundColorLight = 'rgba(0, 0, 0, 0.02)';

export const heatmapColors = [
  '#0084FF',
  '#188EF7',
  '#3199EF',
  '#49A4E8',
  '#62AFE0',
  '#7ABAD9',
  '#93C5D1',
  '#ABD0C9',
  '#C4DBC2',
  '#DCE6BA',
  '#F5F1B3',
  '#F5DBA3',
  '#F6C694',
  '#F6B085',
  '#F79B76',
  '#F78667',
  '#F87057',
  '#F85B48',
  '#F94539',
  '#F9302A',
  '#FA1B1B',
];
