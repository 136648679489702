import { getTagsRequest, getModelsApiRequest } from 'api/Exec/api';
import { ModelApi, Tag } from 'api/Exec/types';
import { ExecState } from 'store/exec/types';
import { RootState } from 'store/types';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: ExecState = {
  modelsApi: [],
  tags: [],
  status: 'idle',
};

export const getTagsAsync = createAsyncThunk(
  'exec/getTags',
  async (abortController: AbortController) => {
    const response = await getTagsRequest(1, 100, '', abortController);
    return response.data.results;
  },
);

export const getModelApiAsync = createAsyncThunk(
  'exec/getModelsApi',
  async (abortController: AbortController) => {
    const response = await getModelsApiRequest(abortController);
    return response.data;
  },
);

export const execSlice = createSlice({
  name: 'exec',
  initialState,
  reducers: {
    addTag: (state, action: PayloadAction<Tag>) => {
      state.tags = [ action.payload, ...state.tags ];
    },
    removeTag: (state, action: PayloadAction<string>) => {
      const tagIndex = state.tags.findIndex(tag => tag.id === action.payload);
      state.tags = [
        ...state.tags.slice(0, tagIndex),
        ...state.tags.slice(tagIndex + 1, state.tags.length),
      ];
    },
    changeTag: (state, action: PayloadAction<Tag>) => {
      const tagIndex = state.tags.findIndex(tag => tag.id === action.payload.id);
      state.tags = [
        ...state.tags.slice(0, tagIndex),
        action.payload,
        ...state.tags.slice(tagIndex + 1, state.tags.length),
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTagsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTagsAsync.fulfilled, (state, action: PayloadAction<Tag[]>) => {
        state.status = 'idle';
        state.tags = action.payload;
      })
      .addCase(getTagsAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getModelApiAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getModelApiAsync.fulfilled, (state, action: PayloadAction<ModelApi[]>) => {
        state.status = 'idle';
        state.modelsApi = action.payload;
      })
      .addCase(getModelApiAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { addTag, removeTag, changeTag } = execSlice.actions;

export const selectTags = (state: RootState) => state.exec.tags;
export const selectModelsApi = (state: RootState) => state.exec.modelsApi;

export default execSlice.reducer;
