import { createModelRequest } from 'api/Exec/api';
import { ModelApiName } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useState } from 'react';

const useAddDockerImage = () => {
  const [ isCreating, setIsCreating ] = useState<boolean>(false);

  const addDockerImage = async (imageTag: string, modelApiName: ModelApiName, modelName?: string) => {
    setIsCreating(true);

    return createModelRequest(imageTag, modelApiName, modelName)
      .then(({ data }) => {
        message.success('Docker image was added');
        return data;
      })
      .catch((reason) => {
        message.error(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return { isCreating, addDockerImage };
};

export default useAddDockerImage;
