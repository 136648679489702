import { forgotPasswordRequest, resetPasswordRequest } from 'api/Users/api';
import { textColorSecondary } from 'app/consts/colors';
import { homeUrl } from 'app/consts/urls';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import ForgotPasswordForm, { FormValues as ForgotPasswordValues } from 'components/Auth/ResetPassword/ForgotPasswordForm/ForgotPasswordForm';
import ResetPasswordForm, { FormValues as ResetPasswordValues } from 'components/Auth/ResetPassword/ResetPasswordForm/ResetPasswordForm';

import { Typography, Space } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const goToSignIn = () => navigate(homeUrl);

  const [ error, setError ] = useState<string>('');
  const [ codeHasBeenSent, setCodeHasBeenSent ] = useState<boolean>(false);
  const [ passwordHasBeenReset, setPasswordHasBeenReset ] = useState<boolean>(false);

  const [ searchParams ] = useSearchParams();
  const code = searchParams.get('code') || '';

  const onSubmitForgotPasswordForm = (values: ForgotPasswordValues) => {
    forgotPasswordRequest(values.email)
      .then(() => {
        setError('');
        setCodeHasBeenSent(true);
      })
      .catch((reason) => {
        setError(getErrorFromPromiseReason(reason));
        setCodeHasBeenSent(false);
      });
  };

  const onSubmitResetPasswordForm = (values: ResetPasswordValues) => {
    resetPasswordRequest(code, values.password)
      .then(() => {
        setError('');
        setPasswordHasBeenReset(true);
      })
      .catch((reason) => {
        setError(getErrorFromPromiseReason(reason));
        setPasswordHasBeenReset(false);
      });
  };

  return (
    <>
      {!code ?
        <ForgotPasswordForm onSubmit={onSubmitForgotPasswordForm} error={error} codeHasBeenSent={codeHasBeenSent} /> :
        <ResetPasswordForm onSubmit={onSubmitResetPasswordForm} error={error} passwordHasBeenReset={passwordHasBeenReset} goToSignIn={goToSignIn} />}
      <Space style={{ justifyContent: 'center', width: '100%' }}>
        <Typography.Text style={{ color: textColorSecondary }}>
          Go to
        </Typography.Text>
        <Typography.Link onClick={goToSignIn}>
          Sign In
        </Typography.Link>
      </Space>
    </>
  );
};

export default ResetPassword;
