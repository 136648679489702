import { Org } from 'api/Orgs/types';
import { Role, Status, User } from 'api/Users/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import { copy } from 'app/helpers/copy';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import { useApiUsersColumns, useDeleteUser, useUsers, useCreateApiUser } from 'components/Dashboard/Users/hooks';
import UsersFilter from 'components/Dashboard/Users/UsersFilter/UsersFilter';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space, Table, Typography } from 'antd';
import { DateTime } from 'luxon';

const { Title, Text } = Typography;

interface Props {
  org?: Org
}

const ApiUsers: React.FC<Props> = ({ org }) => {
  const { role: myUserRole, org: { id: orgId } } = useAppSelector(selectMyUser);
  const { pageParam, pageSizeParam, searchParam, orgParam } = usePaginationParams();
  const { tablePagination } = useTablePagination();

  const { generateApiKey, setApiKey, isApiKeyGenerating, apiKey } = useCreateApiUser();

  const [ userIdToDelete, setUserIdToDelete ] = useState<string | null>(null);
  const openDeleteUserModal = (userId: string) => setUserIdToDelete(userId);
  const closeDeleteUserModal = () => setUserIdToDelete(null);

  const { columns } = useApiUsersColumns(openDeleteUserModal);
  const { response, isLoading, fetchUsers, setResponse } = useUsers();

  const onUserDeleted = (userId: string) => {
    const userIndex = response.results.findIndex((user: User) => user.id === userId);

    setResponse({ results: [
      ...response.results.slice(0, userIndex),
      { ...response.results[userIndex], deletedAt: DateTime.now().toString(), status: Status.DELETED },
      ...response.results.slice(userIndex + 1, response.results.length),
    ],
    count: response.count });
    message.success('API key user was deleted');
  };

  const reload = () => {
    const orgNames = org ? org.name : orgParam;
    fetchUsers(pageParam, pageSizeParam, searchParam, orgNames, true);
  };

  const { deleteUser, isDeleting } = useDeleteUser(onUserDeleted, closeDeleteUserModal);

  const addApiKey = () => {
    const id = org?.id || orgId;
    generateApiKey(id);
  };

  const copyAPIKey = () => copy(apiKey, 'API key was copied');

  const closeApiKeyModal = () => {
    setApiKey('');
    reload();
  };

  useEffect(() => {
    const abortController = new AbortController();
    const orgNames = org ? org.name : orgParam;
    fetchUsers(pageParam, pageSizeParam, searchParam, orgNames, true, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchUsers, pageParam, pageSizeParam, searchParam, orgParam, org ]);

  const pagination = tablePagination(response.count);

  const filter = (myUserRole === Role.SYSTEM_ADMIN && !org) ? UsersFilter : undefined;

  return (
    <>
      <TableTopPanel
        RightColumn={<Button type="primary" onClick={addApiKey} icon={<PlusOutlined />} loading={isApiKeyGenerating}>Generate API key</Button>}
        Filter={filter}
      />

      <Table
        rowKey={(user: User) => user.id}
        columns={columns}
        dataSource={response.results}
        pagination={pagination}
        loading={isLoading}
        scroll={{ x: true }}
      />

      <Modal
        title={<Title level={4}>Delete API key user</Title>}
        open={!!userIdToDelete}
        width={420}
        onCancel={closeDeleteUserModal}
        onOk={() => deleteUser(userIdToDelete!)}
        confirmLoading={isDeleting}
      >
        <div style={{ margin: '24px 0' }}>
          <Text>Are you sure you want to delete this API key user?</Text>
        </div>
      </Modal>

      <Modal
        title={<Title level={4}>API key</Title>}
        open={!!apiKey}
        width={480}
        footer={[
          <Button type="primary" onClick={closeApiKeyModal} key="close">Close</Button>,
        ]}
        onCancel={closeApiKeyModal}
        destroyOnClose
      >
        <Space direction="vertical">
          <Text type="danger">
            Please save this API key locally. You won&apos;t be able to restore it, only generate a new one.
          </Text>
          <Text code>{apiKey}</Text>
          <Typography.Link onClick={copyAPIKey}>Copy API key</Typography.Link>
        </Space>
      </Modal>
    </>
  );
};

export default ApiUsers;
