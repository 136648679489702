import { getCreditServicesRequest } from 'api/Credit/api';
import { PaginatedCreditServices } from 'api/Credit/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useCreditServices = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ creditServices, setCreditServices ] = useState<PaginatedCreditServices>({ count: 0, results: [] });

  const fetchCreditServices = useCallback((page: number, pageSize: number, abortController: AbortController) => {
    setIsLoading(true);
    getCreditServicesRequest(page, pageSize, abortController)
      .then(({ data }) => {
        setCreditServices(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { creditServices, isLoading, fetchCreditServices, setCreditServices };
};

export default useCreditServices;
