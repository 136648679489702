import useUpdateTag from 'app/common_components/TagsList/UpdateTagForm/hooks';

import { Button, Form, Input, Space } from 'antd';
import React from 'react';

interface FormValues {
  name: string;
}

interface Props {
  tagId: string;
  onCancel: () => void;
  name: string;
}

const UpdateTagForm: React.FC<Props> = ({ tagId, onCancel, name = '' }) => {
  const [ form ] = Form.useForm<FormValues>();

  const { updateTag, isUpdating } = useUpdateTag(onCancel);

  const onFinish = (values: FormValues) => {
    updateTag(tagId, values.name);
  };

  return (
    <Form
      form={form}
      name="updateTagForm"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ marginTop: 24 }}
      initialValues={{ name }}
    >
      <Form.Item name="name" label="Tag name">
        <Input />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={isUpdating}>
            Update
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UpdateTagForm;
