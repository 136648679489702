import Notifications from 'containers/DashboardContainer/components/MyUser/components/Notifications/Notifications';
import { logoutAsync } from 'store/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectNotifications } from 'store/notifications/notificationsSlice';
import { selectMyUser } from 'store/users/usersSlice';

import React, { useMemo } from 'react';
import { Badge, Button, Dropdown, Popover, Space, Tooltip, Typography } from 'antd';
import { BellOutlined, DownOutlined } from '@ant-design/icons';

const MyUser: React.FC = () => {
  const dispatch = useAppDispatch();
  const logoutClick = () => dispatch(logoutAsync());

  const notifications = useAppSelector(selectNotifications);
  const unreadNotifications = useMemo(() => notifications.filter(n => !n.watched), [ notifications ]);

  const { firstName, lastName, email, org: { name } } = useAppSelector(selectMyUser);
  const caption = firstName && lastName ? `${firstName} ${lastName}` : email;

  return (
    <Space>

      <Popover
        trigger="click"
        placement="bottom"
        content={<Notifications notifications={notifications} />}
        overlayInnerStyle={{ maxHeight: 600, overflowY: 'scroll' }}
      >
        <Badge offset={[ -6, 3 ]} dot={!!unreadNotifications.length} color="purple" styles={{ indicator: { width: 10, height: 10 } }}>
          <Tooltip title={unreadNotifications.length ? 'You have unread notifications' : ''}>
            <Button icon={<BellOutlined />} type="default" size="large" style={{ marginRight: 5 }} />
          </Tooltip>
        </Badge>
      </Popover>

      <Space size={0} direction="vertical">
        <Typography.Text strong>{caption}</Typography.Text>
        <Typography.Text>{name}</Typography.Text>
      </Space>

      <Dropdown
        trigger={[ 'click' ]}
        menu={{ items: [ { key: '1', label: 'Logout', onClick: logoutClick } ] }}
      >
        <DownOutlined data-testid="dropdown-icon" />
      </Dropdown>

    </Space>
  );
};

export default MyUser;
