import { Org } from 'api/Orgs/types';
import usePaginationParams from 'app/hooks/usePaginationParams';
import CreditExchanges from 'components/Dashboard/Billing/CreditExchanges/CreditExchanges';
import CreditLedger from 'components/Dashboard/Billing/CreditLedger/CreditLedger';
import CreditTariffs from 'components/Dashboard/Billing/CreditTariffs/CreditTariffs';
import CreditTemplates from 'components/Dashboard/Billing/CreditTemplates/CreditTemplates';
import ReplenishForm from 'components/Dashboard/Orgs/Wizard/OrgBilling/ReplenishForm/ReplenishForm';
import { getCreditBalanceAsync, selectCreditBalance } from 'store/credits/creditsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Button, Modal, Space, Statistic, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
  org: Org;
}

const OrgBilling: React.FC<Props> = ({ org }) => {
  const { clearSearchParams } = usePaginationParams();
  const dispatch = useAppDispatch();

  const [ isReplenishModalOpen, setIsReplenishModalOpen ] = useState<boolean>(false);
  const openReplenishModal = () => setIsReplenishModalOpen(true);
  const closeReplenishModal = () => setIsReplenishModalOpen(false);

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(getCreditBalanceAsync({ orgId: org.id, abortController }));

    return () => {
      abortController.abort();
    };
  }, [ dispatch, org ]);

  const { balance } = useAppSelector(selectCreditBalance);

  const tabs: TabsProps['items'] = [
    {
      key: 'ledger',
      label: 'Ledger',
      children: <CreditLedger org={org} />,
    },
    {
      key: 'tariffs',
      label: 'Tariff plans',
      children: <CreditTariffs org={org} />,
    },
    {
      key: 'templates',
      label: 'Templates',
      children: <CreditTemplates org={org} />,
    },
    {
      key: 'exchanges',
      label: 'Exchanges',
      children: <CreditExchanges org={org} />,
    },
  ];

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Statistic title="Organization Balance (credits)" value={balance} precision={2} />
        <Button type="primary" onClick={openReplenishModal}>
          Replenish
        </Button>
        <Tabs defaultActiveKey="ledger" items={tabs} onChange={clearSearchParams} destroyInactiveTabPane />
      </Space>

      <Modal
        title={<Typography.Title level={4}>Replenish</Typography.Title>}
        open={isReplenishModalOpen}
        footer={null}
        width={420}
        onCancel={closeReplenishModal}
        destroyOnClose
      >
        <ReplenishForm orgId={org.id} onCancel={closeReplenishModal} />
      </Modal>
    </>
  );
};

export default OrgBilling;
