import { getCreditExchangesRequest } from 'api/Credit/api';
import { PaginatedCreditExchanges } from 'api/Credit/types';

import { useCallback, useState } from 'react';

const useCreditExchanges = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ creditExchanges, setCreditExchanges ] = useState<PaginatedCreditExchanges>({ results: [], count: 0 });

  const fetchCreditExchanges = useCallback((page: number, pageSize: number, orgId: string, abortController: AbortController) => {
    setIsLoading(true);
    getCreditExchangesRequest(page, pageSize, orgId, abortController)
      .then(({ data }) => {
        setCreditExchanges(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { creditExchanges, isLoading, fetchCreditExchanges, setCreditExchanges };
};

export default useCreditExchanges;
