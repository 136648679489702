import { ColumnItem } from 'api/Statistics/types';
import usePaginationParams from 'app/hooks/usePaginationParams';
import { getLabel } from 'components/Dashboard/Statistics/helpers';
import useOrdersUsage from 'components/Dashboard/Statistics/OrdersUsage/useOrdersUsage';

import React, { useEffect, useState } from 'react';
import { Space, Radio, RadioChangeEvent } from 'antd';
import { Column, ColumnConfig } from '@ant-design/plots';

const OrdersUsage: React.FC = () => {
  const { orgParam, minDateParam, maxDateParam } = usePaginationParams();
  const { response, isLoading, fetchOrdersUsage } = useOrdersUsage();
  const [ plotDateRange, setPlotDateRange ] = useState<string>('1');

  const onRadioChanged = (e: RadioChangeEvent) => setPlotDateRange(e.target.value);

  useEffect(() => {
    const abortController = new AbortController();
    fetchOrdersUsage(orgParam, minDateParam, maxDateParam, plotDateRange, abortController);

    return () => {
      abortController.abort();
    };
  }, [ orgParam, minDateParam, maxDateParam, fetchOrdersUsage, plotDateRange ]);

  const columnConfig: ColumnConfig = {
    data: response,
    xField: 'x',
    yField: 'y',
    seriesField: 'series',
    isGroup: true,
    height: 500,
    autoFit: true,
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      flipPage: false,
      itemSpacing: 10,
      animate: false,
    },
    loading: isLoading,
    label: {
      position: 'middle',
      formatter: value => getLabel(value as ColumnItem),
      layout: [ { type: 'adjust-color' } ],
    },
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Radio.Group defaultValue={plotDateRange} buttonStyle="solid" onChange={onRadioChanged}>
        <Radio.Button value="1">Daily</Radio.Button>
        <Radio.Button value="2">Weekly</Radio.Button>
      </Radio.Group>

      <Column {...columnConfig} />
    </Space>
  );
};

export default OrdersUsage;
