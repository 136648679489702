import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'app/consts/pagination';
import usePaginationParams from 'app/hooks/usePaginationParams';
import { FilterProps } from 'components/Dashboard/types';

import React, { ReactElement, useState } from 'react';
import { Button, Input, Popover } from 'antd';
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';

import styles from './TableTopPanel.module.css';

interface Props {
  onReload?: () => void;
  placeholder?: string;
  Filter?: React.FC<FilterProps<any>>;
  onApplyFilter?: (values: any) => void;
  orgId?: string;
  RightColumn?: ReactElement;
}

const TableTopPanel: React.FC<Props> = (
  {
    onReload,
    placeholder,
    Filter,
    onApplyFilter,
    orgId,
    RightColumn,
  },
) => {
  const [ open, setOpen ] = useState<boolean>(false);
  const { searchParam, setSearchParam, setPaginationParams } = usePaginationParams();

  const onSearch = (value: string) => {
    setPaginationParams(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
    setSearchParam(value);
  };

  const onApply = (values: any) => {
    if (onApplyFilter) {
      onApplyFilter(values);
    }
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <div className={styles.panel}>
      <div style={{ display: 'flex' }}>
        {placeholder && (
          <Input.Search
            defaultValue={searchParam}
            placeholder={placeholder}
            allowClear
            className={styles.searchInput}
            onSearch={onSearch}
          />
        )}
        {onReload && (
          <Button icon={<ReloadOutlined />} onClick={onReload} className={styles.button}>
            Reload
          </Button>
        )}
        {Filter && (
          <Popover
            trigger="click"
            placement="bottom"
            title="Filters"
            content={<Filter onApply={onApply} orgId={orgId} />}
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button icon={<FilterOutlined />} className={styles.button}>
              Filter
            </Button>
          </Popover>
        )}
      </div>

      {RightColumn}
    </div>
  );
};

export default TableTopPanel;
