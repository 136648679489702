import { deleteTagRequest } from 'api/Exec/api';
import { removeTag } from 'store/exec/execSlice';
import { useAppDispatch } from 'store/hooks';

import { useCallback, useState } from 'react';

const useDeleteTag = (finalCallback: () => void) => {
  const dispatch = useAppDispatch();
  const [ isDeleting, setIsDeleting ] = useState<boolean>(false);

  const deleteTag = useCallback((tagId: string) => {
    setIsDeleting(true);
    deleteTagRequest(tagId)
      .then(() => {
        dispatch(removeTag(tagId));
      })
      .finally(() => {
        setIsDeleting(false);
        finalCallback();
      });
  }, [ dispatch, finalCallback ]);

  return { isDeleting, deleteTag };
};

export default useDeleteTag;
