import { Role, UpdateUserParams, User } from 'api/Users/types';
import useUpdateUser from 'components/Dashboard/Users/UpdateUserForm/hooks';

import { Button, Form, Input, Select, Space } from 'antd';
import React from 'react';

interface Props {
  onCancel: () => void;
  user: User;
  onUserUpdated: (user: User) => void
}

const UpdateUserForm: React.FC<Props> = ({ onCancel, user, onUserUpdated }) => {
  const [ form ] = Form.useForm<UpdateUserParams>();
  const { isUpdating, updateUser } = useUpdateUser(onUserUpdated, onCancel);

  const onFinish = (values: UpdateUserParams) => {
    updateUser(user.id, values);
  };

  return (
    <Form
      form={form}
      name="updateUserForm"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ marginTop: 24 }}
      initialValues={{ firstName: user.firstName, lastName: user.lastName, role: user.role }}
    >
      <Form.Item label="First name" name="firstName">
        <Input placeholder="Enter user’s first name" disabled={isUpdating} />
      </Form.Item>

      <Form.Item label="Last name" name="lastName">
        <Input placeholder="Enter user’s last name" disabled={isUpdating} />
      </Form.Item>

      <Form.Item label="Role" name="role">
        <Select
          disabled={isUpdating || user.role === Role.SYSTEM_ADMIN}
          options={[
            { value: Role.ORG_USER, label: 'User' },
            { value: Role.ORG_ADMIN, label: 'Organization Admin' },
          ]}
        />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={isUpdating}>
            Update
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UpdateUserForm;
