import { CreditLedgerFilterParams, CreditTariffsFilterParams } from 'api/Credit/types';

const rootUrl = 'credit';

const creditServiceUrl = `${rootUrl}/service`;
const creditServiceListUrl = (page: number, pageSize: number) => `${creditServiceUrl}?page=${page}&page_size=${pageSize}`;
const creditServiceItemUrl = (creditServiceId: string) => `${creditServiceUrl}/${creditServiceId}`;

const creditTariffUrl = `${rootUrl}/tariff`;
const creditTariffsListUrl = (page: number, pageSize: number, filter: CreditTariffsFilterParams) => {
  const { orgs, type, modelApi } = filter;
  return `${creditTariffUrl}?page=${page}&page_size=${pageSize}&orgs_ids=${orgs}&credit_service_types=${type}&model_api_names=${modelApi}`;
};
const creditTariffItemUrl = (creditTariffId: string) => `${creditTariffUrl}/${creditTariffId}`;

const creditExchangeUrl = `${rootUrl}/exchange`;
const creditExchangesListUrl = (page: number, pageSize: number, orgsIds: string) => {
  return `${creditExchangeUrl}?page=${page}&page_size=${pageSize}&orgs_ids=${orgsIds}`;
};
const creditExchangeItemUrl = (creditExchangeId: string) => `${creditExchangeUrl}/${creditExchangeId}`;

const creditBalanceListUrl = (page: number, pageSize: number, orgsIds: string) => {
  return `${rootUrl}/balance?page=${page}&page_size=${pageSize}&orgs_ids=${orgsIds}`;
};

const creditReplenish = `${rootUrl}/replenish`;

const creditLedgerUrl = (page: number, pageSize: number, filter: CreditLedgerFilterParams) => {
  const { orgs, type, modelApi, minDate, maxDate, search, showFullLedger } = filter;
  return [
    rootUrl,
    '/ledger',
    `?page=${page}`,
    `&page_size=${pageSize}`,
    `&orgs_ids=${orgs}`,
    `&order_id=${search}`,
    `&credit_service_types=${type}`,
    `&model_api_names=${modelApi}`,
    `&min_date=${minDate}`,
    `&max_date=${maxDate}`,
    `&show_full=${showFullLedger}`,
  ].join('');
};

const creditTemplateUrl = `${rootUrl}/template`;
const creditTemplateListUrl = (page: number, pageSize: number, filter: CreditTariffsFilterParams) => {
  const { orgs, type, modelApi } = filter;
  return `${creditTemplateUrl}?page=${page}&page_size=${pageSize}&orgs_ids=${orgs}&credit_service_types=${type}&model_api_names=${modelApi}`;
};
const creditTemplateItemUrl = (creditTemplateId: string) => `${creditTemplateUrl}/${creditTemplateId}`;

export default {
  creditServiceUrl,
  creditServiceListUrl,
  creditServiceItemUrl,
  creditTariffUrl,
  creditTariffsListUrl,
  creditTariffItemUrl,
  creditExchangeUrl,
  creditExchangesListUrl,
  creditExchangeItemUrl,
  creditBalanceListUrl,
  creditReplenish,
  creditLedgerUrl,
  creditTemplateListUrl,
  creditTemplateItemUrl,
};
