import { Message, NotificationType, JsonMessage, PlotRequestMessage, ResultFileMessage } from 'api/Notifications/types';
import { selectWebsocketAccessToken } from 'store/auth/authSlice';
import { useAppSelector } from 'store/hooks';

import { useCallback, useEffect, useRef } from 'react';
import useWebSocket from 'react-use-websocket';
import { camelizeKeys } from 'humps';

const useWSNotifications = (onReceiveMessage: (message: Message, type: NotificationType) => void) => {
  const token = useAppSelector(selectWebsocketAccessToken) || '';
  const lastNotificationId = useRef('');
  const WS_URL = `${process.env.REACT_APP_WS_URL}/notification`;

  const { lastJsonMessage } = useWebSocket<JsonMessage>(WS_URL, {
    queryParams: { token },
    share: true,
  });

  const handleMessage = useCallback((id: string, message: Message, type: NotificationType) => {
    if (lastNotificationId.current !== id) {
      onReceiveMessage(message, type);
      lastNotificationId.current = id;
    }
  }, [ onReceiveMessage ]);

  useEffect(() => {
    if (lastJsonMessage) {
      const formattedMessage = camelizeKeys<JsonMessage>(lastJsonMessage);
      const { type, message } = formattedMessage;
      if (type === 'plot_completed' || type === 'plot_failed') {
        handleMessage((message as PlotRequestMessage).plotRequestId, (message as PlotRequestMessage), type);
      } else if (type === 'upload_result_file_completed' || type === 'upload_result_file_failed') {
        handleMessage((message as ResultFileMessage).resultFileId, (message as ResultFileMessage), type);
      }
    }
  }, [ lastJsonMessage, handleMessage ]);
};

export default useWSNotifications;
