import { axiosInstance } from 'api/instance';
import {
  CreditBalance,
  CreditCurrency,
  CreditExchange,
  CreditLedgerFilterParams,
  CreditService,
  CreditTariff,
  CreditTariffsFilterParams,
  CreditTemplate,
  CreditServiceType,
  PaginatedCreditServices,
  PaginatedCreditTariffs,
  PaginatedCreditBalances,
  PaginatedCreditExchanges,
  PaginatedCreditTemplates,
  PaginatedCreditLedger,
} from 'api/Credit/types';
import urls from 'api/Credit/urls';

import { AxiosResponse } from 'axios';

export const getCreditServicesRequest = (
  page = 1,
  pageSize = 10,
  { signal }: AbortController,
): Promise<AxiosResponse<PaginatedCreditServices>> => {
  return axiosInstance.get<PaginatedCreditServices>(urls.creditServiceListUrl(page, pageSize), { signal });
};

export const createCreditServiceRequest = (name: string, type: CreditServiceType, namedModelId?: string): Promise<AxiosResponse<CreditService>> => {
  return axiosInstance.post<CreditService>(urls.creditServiceUrl, { name, type, namedModelId: namedModelId || null });
};

export const deleteCreditServiceRequest = (creditServiceId: string): Promise<AxiosResponse> => {
  return axiosInstance.delete(urls.creditServiceItemUrl(creditServiceId));
};

export const getCreditTariffsRequest = (
  page = 1,
  pageSize = 10,
  filter: CreditTariffsFilterParams,
  { signal }: AbortController,
): Promise<AxiosResponse<PaginatedCreditTariffs>> => {
  return axiosInstance.get<PaginatedCreditTariffs>(urls.creditTariffsListUrl(page, pageSize, filter), { signal });
};

export const createCreditTariffRequest = (orgId: string, creditServiceId: string, price: number): Promise<AxiosResponse<CreditTariff>> => {
  return axiosInstance.post<CreditTariff>(urls.creditTariffUrl, { orgId, creditServiceId, price });
};

export const updateCreditTariffRequest = (creditTariffId: string, price: number): Promise<AxiosResponse<CreditTariff>> => {
  return axiosInstance.put<CreditTariff>(urls.creditTariffItemUrl(creditTariffId), { price });
};

export const deleteCreditTariffRequest = (creditTariffId: string): Promise<AxiosResponse> => {
  return axiosInstance.delete(urls.creditTariffItemUrl(creditTariffId));
};

export const getCreditExchangesRequest = (
  page = 1,
  pageSize = 10,
  orgId: string,
  { signal }: AbortController,
): Promise<AxiosResponse<PaginatedCreditExchanges>> => {
  return axiosInstance.get<PaginatedCreditExchanges>(urls.creditExchangesListUrl(page, pageSize, orgId), { signal });
};

export const createCreditExchangeRequest = (
  orgId: string,
  price: number,
  rate: number,
  currencyFrom: CreditCurrency = 'USD',
): Promise<AxiosResponse<CreditExchange>> => {
  return axiosInstance.post<CreditExchange>(urls.creditExchangeUrl, { orgId, price, rate, currencyFrom });
};

export const updateCreditExchangeRequest = (
  creditExchangeId: string,
  price: number,
  rate: number,
  currencyFrom: CreditCurrency = 'USD',
): Promise<AxiosResponse<CreditExchange>> => {
  return axiosInstance.put<CreditExchange>(urls.creditExchangeItemUrl(creditExchangeId), { price, rate, currencyFrom });
};

export const deleteCreditExchangeRequest = (creditExchangeId: string): Promise<AxiosResponse> => {
  return axiosInstance.delete(urls.creditExchangeItemUrl(creditExchangeId));
};

export const getCreditBalancesRequest = (
  page = 1,
  pageSize = 10,
  orgId: string,
  abortController?: AbortController,
): Promise<AxiosResponse<PaginatedCreditBalances>> => {
  return axiosInstance.get<PaginatedCreditBalances>(urls.creditBalanceListUrl(page, pageSize, orgId), { signal: abortController?.signal });
};

export const replenishBalanceRequest = (orgId: string, amount: number): Promise<AxiosResponse<CreditBalance>> => {
  return axiosInstance.post<CreditBalance>(urls.creditReplenish, { orgId, amount });
};

export const getCreditLedgerRequest = (
  page = 1,
  pageSize = 10,
  filter: CreditLedgerFilterParams,
  abortController?: AbortController,
): Promise<AxiosResponse<PaginatedCreditLedger>> => {
  return axiosInstance.get<PaginatedCreditLedger>(urls.creditLedgerUrl(page, pageSize, filter), { signal: abortController?.signal });
};

export const getCreditTemplatesRequest = (
  page = 1,
  pageSize = 10,
  filter: CreditTariffsFilterParams,
  { signal }: AbortController,
): Promise<AxiosResponse<PaginatedCreditTemplates>> => {
  return axiosInstance.get<PaginatedCreditTemplates>(urls.creditTemplateListUrl(page, pageSize, filter), { signal });
};

export const updateCreditTemplateRequest = (creditTemplateId: string, price: number): Promise<AxiosResponse<CreditTemplate>> => {
  return axiosInstance.put<CreditTemplate>(urls.creditTemplateItemUrl(creditTemplateId), { price });
};
