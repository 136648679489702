export enum SearchParams {
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
  SEARCH = 'search',
  ORG = 'org',
  ORDER_STATUS = 'orderStatus',
  TAG = 'tag',
  MODEL = 'model',
  MODEL_API = 'modelApi',
  MIN_DATE = 'minDate',
  MAX_DATE = 'maxDate',
  CREDIT_SERVICE_TYPE = 'creditServiceType',
  FULL_LEDGER = 'fullLedger',
  TYPE = 'type',
  PLOT_REQUEST_ID = 'plotRequestId',
}
