import { getDonutRequest } from 'api/Dashboard/api';
import { DonutResponse } from 'api/Dashboard/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { ChartFilter } from 'components/Dashboard/Plots/types';
import { useAppDispatch } from 'store/hooks';
import { addDonutChart } from 'store/plots/plotsSlice';

import { useCallback, useState } from 'react';
import { message } from 'antd';

const useDonut = () => {
  const dispatch = useAppDispatch();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ sunburstData, setSunburstData ] = useState<DonutResponse>({ children: [] });

  const fetchDonut = useCallback((filter: ChartFilter, abortController: AbortController) => {
    setIsLoading(true);
    getDonutRequest(filter, abortController)
      .then((response) => {
        if (response.status === 200) {
          if (filter.plotRequestId) {
            dispatch(addDonutChart({ ...response.data, id: filter.plotRequestId }));
          } else {
            setSunburstData(response.data);
          }
        }
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [ dispatch ]);

  return { sunburstData, isLoading, fetchDonut, setSunburstData };
};

export default useDonut;
