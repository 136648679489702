import { SelectOption } from 'app/types/Forms';
import useDeleteTag from 'app/common_components/TagsList/hooks';
import UpdateTagForm from 'app/common_components/TagsList/UpdateTagForm/UpdateTagForm';

import React, { useState } from 'react';
import { Button, Typography, List, Modal } from 'antd';

export interface Props {
  onCancel: () => void;
  tagOptions: SelectOption[];
}

const TagsList: React.FC<Props> = ({ onCancel, tagOptions }) => {
  const [ tagIdToRemove, setTagIdToRemove ] = useState<string | null>(null);
  const openDeleteTagModal = (tagId: string) => setTagIdToRemove(tagId);
  const closeDeleteTagModal = () => setTagIdToRemove(null);

  const { deleteTag, isDeleting } = useDeleteTag(closeDeleteTagModal);

  const [ tagToUpdate, setTagToUpdate ] = useState<SelectOption | null>(null);
  const openUpdateTagModal = (tag: SelectOption) => setTagToUpdate(tag);
  const closeUpdateTagModal = () => setTagToUpdate(null);

  return (
    <>
      <div style={{ maxHeight: 400, overflow: 'auto', margin: '16px 0' }}>
        <List
          dataSource={tagOptions}
          renderItem={(item: SelectOption) => (
            <List.Item
              actions={[
                <Typography.Link onClick={() => openUpdateTagModal(item)} key="edit">edit</Typography.Link>,
                <Typography.Link onClick={() => openDeleteTagModal(item.value)} key="delete">delete</Typography.Link>,
              ]}
            >
              {item.label}
            </List.Item>
          )}
        />
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={onCancel}>
          Cancel
        </Button>
      </div>

      <Modal
        title={<Typography.Title level={4}>Delete tag</Typography.Title>}
        open={!!tagIdToRemove}
        width={420}
        onCancel={closeDeleteTagModal}
        onOk={() => deleteTag(tagIdToRemove!)}
        confirmLoading={isDeleting}
        destroyOnClose
      >
        <div style={{ marginTop: 24 }}>
          <Typography.Text>Are you sure you want to delete this tag?</Typography.Text>
        </div>
      </Modal>

      <Modal
        title={<Typography.Title level={4}>Update tag</Typography.Title>}
        open={!!tagToUpdate}
        width={420}
        footer={null}
        onCancel={closeUpdateTagModal}
        destroyOnClose
      >
        {tagToUpdate && (
          <UpdateTagForm tagId={tagToUpdate!.value} onCancel={closeUpdateTagModal} name={tagToUpdate!.label} />
        )}
      </Modal>
    </>
  );
};

export default TagsList;
