import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuthStatus, selectIsAuthenticated } from 'store/auth/authSlice';
import { fetchMyUser } from 'store/users/usersSlice';

import { useEffect } from 'react';

const useMyUser = () => {
  const dispatch = useAppDispatch();

  const authStatus = useAppSelector(selectAuthStatus);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  useEffect(() => {
    if (authStatus === 'idle') {
      dispatch(fetchMyUser(isAuthenticated));
    }
  }, [ isAuthenticated, authStatus, dispatch ]);
};

export default useMyUser;
