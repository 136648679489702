import { createTagRequest } from 'api/Exec/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { addTag } from 'store/exec/execSlice';
import { useAppDispatch } from 'store/hooks';

import { useCallback, useState } from 'react';

const useCreateTag = (setError: (error: string) => void) => {
  const dispatch = useAppDispatch();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const createTag = useCallback((name: string) => {
    setError('');
    setIsLoading(true);
    createTagRequest(name)
      .then((response) => {
        dispatch(addTag(response.data));
      })
      .catch((reason) => {
        setError(getErrorFromPromiseReason(reason));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [ dispatch, setError ]);

  return { isLoading, createTag };
};

export default useCreateTag;
