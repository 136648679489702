import { axiosInstance } from 'api/instance';
import { PaginatedNotifications } from 'api/Notifications/types';
import urls from 'api/Notifications/urls';

import { AxiosResponse } from 'axios';

export const getNotificationsRequest = (
  page = 1,
  pageSize = 10,
  watched?: boolean,
  abortController?: AbortController,
): Promise<AxiosResponse<PaginatedNotifications>> => {
  return axiosInstance.get<PaginatedNotifications>(urls.notificationsListUrl(page, pageSize, watched), { signal: abortController?.signal });
};

export const watchNotificationRequest = (notificationId?: string, watchAll = false): Promise<AxiosResponse> => {
  return axiosInstance.put(urls.notificationsWatchUrl(notificationId, watchAll));
};
