import { Order } from 'api/Exec/types';
import TagsSelect from 'app/common_components/TagsSelect/TagsSelect';
import { useUpdateOrder } from 'components/Dashboard/Orders/hooks';

import { Button, Form, Space } from 'antd';
import React from 'react';

interface FormValues {
  tagIds: string[];
}

interface Props {
  orderId: string;
  onCancel: () => void;
  onOrderUpdated: (order: Order) => void;
  tagIds: string[];
}

const UpdateOrderForm: React.FC<Props> = ({ orderId, onCancel, onOrderUpdated, tagIds = [] }) => {
  const [ form ] = Form.useForm<FormValues>();

  const { updateOrder, isLoading } = useUpdateOrder(onOrderUpdated, onCancel);

  const onFinish = (values: FormValues) => {
    updateOrder(orderId, values.tagIds);
  };

  return (
    <Form
      form={form}
      name="updateOrderForm"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ marginTop: 24 }}
      initialValues={{ tagIds }}
    >
      <Form.Item name="tagIds" label="Tags">
        <TagsSelect disabled={isLoading} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            Update
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UpdateOrderForm;
