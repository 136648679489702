import { Role, Status } from 'api/Users/types';

export const getStatusColor = (status: Status) => {
  switch (status) {
    case Status.ACTIVATED:
      return 'purple';
    case Status.DELETED:
      return 'default';
    default:
      return 'geekblue';
  }
};

export const isChangingDisabled = (deletedAt: string | null, myUserRole: Role, role: Role) => {
  return !!deletedAt || (myUserRole === Role.ORG_ADMIN && role === Role.SYSTEM_ADMIN);
};
