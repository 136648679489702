import { Role } from 'api/Users/types';
import useCreateTag from 'app/common_components/TagsSelect/Dropdown/hooks';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import React, { useState } from 'react';
import { Button, Divider, Input, Space, Tooltip, Typography } from 'antd';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';

export interface Props {
  menu: React.ReactElement;
  disableTagManagement?: boolean,
  onSettingsClick?: () => void;
}

const Dropdown: React.FC<Props> = ({ menu, disableTagManagement, onSettingsClick }) => {
  const [ name, setName ] = useState<string>('');
  const [ error, setError ] = useState<string>('');

  const { role: myUserRole } = useAppSelector(selectMyUser);

  const { createTag, isLoading } = useCreateTag(setError);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    if (name.length === 0) {
      setError('Tag name is required');
    } else {
      createTag(name);
    }
  };

  return (
    <>
      {menu}
      {!disableTagManagement && (
        <>
          <Divider style={{ margin: '8px 0' }} />
          <Space direction="vertical" style={{ padding: '0 8px 4px' }}>
            <Space>
              <Input
                placeholder="Enter tag"
                value={name}
                onChange={onNameChange}
                status={error.length > 0 ? 'error' : ''}
                disabled={isLoading}
              />
              <Button type="text" disabled={isLoading} icon={<PlusOutlined />} onClick={addItem}>
                Create tag
              </Button>
              {myUserRole !== Role.ORG_USER && onSettingsClick && (
                <Tooltip title="Manage tags">
                  <SettingOutlined style={{ cursor: 'pointer' }} onClick={onSettingsClick} />
                </Tooltip>
              )}
            </Space>
            <Typography.Text type="danger">{error}</Typography.Text>
          </Space>
        </>
      )}
    </>
  );
};

export default Dropdown;
