import React from 'react';
import { Spin } from 'antd';

const AppLoader: React.FC = () => (
  <div data-testid="app-loader" style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Spin size="large" />
  </div>
);

export default AppLoader;
