import { CreditTariffsFilterParams, CreditTemplate } from 'api/Credit/types';
import { Org } from 'api/Orgs/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import { useColumns, useCreditTemplates } from 'components/Dashboard/Billing/CreditTemplates/hooks';
import CreditTemplateFilter from 'components/Dashboard/Billing//CreditTemplates/CreditTemplateFilter/CreditTemplateFilter';
import UpdateCreditTemplateForm from 'components/Dashboard/Billing//CreditTemplates/UpdateCreditTemplateForm/UpdateCreditTemplateForm';

import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Table, Typography } from 'antd';

interface Props {
  org?: Org;
  hasOrgJustCreated?: boolean;
}

const CreditTemplates: React.FC<Props> = ({ org, hasOrgJustCreated }) => {
  const { isLoading, creditTemplates, fetchCreditTemplates, setCreditTemplates } = useCreditTemplates();
  const { pageParam, pageSizeParam, orgParam, creditServiceTypeParam, modelApiParam } = usePaginationParams();
  const [ updatedCreditTemplate, setUpdatedCreditTemplate ] = useState<CreditTemplate | null>(null);
  const openUpdateCreditTemplateModal = (creditTemplate: CreditTemplate) => setUpdatedCreditTemplate(creditTemplate);
  const closeUpdateCreditTemplateModal = () => setUpdatedCreditTemplate(null);

  const { columns } = useColumns(openUpdateCreditTemplateModal, org, hasOrgJustCreated);
  const { tablePagination } = useTablePagination();
  const pagination = tablePagination(creditTemplates.count);

  const getFilter = useCallback((): CreditTariffsFilterParams => ({
    orgs: org ? [ org.id ] : orgParam.split(','),
    type: creditServiceTypeParam,
    modelApi: modelApiParam,
  }), [ org, orgParam, creditServiceTypeParam, modelApiParam ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchCreditTemplates(pageParam, pageSizeParam, getFilter(), abortController);

    return () => {
      abortController.abort();
    };
  }, [ pageParam, pageSizeParam, fetchCreditTemplates, getFilter ]);

  const onCreditTemplateUpdated = (updatedCreditTemplate: CreditTemplate) => {
    const creditTemplateIndex = creditTemplates.results.findIndex((creditTemplate: CreditTemplate) => creditTemplate.id === updatedCreditTemplate.id);
    setCreditTemplates({
      count: creditTemplates.count,
      results: [
        ...creditTemplates.results.slice(0, creditTemplateIndex),
        updatedCreditTemplate,
        ...creditTemplates.results.slice(creditTemplateIndex + 1, creditTemplates.results.length),
      ],
    });
    closeUpdateCreditTemplateModal();
  };

  const filter = !hasOrgJustCreated ? CreditTemplateFilter : undefined;

  return (
    <>
      <TableTopPanel Filter={filter} orgId={org?.id} />

      <Table
        rowKey={record => String(record.id)}
        columns={columns}
        dataSource={creditTemplates.results}
        pagination={pagination}
        scroll={{ x: true }}
        loading={isLoading}
      />

      {updatedCreditTemplate && (
        <Modal
          title={<Typography.Title level={4}>Update template</Typography.Title>}
          open={!!updatedCreditTemplate}
          footer={null}
          width={420}
          onCancel={closeUpdateCreditTemplateModal}
          destroyOnClose
        >
          <UpdateCreditTemplateForm
            creditTemplate={updatedCreditTemplate}
            onCancel={closeUpdateCreditTemplateModal}
            onCreditTemplateUpdated={onCreditTemplateUpdated}
          />
        </Modal>
      )}
    </>
  );
};

export default CreditTemplates;
