import Wizard from 'components/Dashboard/Orgs/Wizard/Wizard';
import { DashboardPageProps } from 'components/Dashboard/types';

import React, { useEffect } from 'react';

const CreateOrg: React.FC<DashboardPageProps> = ({ setTitle }) => {
  useEffect(() => {
    setTitle('Create organization');
  }, [ setTitle ]);

  return <Wizard />;
};

export default CreateOrg;
